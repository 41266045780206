import { FC, Fragment } from 'react'
import { Grid, InputAdornment, makeStyles, createStyles } from '@material-ui/core'
//icons
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
//time manager
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import es from 'date-fns/locale/es'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customLabel: {
			marginBottom: '5px',
		},
		passengersLabel: {
			'color': '#007AFF',
			'fontSize': '20px',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
	})
)

const Schedule: FC<ScheduleProps> = ({ scheduleSelection, entryTime, exitTime, setEntryTime, setExitTime, commonShift}) => {
	const classes = useStyles()

	const setLabel = value => {
		switch (value) {
			case 0:
				return 'Hora Ingreso'
			case 1:
				return 'Hora Salida'
			default:
				break
		}
	}

	const renderTimePicker = () => {
		if (scheduleSelection === 2) {
			return (
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={12} md={6}>
						<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>{setLabel(0)}</p>
						<TimePicker
							ampm={false}
							className={classes.customTextField}
							clearable
							fullWidth
							inputVariant="outlined"
							name="entryTime"
							onChange={event => setEntryTime(event)}
							value={entryTime}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<QueryBuilderIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>{setLabel(1)}</p>
						<TimePicker
							ampm={false}
							className={classes.customTextField}
							clearable
							fullWidth
							inputVariant="outlined"
							name="exitTime"
							onChange={event => setExitTime(event)}
							value={exitTime}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<QueryBuilderIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			)
		} else {
			return (
				<Fragment>
					<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>{setLabel(scheduleSelection)}</p>
					<TimePicker
						ampm={false}
						className={classes.customTextField}
						clearable
						fullWidth
						inputVariant="outlined"
						name={scheduleSelection > 0 ? 'exitTime' : 'entryTime'}
						onChange={event => (scheduleSelection > 0 ? setExitTime(event) : setEntryTime(event))}
						value={!commonShift ? (scheduleSelection > 0 ? entryTime : entryTime) : scheduleSelection > 0 ? exitTime : entryTime}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<QueryBuilderIcon />
								</InputAdornment>
							),
						}}
					/>
				</Fragment>
			)
		}
	}

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
				{renderTimePicker()}
			</MuiPickersUtilsProvider>
		</>
	)
}

export default Schedule
