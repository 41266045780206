import { useContext, useEffect, useState } from 'react'
import { createStyles, Grid, ListItemIcon, makeStyles, Box, MenuItem, MenuList, Paper, Typography, ThemeProvider } from '@material-ui/core'
import { SearchCollaborator } from '../components/Reports/SearchCollaborator'
import { useMediaQuery } from 'react-responsive'

import MainColorTheme from '../components/utils/MainColorTheme'

import PageviewIcon from '@material-ui/icons/Pageview'
import StoreIcon from '@material-ui/icons/Store'
import TimerIcon from '@material-ui/icons/Timer'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'

import { SearchStore } from '../components/Reports/SearchStore'
import { SearchVisit } from '../components/Reports/SearchVisit'
import { UserContext } from '../context/UserContext'
import { DownloadRoutes } from '../components/Reports/DownloadRoutes'

const useStyles = makeStyles(() =>
	createStyles({
		containerMobile: {
			padding: '1rem 0',
		},
		custList: {
			'& li': {
				fontFamily: 'Lato-Regular',
			},
		},
		contentWrapper: {
			background: '#EFF1F3',
			height: 'calc(100vh - 160px)',
			paddingTop: '80px',
		},
		container: {
			padding: '3% 3%',
		},
		menuWidth: {
			width: '90%',
		},
		selectedItem: {
			background: '#EFF1F3',
		},
		contentSelected: {
			borderRadius: '4px',
			height: 'calc(100vh - 260px)',
			overflow: 'auto',
		},
	})
)

const INIT_MENU = {
	searchCollaborator: false,
	searchStore: false,
	searchVisit: false,
	downloadRoutes: false,
}

export const ReportsPage = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [menuItem, setMenuItem] = useState(INIT_MENU)
	const [componentValue, setComponentValue] = useState(0)
	const { selectedOption, setSelectedOption } = useContext(UserContext)

	const role = localStorage.getItem('role')

	useEffect(() => {
		setSelectedOption({ ...selectedOption, item: 4 })
	}, [])

	const handleSetSelected = value => {
		setMenuItem({
			...INIT_MENU,
			[value]: true,
		})
	}

	const renderComponent = value => {
		switch (value) {
			case 0:
				return null
			case 1:
				return <SearchCollaborator />
			case 2:
				return <SearchStore />
			case 3:
				return <SearchVisit />
			case 4:
				return <DownloadRoutes />
		}
	}

	return (
		<Grid container spacing={3} className={isMobile ? classes.containerMobile : ''}>
			<Grid item xs={12} md={3}>
				<Paper style={{ marginTop: '20px' }}>
					<MenuList className={classes.custList}>
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN') && (
							<MenuItem
								className={menuItem.downloadRoutes ? classes.selectedItem : ''}
								onClick={() => {
									handleSetSelected('downloadRoutes')
									setComponentValue(4)
								}}
							>
								<ListItemIcon>
									<AssignmentReturnedIcon fontSize="small" />
								</ListItemIcon>
								<Typography variant="inherit">Descargar Rutas</Typography>
							</MenuItem>
						)}
						<MenuItem
							className={menuItem.searchCollaborator ? classes.selectedItem : ''}
							onClick={() => {
								handleSetSelected('searchCollaborator')
								setComponentValue(1)
							}}
						>
							<ListItemIcon>
								<PageviewIcon fontSize="small" />
							</ListItemIcon>
							<Typography variant="inherit">Buscar Colaborador</Typography>
						</MenuItem>
						<MenuItem
							className={menuItem.searchStore ? classes.selectedItem : ''}
							onClick={() => {
								handleSetSelected('searchStore')
								setComponentValue(2)
							}}
						>
							<ListItemIcon>
								<StoreIcon fontSize="small" />
							</ListItemIcon>
							<Typography variant="inherit">Buscar Sucursal</Typography>
						</MenuItem>
						<MenuItem
							className={menuItem.searchVisit ? classes.selectedItem : ''}
							onClick={() => {
								handleSetSelected('searchVisit')
								setComponentValue(3)
							}}
						>
							<ListItemIcon>
								<TimerIcon fontSize="small" />
							</ListItemIcon>
							<Typography variant="inherit">Buscar Visita</Typography>
						</MenuItem>
					</MenuList>
				</Paper>
			</Grid>
			<Grid item xs={12} md={9}>
				<ThemeProvider theme={MainColorTheme}>
					<Box className={classes.contentSelected}>{renderComponent(componentValue)}</Box>
				</ThemeProvider>
			</Grid>
		</Grid>
	)
}
