import {
	createStyles,
	makeStyles,
  Button,
} from '@material-ui/core'

const useStyles = makeStyles(() =>
	createStyles({
    buttonRecovery: {
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      fontFamily: 'Lato-Regular',
      fontSize: 15,
      textAlign: 'center',
      transition: '0.3s',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        cursor: 'pointer',
        opacity: '0.8',
      },
    }
	})
)

const ButtonLink = ({ type, setAction, label }) => {
	const classes = useStyles()

	return (
    <Button
      className={classes.buttonRecovery}
      onClick={() => setAction(type)}
      variant="text"
    >
      {label}
    </Button>
	)
}

export default ButtonLink
