import { Box, Button, CircularProgress, createStyles, Grid, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import SendIcon from '@material-ui/icons/Send'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { putPassengersExcel, getShift, putMaxTimeShifts, putShiftOptimize } from '../providers/Private/Provider'
import Files from '../components/NewRoutes/Passengers/Files'
import Loading from '../components/Loading/Loading'
import { useNotification } from '../hooks/useNotification'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'

const useStyles = makeStyles(() =>
	createStyles({
		boxContent: {
			minHeight: 'calc(100vh - 155px)',
		},
		boxHeaderRoute: {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		gridHeaderWrong: {
			marginTop: '2%',
		},
		wrongTitle: {
			color: '#FF5E05',
			textTransform: 'uppercase',
			fontWeight: 600,
			fontFamily: 'Lato-Regular',
			fontStyle: 'normal',
			fontSize: '20px',
		},
		wrongSubTitle: {
			fontFamily: 'Lato-Bold',
			fontStyle: 'normal',
			fontSize: '18px',
			color: 'red',
		},
		buttonAction: {
			borderRadius: 20,
			fontFamily: 'Lato-Bold',
			fontSize: 16,
			height: 49,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonCancel: {
			'color': '#000000',
			'backgroundColor': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#CDCDCD',
				color: '#000000',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonValidate: {
			'color': '#FFFFFF',
			'backgroundColor': '#F28F15',
			'&:hover': {
				backgroundColor: '#F28F15',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		spaceContainer: {
			padding: '1.5rem 0',
		},
		labelName: {
			color: '#4F4F4F',
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			magin: 0,
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customLabel: {
			marginBottom: '5px',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
		},
	})
)

export const WrongMaxTimePage = props => {
	const id = props.match.params.id
	const [errorMessage, setErrorMessage] = useState(props.location.state.data)
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [shiftData, setShiftData] = useState({ name: '', store: '' })
	const [loading, setLoading] = useState(true)
	const [handleNotification] = useNotification()
	const [normalized, setNormalized] = useState(false)

	const maxTimeOptions = [
		{
			value: 30,
			text: '00:30 Hrs',
		},
		{
			value: 60,
			text: '01:00 Hrs',
		},
		{
			value: 90,
			text: '01:30 Hrs',
		},
		{
			value: 120,
			text: '02:00 Hrs',
		},
		{
			value: 150,
			text: '02:30 Hrs',
		},
		{
			value: 180,
			text: '03:00 Hrs',
		},
		{
			value: 210,
			text: '03:30 Hrs',
		},
	]

	useEffect(() => {
		handleNotification('Por favor, cambie la hora máxima a una más alta', 'warning')
		getShift(id).then(response => {
			if (response && response.success) {
				const { name, store } = response.data
				setShiftData({ name: name, store: store.name })
				setLoading(false)
			}
		})
	}, [])

	const handleSubmitForm = values => {
		
		putMaxTimeShifts(id, values).then(response => {
			if (response && response.success) {
				setSendingData(true)
				putShiftOptimize(id).then(response => {
					if (response && response.success) {
						setNormalized(true)
					} else {
						setSendingData(false)
						handleNotification('Por favor, cambie la hora máxima a una más alta', 'warning')
					}
				})
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
	}

	const formik = useFormik({
		initialValues: {
			maxTime: 90,
		},
		onSubmit: values => handleSubmitForm(values),
	})

	if (normalized) {
		return <Redirect push to={{ pathname: `/new-route/${id}/optimized-route` }} />
	}

	if (loading) {
		return <Loading />
	}

	return (
		<Box className={classes.boxContent}>
			<FormikProvider value={formik}>
				<Grid>
					<Grid item xs={12} md={6}>
						<Grid container alignItems="center">
							<Grid item xs={12} md={6}>
								<h1 className={'ct-light-black ff-LatoRegular fs25 tt-upper'}>{'Mis Rutas'}</h1>
							</Grid>
							<Grid item xs={12} md={6}>
								<h1 className={classes.wrongTitle}>{'Corregir Información'}</h1>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={12} md={6}>
							<Box className={classes.boxHeaderRoute}>
								<Grid container>
									<Grid item xs={12} md={6}>
										<p className={classes.labelName}>{shiftData.name}</p>
									</Grid>
									<Grid item xs={12} md={6} style={{ textAlign: isMobile ? 'start' : 'center' }}>
										<p className={classes.labelName}>{shiftData.store}</p>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} className={classes.gridHeaderWrong}>
							<p className={classes.wrongTitle}>{'INFORMACIÓN CON PROBLEMAS'}</p>
							<p className={classes.wrongSubTitle}>{errorMessage ? errorMessage : ''}</p>
						</Grid>
					</Grid>
				</Grid>
				{!sendingData ? (
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={2} className={classes.spaceContainer}>
							<Grid item xs={12} md={4}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Tiempo máximo en ruta</p>
							</Grid>
							<Grid item xs={12} md={4}>
								<Select className={classes.customTextField} fullWidth id="maxTime" name="maxTime" onChange={formik.handleChange} value={formik.values.maxTime} variant="outlined">
									{maxTimeOptions.map((maxTime, index) => (
										<MenuItem key={index} value={maxTime.value} classes={{ root: classes.customMenuItem }}>
											{maxTime.text}
										</MenuItem>
									))}
								</Select>
								<ErrorMessage name="maxTime" render={msg => <Typography className={classes.error}>{msg}</Typography>} />
							</Grid>
						</Grid>
						<Grid alignItems="center" container justify="space-between" spacing={2} className={classes.spaceContainer}>
							<Grid item xs={12} md={3}>
								<Button
									classes={{ root: classes.buttonAction }}
									className={classes.buttonCancel}
									color="primary"
									component={Link}
									fullWidth
									startIcon={<TransitEnterexitIcon />}
									to={'/new-route'}
									variant="contained"
								>
									{'Volver'}
								</Button>
							</Grid>
							<Grid item xs={12} md={3}>
								<Button
									classes={{ root: classes.buttonAction }}
									className={classes.buttonValidate}
									color="primary"
									disabled={sendingData}
									endIcon={<SendIcon />}
									fullWidth
									type={'submit'}
									variant="contained"
								>
									{'Validar'}
									{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
								</Button>
							</Grid>
						</Grid>
					</form>
				) : (
					<Box className={classes.boxLoading}>
						<CircularProgress size={34} className={classes.customProgress} />
						<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'}>{'Optimizando Ruta'}</p>
					</Box>
				)}
			</FormikProvider>
		</Box>
	)
}
