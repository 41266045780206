import {
	Container,
	createStyles,
	Grid,
	makeStyles,
	TextField,
	withStyles,
	Theme,
	TableCell,
	TableRow,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	Paper,
	Box,
	Button,
	CircularProgress,
	Select,
	MenuItem,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getStores, getStoreServices, postGetCCByStore } from '../../providers/Private/Provider'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

//time manager
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import es from 'date-fns/locale/es'
import { useNotification } from '../../hooks/useNotification'

const es_ES = require('date-fns/locale/es')
const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
			fontFamily: 'Lato-Regular',
		},
		customLabel: {
			marginBottom: '5px',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				padding: '14px',
			},
		},
		failMessage: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 600px)',
			justifyContent: 'center',
			alignContent: 'center',
			margin: 'auto',
		},
		customTabsIndicator: {
			display: 'none',
		},
		customTab: {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		activeTab: {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		customProgress: {
			color: '#0D1373',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		editRoute: {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		custCell: {
			padding: '0 !important',
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 260px)',
			justifyContent: 'center',
		},
		buttonShift: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		boxHeaderRoute: {
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			margin: '2rem 0 2rem 0',
			padding: '0 1rem',
		},
		downloadButton: {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
	})
)

export const SearchStore = () => {
	const classes = useStyles()
	let today = new Date()
	let oneWeekAgo = new Date(today.getTime() - 168 * 60 * 60 * 1000)
	const [stores, setStores] = useState([])
	const [costCenters, setCostCenters] = useState<any>([{ name: 'Todas', id: 0, state: 1, address: { street: '', number: '' } }])
	const [storeSelected, setStoreSelected] = useState<any>({})
	const [storeServices, setStoreServices] = useState<any>([])
	const [filters, setFilters] = useState({ startDate: oneWeekAgo, endDate: today, costCenter: 0 })
	const [loader, setLoader] = useState(false)
	const [handleNotification] = useNotification()

	useEffect(() => {
		getStores().then(response => {
			if (response && response.success) {
				setStores(response.data)
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
	}, [])

	const handleStoreSelect = id => {
		if (id) {
			setStoreSelected(stores.filter((store: any) => store.id === id)[0])
		}
		getStoreServices(id, '').then(response => {
			if (response && response.success) {
				setStoreServices(response.data)
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
		if (id !== 0) {
			postGetCCByStore({ id: id }).then(response => {
				if (response && response.success) {
					setCostCenters([...costCenters, ...response.data])
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
		}
	}

	const formatDateToSeach = (date: any) => {
		return format(date, 'yyyy-MM-dd', { locale: es_ES }).toString()
	}

	const handleSearchStore = () => {
		if (storeSelected?.name) {
			setLoader(true)
			let formatFilters: any = '?'
			const { startDate, endDate, costCenter } = filters
			formatFilters = formatFilters + `&startDate=${formatDateToSeach(startDate)}&endDate=${formatDateToSeach(endDate)}`
			formatFilters = costCenter !== 0 ? formatFilters + `&costCenter=${costCenter}` : formatFilters
			const { id } = storeSelected
			getStoreServices(id, formatFilters)
				.then(response => {
					if (response && response.success) {
						setStoreServices(response.data)
					}
				})
				.finally(() => setLoader(false))
		}
	}

	const handleCostCenterChange = event => {
		const { value } = event.target
		setFilters({ ...filters, costCenter: value })
	}

	const handleDateChange = (date, type) => {
		if (type === 0) {
			if (format(date, 'yyyy-MM-dd').toString() > format(filters.endDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, startDate: date })
			}
		} else if (type === 1) {
			if (format(date, 'yyyy-MM-dd').toString() < format(filters.startDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, endDate: date })
			}
		}
	}

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy').toString()
	}

	return (
		<Container maxWidth={'xl'}>
			<div className={classes.container}>
				<h2 className={'ct-black ff-LatoRegular fs25'}>{'Buscar Sucursal'}</h2>
				<Grid container>
					<Grid container spacing={2} xs={12} md={12} style={{ paddingLeft: '1%' }}>
						<Grid item xs={12} md={6}>
							<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Sucursal'}</p>
							<Autocomplete
								classes={{ option: classes.customMenuItem }}
								getOptionLabel={option => `${option['name']}`}
								getOptionSelected={option => option['id']}
								id="stores"
								options={stores}
								noOptionsText={'Sin coincidencias'}
								onChange={(event, value: any) => handleStoreSelect(value?.id)}
								renderInput={params => (
									<TextField
										{...params}
										name="stores"
										classes={{
											root: classes.customAutocompleteField,
										}}
										required={true}
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Centro de costo'}</p>
							<Select id="costCenters" name="office" defaultValue={0} variant="outlined" className={classes.customTextField} fullWidth onChange={handleCostCenterChange}>
								{costCenters.map((costCenter, index) => (
									<MenuItem key={index} value={costCenter.id} classes={{ root: classes.customMenuItem }}>
										{costCenter.name}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<Grid container spacing={2} xs={12} md={9} style={{ paddingLeft: '1%' }}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
							<Grid item xs={12} md={6}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Inicio'}</p>
								<DatePicker
									autoOk
									className={classes.customTextField}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={date => handleDateChange(date, 0)}
									value={filters.startDate}
									emptyLabel="Seleccione fecha de inicio"
									variant="inline"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Fin'}</p>
								<DatePicker
									autoOk
									className={classes.customTextField}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={date => handleDateChange(date, 1)}
									value={filters.endDate}
									emptyLabel="Seleccione fecha de fin"
									variant="inline"
								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} md={3} style={{ marginTop: 'auto', marginBottom: '5px', paddingLeft: '1%', paddingRight: '1%' }}>
						<Button style={{ backgroundColor: '#0D1373', color: '#FFFFFF' }} fullWidth variant="contained" onClick={() => handleSearchStore()}>
							Buscar
						</Button>
					</Grid>
					{storeSelected?.name !== undefined && storeServices[0]?.shifts?.length > 0 && (
						<Grid container spacing={2} className={classes.boxHeaderRoute}>
							<Grid item xs={12} md={4} style={{ paddingTop: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Nombre</p>
								{storeSelected?.name ? storeSelected.name : 'Sin Nombre'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingTop: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Teléfono</p>
								{storeSelected?.phoneNumber ? storeSelected.phoneNumber : 'Sin Numero'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingTop: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>E-mail</p>
								{storeSelected?.emailInCharge ? storeSelected.emailInCharge : 'Sin Email'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingBottom: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Compañía</p>
								{storeSelected?.company?.name ? storeSelected.company.name : 'Sin Compañía'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingBottom: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Dirección</p>
								{`${storeSelected?.address?.street ? storeSelected.address.street : 'Sin Calle'} - ${
									storeSelected?.address?.county?.name ? storeSelected.address.county.name : 'Sin Comuna'
								}`}
							</Grid>
						</Grid>
					)}
					<Grid item xs={12} md={12} style={{ paddingTop: '2%' }}>
						{!loader ? (
							storeServices.length > 0 &&
							(storeServices[0].shifts.length > 0 ? (
								<TableContainer component={Paper}>
									<Table className={classes.table}>
										<TableHead>
											<TableRow>
												<StyledTableCellHead>{'Fecha Inicio'}</StyledTableCellHead>
												<StyledTableCellHead>{'Fecha Fin'}</StyledTableCellHead>
												<StyledTableCellHead>{'Nombre'}</StyledTableCellHead>
												<StyledTableCellHead colSpan={2}></StyledTableCellHead>
											</TableRow>
										</TableHead>
										<TableBody>
											{storeServices[0].shifts.map((shift: any, index) => (
												<>
													<StyledTableRowBody key={index}>
														<StyledTableCellBody>{shift.startDate ? formatDate(new Date(`${shift.startDate}T00:00:00`)) : 'Sin Fecha'}</StyledTableCellBody>
														<StyledTableCellBody>{shift.endDate ? formatDate(new Date(`${shift.endDate}T00:00:00`)) : 'Sin Fecha'}</StyledTableCellBody>
														<StyledTableCellBody>{shift.shiftName ? shift.shiftName : 'Sin Nombre'}</StyledTableCellBody>
														<StyledTableCellBody>
															<Button
																classes={{ root: classes.buttonTableAction }}
																className={classes.downloadButton}
																color="primary"
																fullWidth
																variant="contained"
																download
																href={`${process.env.REACT_APP_API_XLSX}/api/shifts/${shift.id}/download-excel`}
															>
																{'Descargar'}
															</Button>
														</StyledTableCellBody>
														<StyledTableCellBody>
															<Button
																classes={{ root: classes.buttonTableAction }}
																className={classes.buttonShift}
																component={Link}
																color="primary"
																fullWidth
																variant="contained"
																to={`/${shift.id}/existing-route`}
															>
																{'Ver Turno'}
															</Button>
														</StyledTableCellBody>
													</StyledTableRowBody>
												</>
											))}
										</TableBody>
									</Table>
									<div style={{ width: '100%', textAlign: 'center', padding: '1% 0%' }}></div>
								</TableContainer>
							) : (
								<Box className={classes.boxLoading}>
									<h1 className={'ct-black ff-LatoRegular fs20'}>{'No hay turnos disponibles'}</h1>
								</Box>
							))
						) : (
							<div style={{ width: '100%', textAlign: 'center', paddingTop: '5%' }}>
								<CircularProgress />
							</div>
						)}
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}
