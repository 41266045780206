import { FC, useState, useEffect, useContext } from 'react'
import { Box, Button, Container, createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core'
//time manager
import 'date-fns'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import CommonRoute from '../components/NewRoutes/CommonRoute'
import DynamicRoute from '../components/NewRoutes/DynamicRoute'
import { useMediaQuery } from 'react-responsive'
import { UserContext } from '../context/UserContext'
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: { paddingTop: '3%' },
		customTabsIndicator: {
			display: 'none',
			backgroundColor: '#F1F1F1',
		},
		customTab: {
			borderBottom: '2px solid',
			borderColor: 'white',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		activeTab: {
			border: '2px solid',
			borderBottom: 'none',
			borderColor: '#0D1373',
			color: '#113047',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
		},
		boxTabPanel: {
			border: '2px solid #0D1373',
			borderTop: 'none',
			borderColor: '#0D1373',
		},
		buttonContinue: {
			margin: '1rem 0',
		},
		boxContent: {
			padding: '24px',
			backgroundColor: 'white',
		},
		boxContentMob: {
			padding: '24px 0',
		},
	})
)

const NewRoutePage: FC = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const location = useLocation()
	const { setSelectedOption, selectedOption } = useContext(UserContext)

	useEffect(() => {
		setValueByLocation(location.pathname)
		setSelectedOption({ item: 3, selected: true })
	}, [])

	const routes = ['/new-route/common-route', '/new-route/dynamic-route']
	const classes = useStyles()
	const [value, setValue] = useState(0)

	const setValueByLocation = (path: string) => {
		const pathName = path.split('/')
		setValue(pathName[2] === 'common-route' ? 0 : 1)
	}

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className={classes.container}>
			<h1 className={'ct-light-black ff-LatoRegular fs40 tt-upper'} style={{ color: '#7E9BF2' }}>
				{'Crear Ruta'}
			</h1>
			<Box className={isMobile ? classes.boxContentMob : classes.boxContent}>
				<Tabs
					variant="fullWidth"
					value={value}
					onChange={handleChange}
					classes={{
						indicator: classes.customTabsIndicator,
					}}
				>
					<Tab classes={{ root: classes.customTab }} className={value === 0 ? classes.activeTab : ''} component={Link} label="Horario Fijo" to={routes[0]} />
					<Tab classes={{ root: classes.customTab }} className={value === 1 ? classes.activeTab : ''} component={Link} label="Horario Variable" to={routes[1]} />
				</Tabs>
				<Box className={classes.boxTabPanel} p={3}>
					<Switch>
						<Route path={routes[0]} component={CommonRoute} />
						<Route path={routes[1]} component={DynamicRoute} />
					</Switch>
				</Box>
			</Box>
		</div>
	)
}

export default NewRoutePage
