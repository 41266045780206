import { useState, useEffect } from 'react'
import { createStyles, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, CircularProgress, Grid, TextField, InputAdornment, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Theme } from '@material-ui/core/styles'
import { useNotification } from '../../hooks/useNotification'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LockIcon from '@material-ui/icons/Lock'
import { ChangePasswordSchema } from '../../validations/ChangePasswordSchema'
import { changePassword } from '../../providers/Private/Provider'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		buttonActionSave: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 240,
			'opacity': 1,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		custDialogContent: {
			padding: '1.5rem 24px',
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem',
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
			opacity: 0.8,
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		errorValidation: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		visibility: {
			'&:hover': {
				cursor: 'pointer',
			},
		},
	})
)

export const ChangePasswordDialog = ({ handleDialog, open, user }) => {
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [handleNotification] = useNotification()
	const [watchPass, setWatchPass] = useState({
		newPassword: false,
		confirmPassword: false,
	})
	let userId = localStorage.getItem('userId') || ''
	
	const handleSubmit = values => {
		const setPostData = {
			id: userId,
			password: values.newPassword,
		}
		changePassword(userId, setPostData)
			.then(response => {
				if (response && response.success) {
					handleNotification('Contraseña cambiada con éxito', 'success')
					formik.resetForm()
					handleDialog()
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.error(`err`, err)
				handleNotification('Ocurrió un error con la operación', 'error')
			})
	}

	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema: ChangePasswordSchema,
		onSubmit: values => {
			handleSubmit(values)
		},
	})

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg">
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit} autoComplete="off">
					<DialogTitle>
						<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>{'Cambiar Contraseña'}</h1>
						<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent classes={{ root: classes.custDialogContent }}>
						<Grid container spacing={2} className={classes.containerInput}>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Nueva contraseña'}</p>
								<TextField
									className={classes.customTextField}
									id="newPassword"
									name="newPassword"
									variant="outlined"
									required={true}
									onChange={formik.handleChange}
									value={formik.values.newPassword}
									fullWidth
									type={watchPass.newPassword ? 'text' : 'password'}
									helperText={<ErrorMessage name="newPassword" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<LockIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												{watchPass.newPassword ? (
													<VisibilityOffIcon className={classes.visibility} onClick={() => setWatchPass({ ...watchPass, newPassword: false })} />
												) : (
													<VisibilityIcon className={classes.visibility} onClick={() => setWatchPass({ ...watchPass, newPassword: true })} />
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Reingresa nueva contraseña'}</p>
								<TextField
									className={classes.customTextField}
									id="confirmPassword"
									name="confirmPassword"
									variant="outlined"
									required={true}
									onChange={formik.handleChange}
									value={formik.values.confirmPassword}
									fullWidth
									type={watchPass.confirmPassword ? 'text' : 'password'}
									helperText={<ErrorMessage name="confirmPassword" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<LockIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												{watchPass.confirmPassword ? (
													<VisibilityOffIcon className={classes.visibility} onClick={() => setWatchPass({ ...watchPass, confirmPassword: false })} />
												) : (
													<VisibilityIcon className={classes.visibility} onClick={() => setWatchPass({ ...watchPass, confirmPassword: true })} />
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
						<Button classes={{ root: classes.buttonActionSave }} color="primary" type="submit" variant="contained">
							{'Guardar'}
							{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
						</Button>
					</DialogActions>
				</form>
			</FormikProvider>
		</Dialog>
	)
}
