import { useState, useEffect } from 'react'
import { Container, makeStyles, createStyles, Grid, Button, CircularProgress, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

//time manager
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import es from 'date-fns/locale/es'
import { getCompanies, getReportExcelRoute } from '../../providers/Private/Provider'
import { useNotification } from '../../hooks/useNotification'
import { Autocomplete } from '@material-ui/lab'

const { format } = require('date-fns')

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
			fontFamily: 'Lato-Regular',
		},
		customLabel: {
			marginBottom: '5px',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
	})
)

export const DownloadRoutes = () => {
	const classes = useStyles()
	let today = new Date()
	let oneWeekAgo = new Date(today.getTime() - 168 * 60 * 60 * 1000)

	const [companies, setCompanies] = useState([])
	const [handleNotification] = useNotification()
	const [loader, setLoader] = useState(false)
	const [filters, setFilters] = useState({
		startDate: oneWeekAgo,
		endDate: today,
		company: {
			id: '',
		},
	})

	useEffect(() => {
		getCompanies()
			.then(response => {
				if (response && response.success) {
					setCompanies(
						response.data.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.finally(() => {
				setLoader(false)
			})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleDateChange = (date, type) => {
		if (type === 0) {
			if (format(date, 'yyyy-MM-dd').toString() > format(filters.endDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, startDate: date })
			}
		} else if (type === 1) {
			if (format(date, 'yyyy-MM-dd').toString() < format(filters.startDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, endDate: date })
			}
		}
	}

	const handleFilterChange = (value, field) => {
		setFilters({ ...filters, [field]: value })
	}

	const handleSubmit = () => {
		const setPostData = {
			startDate: format(filters.startDate, 'yyyy-MM-dd').toString(),
			endDate: format(filters.endDate, 'yyyy-MM-dd').toString(),
			company: filters.company?.id ? filters.company.id : '',
		}
		let query
		setPostData.company !== ''
			? (query = `?startDate=${setPostData.startDate}&endDate=${setPostData.endDate}&company=${setPostData.company}`)
			: (query = `?startDate=${setPostData.startDate}&endDate=${setPostData.endDate}`)

		getReportExcelRoute(query, 'rutas')
	}

	return !loader ? (
		<Container maxWidth={'xl'}>
			<div className={classes.container}>
				<h2 className={'ct-black ff-LatoRegular fs25'}>{'Descargar Rutas'}</h2>
				<Grid container>
					<Grid container spacing={2} xs={12} md={12} style={{ paddingLeft: '1%' }}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
							<Grid item xs={12} md={6}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Inicio'}</p>
								<DatePicker
									autoOk
									className={classes.customTextField}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={date => handleDateChange(date, 0)}
									value={filters.startDate}
									emptyLabel="Seleccione fecha de inicio"
									maxDate={today}
									variant="inline"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Fin'}</p>
								<DatePicker
									autoOk
									className={classes.customTextField}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={date => handleDateChange(date, 1)}
									value={filters.endDate}
									emptyLabel="Seleccione fecha de fin"
									maxDate={today}
									variant="inline"
								/>
							</Grid>
						</MuiPickersUtilsProvider>
						<Grid container spacing={2} style={{ paddingLeft: '1%' }}>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Compañía'}</p>
								<Autocomplete
									id="companies"
									onChange={(e, value: any) => {
										handleFilterChange(value, 'company')
									}}
									options={companies.map(option => option)}
									value={filters.company}
									getOptionLabel={(option: any) => (option ? option['name'] : '')}
									renderInput={params => <TextField {...params} name="companies" value={filters.company} variant="outlined" className={classes.customAutocompleteField} />}
								/>
							</Grid>
							<Grid item xs={12} md={2} style={{ marginTop: 'auto', marginBottom: '5px', paddingLeft: '1%', paddingRight: '1%' }}></Grid>
							<Grid item xs={12} md={2} style={{ marginTop: 'auto', marginBottom: '5px', paddingLeft: '1%', paddingRight: '1%' }}>
								<Button variant="contained" color="primary" onClick={() => handleSubmit()}>
									Descargar
								</Button>
							</Grid>
							<Grid item xs={12} md={2} style={{ marginTop: 'auto', marginBottom: '5px', paddingLeft: '1%', paddingRight: '1%' }}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Container>
	) : (
		<div style={{ width: '100%', textAlign: 'center', paddingTop: '5%' }}>
			<CircularProgress />
		</div>
	)
}
