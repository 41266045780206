import { FC, Fragment, useState } from 'react'
import { AppBar, Box, Container, createStyles, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Theme, Toolbar } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { Link, NavLink, Redirect } from 'react-router-dom'
import Logo from '../../assets/img/logo.svg'
import IconButton from '@material-ui/core/IconButton'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		customAppBar: {
			background: '#0D1373',
			boxShadow: 'none',
		},
		customToolbar: {
			minHeight: 80,
			padding: 0,
		},
		boxLinks: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		customLink: {
			'color': '#FFFFFF',
			'minWidth': 113,
			'textDecoration': 'none',
			'&:hover, &:focus': {
				color: '#FFFFFF',
				textDecoration: 'none',
			},
		},
		customItemText: {
			'fontSize': 14,
			'textAlign': 'center',
			'& .MuiTypography-root': {
				fontFamily: 'Lato-Regular',
				textTransform: 'uppercase',
			},
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
	})
)

const AppBarDesktop: FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [token, setToken] = useState(localStorage.getItem('token'))
	const open = Boolean(anchorEl)
	const classes = useStyles()

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleLogout = () => {
		handleClose()
		localStorage.removeItem('token')
		setToken(null)
	}

	
	return (
		<Fragment>
			<AppBar position="fixed" className={classes.customAppBar}>
				<Container>
					<Toolbar className={classes.customToolbar}>
							<img alt={'Logo'} src={Logo} />
					</Toolbar>
				</Container>
			</AppBar>
		</Fragment>
	)
}

export default AppBarDesktop
