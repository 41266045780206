import * as Yup from 'yup'

export const addUserSchema = Yup.object().shape({
	firstName: Yup.string().min(2, 'El nombre debe ser más largo').required('Debes ingresar un nombre'),
	lastName: Yup.string().min(2, 'El nombre debe ser más largo').required('Debes ingresar un nombre'),
	email: Yup.string().email('Debes ingresar un email válido').required('Debes ingresar un correo electrónico'),
	phoneNumber: Yup.number().typeError('Debes ingresar solo números').required('Número de teléfono es obligatorio'),
	password: Yup.string().required('Debes ingresar una contraseña'),
	passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas no son iguales'),
})
