import { useState, useEffect, useContext } from 'react'
import { Box, createStyles, Grid, CircularProgress, Button, makeStyles, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { ProfileSchema } from '../validations/ProfileSchema'
import ProfileForm from '../components/Profile/ProfileForm'
import { getUser, editUser } from '../providers/Private/Provider'
import { ChangePasswordDialog } from '../components/Dialogs/ChangePasswordDialog'
import { UserContext } from '../context/UserContext'
const { format } = require('rut.js')

const useStyles = makeStyles(() =>
	createStyles({
		buttonAction: {
			borderRadius: 40,
			fontFamily: 'Lato-Bold',
			fontSize: 16,
			padding: '10px 0px',
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonCancel: {
			'color': '#000000',
			'backgroundColor': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#CDCDCD',
				color: '#000000',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonCreate: {
			'color': '#FFFFFF',
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		containerButtonGroup: {
			padding: '2rem 0',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
	})
)

const ProfilePage = () => {
	const userId = localStorage.getItem('userId') || ''
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [userData, setUserData] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		rut: '',
	})
	const [loader, setLoader] = useState(true)
	const [snackSettings, setSnackSettings] = useState({ show: false, type: '', message: '' })
	const [changePasswordDialog, setChangePasswordDialog] = useState(false)
	const { selectedOption, setSelectedOption } = useContext(UserContext)

	useEffect(() => {
		setSelectedOption({ ...selectedOption, item: 1 })
	}, [])

	const getUserById = () => {
		getUser(userId)
			.then(({ success, data }) => {
				if (success) {
					const { firstName, lastName, phoneNumber, email, rut } = data
					setUserData({
						firstName: firstName,
						lastName: lastName,
						phoneNumber: phoneNumber,
						email: email,
						rut: setInitialRut(rut),
					})
				}
			})
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const setInitialRut = (rut: string) => {
		return format(rut)
	}

	const handleSubmit = (values: any) => {
		setSendingData(true)
		const postData = setPostData(values)
		editUser(userId, postData)
			.then(({ success, response, message }) => {
				if (success) {
					setSendingData(false)
					launchSnack('success', message)
				} else {
					setSendingData(false)
					launchSnack('error', 'Ha ocurrido un error con la edición del perfil')
				}
			})
			.catch(error => {
				launchSnack('error', 'Ha ocurrido un error con la edición del perfil')
				setSendingData(false)
				console.log('error', error)
			})
	}

	const setPostData = (data: any) => {
		const { firstName, lastName, phoneNumber, email, rut } = data
		let clone = JSON.parse(JSON.stringify(data))

		clone = {
			firstName: firstName,
			lastName: lastName,
			phoneNumber: phoneNumber,
			email: email,
			rut: formatRut(rut),
		}

		return clone
	}

	const formatRut = (rut: string) => {
		let separateRut = rut.split('-')
		let rutBody = separateRut[0]
		let rutDv = separateRut[1]

		return `${rutBody.replace(/\./g, '')}-${rutDv}`
	}

	const launchSnack = (type: string, message: string) => {
		setSnackSettings({ show: true, type: type, message: message })
	}

	const handleCloseSnack = () => {
		setSnackSettings({ ...snackSettings, show: false })
	}

	const renderTypeAlert = (type: any) => {
		return type
	}

	useEffect(() => {
		getUserById()
	}, [])

	const handleChangePasswordDialogOpen = () => {
		setChangePasswordDialog(true)
	}

	const handleChangePasswordDialog = () => {
		setChangePasswordDialog(false)
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}

	return (
		<Box>
			<h1 className={'ct-light-black ff-LatoRegular fs25 tt-upper'}>{'Mi Perfil'}</h1>
			<Formik
				initialValues={{
					firstName: userData.firstName,
					lastName: userData.lastName,
					phoneNumber: userData.phoneNumber,
					email: userData.email,
					rut: userData.rut,
				}}
				validationSchema={() => ProfileSchema}
				onSubmit={(values: ProfileFields) => {
					handleSubmit(values)
				}}
			>
				{({ values, handleChange, handleSubmit }) => (
					<>
						<Form onSubmit={handleSubmit} autoComplete="off">
							<ProfileForm values={values} userData={userData} setUserData={setUserData} handleChange={handleChange} />
							<Grid container alignItems="center" justify="center" spacing={2} className={classes.containerButtonGroup}>
								<Grid item xs={12} md={4}>
									<Button
										classes={{ root: classes.buttonAction }}
										className={classes.buttonCreate}
										color="secondary"
										disabled={sendingData}
										fullWidth
										onClick={() => setChangePasswordDialog(true)}
										variant="contained"
									>
										{'Cambiar contaseña'}
										{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
									</Button>
								</Grid>
								<Grid item xs={12} md={4}></Grid>
								<Grid item xs={12} md={4}>
									<Button
										classes={{ root: classes.buttonAction }}
										className={classes.buttonCreate}
										color="primary"
										disabled={sendingData}
										fullWidth
										type="submit"
										variant="contained"
									>
										{'Guardar'}
										{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
									</Button>
								</Grid>
							</Grid>
						</Form>
						<ChangePasswordDialog handleDialog={handleChangePasswordDialog} open={changePasswordDialog} user={userData} />
					</>
				)}
			</Formik>
			<Snackbar anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} autoHideDuration={4000} onClose={handleCloseSnack} open={snackSettings.show}>
				<Alert className={'ff-LatoRegular'} onClose={handleCloseSnack} severity={renderTypeAlert(snackSettings.type)} variant="filled">
					{snackSettings.message}
				</Alert>
			</Snackbar>
		</Box>
	)
}

export default ProfilePage
