import { CircularProgress, createStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme, withStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useNotification } from '../../../hooks/useNotification'
import { getVisits } from '../../../providers/Private/Provider'
import { PopoverDetail } from '../../utils/PopoverDetails'

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

export const CreatedRoute = props => {
	const { visitId } = props
	const [visit, setVisit] = useState<any>()
	const [loader, setLoader] = useState(true)
	const [handleNotification] = useNotification()

	useEffect(() => {
		getVisits(visitId)
			.then(response => {
				if (response && response.success) {
					setVisit(response.data)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.finally(() => {
				setLoader(false)
			})
	}, [visitId])

	if (loader) {
		return (
			<div style={{ width: '100%', textAlign: 'center', padding: '1%' }}>
				<CircularProgress />
			</div>
		)
	}
	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<StyledTableCellHeader>{'Nombre del pasajero'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Dirección'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Teléfono'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Correo'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Comentarios'}</StyledTableCellHeader>
				</TableRow>
			</TableHead>
			<TableBody>
				<StyledTableRowBody>
					<StyledTableCellBody>{visit?.address?.collaborator?.fullName ? visit.address.collaborator.fullName : 'Sin Nombre'}</StyledTableCellBody>
					<StyledTableCellBody>
						{visit?.address?.street ? visit.address.street : 'Sin Calle'}
					</StyledTableCellBody>
					<StyledTableCellBody>{visit?.address?.collaborator?.phoneNumber ? visit.address.collaborator.phoneNumber : 'Sin Teléfono'}</StyledTableCellBody>
					<StyledTableCellBody>{visit?.address?.collaborator?.email ? visit.address.collaborator.email : 'Sin Email'}</StyledTableCellBody>
					<StyledTableCellBody>
						{visit?.address.comment ? visit?.address.comment?.length > 1 ? <PopoverDetail comment={visit.address.comment} /> : 'Sin comentarios' : 'Sin comentarios'}
					</StyledTableCellBody>
				</StyledTableRowBody>
			</TableBody>
		</Table>
	)
}
