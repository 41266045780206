import { FC, useState, useEffect, Fragment } from 'react'
import { Box, CircularProgress, Collapse, Grid, Paper, Table, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, Divider } from '@material-ui/core'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Redirect, Link } from 'react-router-dom'

import ExpandMore from '@material-ui/icons/ExpandMore'
import SendIcon from '@material-ui/icons/Send'
import GetAppIcon from '@material-ui/icons/GetApp'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ArrowLoader from '../assets/img/icons/arrowLoader.png'

import { getShift, putShiftNormalize, putShiftOptimize, putRouteNormalize, putRouteOptimize, getRouteOptimized, putApproveShift, putApproveRoute } from '../providers/Private/Provider'
import { useNotification } from '../hooks/useNotification'
import { PopoverDetail } from '../components/utils/PopoverDetails'
import { useMediaQuery } from 'react-responsive'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const { format } = require('date-fns')
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
		},
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
		},
	})
)(TableCell)
const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			backgroundColor: '#0D1373',
			color: '#FFFFFF',
		},
		body: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
		},
	})
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'fontFamily': 'Lato-Regular',
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
				textAlign: 'center',
			},
		},
	})
)(TableRow)

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const useStyles = makeStyles(() =>
	createStyles({
		'pageTitle': {
			color: '#113047',
			fontFamily: 'Lato-Regular',
			fontSize: 22,
			margin: '0',
		},
		'customTextField': {
			'& .MuiInputBase-input': {
				padding: '14px',
			},
		},
		'label': {
			fontSize: '18px',
			fontStyle: 'oblique',
			textTransform: 'capitalize',
		},
		'labelWithoutCap': {
			fontSize: '18px',
			fontStyle: 'oblique',
		},
		'buttonAction': {
			borderRadius: 40,
			padding: '10px 0px',
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		'overTime': {
			'color': '#FFFFFF',
			'backgroundColor': '#0D1373',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'editRoute': {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'boxBodyResume': {
			minHeight: 'calc(100vh - 155px)',
			padding: '24px 0',
			paddingTop: '4rem',
		},
		'boxProgress': {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		'customProgress': {
			color: '#EF9A1B',
		},
		'boxHeaderRoute': {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		'buttonSee': {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		'boxButtonAction': {
			marginTop: '2rem',
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		'buttonValidate': {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'buttonEdit': {
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'buttonBack': {
			'backgroundColor': '#FFFFFF',
			'borderRadius': 40,
			'border': '1px solid #7E7E7E',
			'color': '#7E7E7E',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				color: '#7E7E7E',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'boxLoading': {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 323px)',
			justifyContent: 'center',
			color: '#EF9A1B',
		},
		'buttonProgress': {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		'customTabsIndicator': {
			display: 'none',
		},
		'customTab': {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		'activeTab': {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		'buttonTableAction': {
			borderRadius: 40,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			minWidth: 120,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		'showRoute': {
			'backgroundColor': '#0D1373',
			'color': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'infoText': {
			fontFamily: 'Lato-Regular',
			fontSize: '16px',
			margin: 0,
			padding: '1rem',
		},
		'custCell': {
			padding: '0 !important',
		},
		'animatedItem': {
			animation: `$spin 2s infinite linear`,
		},
		'@global': {
			'@keyframes spin': {
				'0%': {
					transform: 'rotate(0deg)',
				},
				'100%': {
					transform: 'rotate(360deg)',
				},
			},
		},
	})
)
export const ExistingRoutePage: FC = (props: any) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const [openPassengers, setOpenPassengers] = useState()
	const id = props.match.params.id
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [loader, setLoader] = useState(true)
	const [normalized, setNormalized] = useState(false)
	const [wrongAddress, setWrongAddress] = useState({ status: false, data: null })
	const [sendingDataMessage, setSendingDataMessage] = useState('Validando Datos...')
	const [shift, setShift] = useState<any>()
	const [handleNotification] = useNotification()
	const [valueTab, setValueTab] = useState<any>(0)
	const [sendingOptimized, setSendingOptimized] = useState(false)
	const [verifyButton, setVerifyButton] = useState(false)
	const [getShiftOptimizedData, setShiftOptimizedData] = useState<any>([])
	const [isAproved, setIsAproved] = useState(false)
	const [isAllOptimized, setIsAllOptimized] = useState(false)
	const [entryData, setEntryData] = useState([])
	const [exitData, setExitData] = useState([])
	let allStatus: any = []

	const handleClick = (value: any) => {
		setOpenPassengers(openPassengers === value ? false : value)
	}

	const getShiftById = () => {
		getShift(id)
			.then((response: any) => {
				if (response && response.success) {
					const { routes } = response.data
					routes
						.sort((a, b) => new Date(`2020-01-01T${a.entryTime}`).getTime() - new Date(`2020-01-01T${b.entryTime}`).getTime())
						.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
					formatterData(response.data)
					let entry = routes.filter(item => item.serviceType === 0)
					let exit = routes.filter(item => item.serviceType === 1)
					setEntryData(entry)
					setExitData(exit)
					let getRoutesId = routes.map(route => {
						const { id, state } = route
						let arr: any
						allStatus.push(state)
						if (state === 1 || state === 3) {
							arr = id
						}
						return arr
					})
					getOptimizedRoutesData(getRoutesId)
					if (allStatus.length > 0) {
						setVerifyButton(allStatus.some(status => status === 1 || status === 3))
						setIsAproved(allStatus.some(status => status === 3))
						setIsAllOptimized(!allStatus.some(status => status !== 1))
					}
					// @ts-ignore: Object is possibly 'null'.
					let newTab = +localStorage.getItem('tab')

					if (newTab === 0) {
						setValueTab(0)
					} else if (newTab === 1) {
						setValueTab(1)
					} else {
						setValueTab(0)
					}
					if(entry.length === 0){
						setValueTab(1)
					}
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error', 'error')
			})
			.finally(() => {
				setLoader(false)
			})
	}

	useEffect(() => {
		if (entryData.length > 0 || exitData.length > 0) {
			setValueTab(entryData.length > 0 ? 0 : 1)
		}
	}, [entryData, exitData])

	const getOptimizedRoutesData = routesId => {
		let arr: any = []
		setLoader(true)
		routesId.map((routeId, index) => {
			getRouteOptimized(routeId)
				.then(response => {
					if (response && response.success) {
						arr.push(response.data)
					}
				})
				.finally(() => {
					setLoader(false)
				})
		})

		setShiftOptimizedData(arr)
	}

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy').toString()
	}

	const handleNormalizeShift = () => {
		setSendingData(true)
		putShiftNormalize(id)
			.then((response: any) => {
				setSendingDataMessage('Optimizando Rutas...')
				if (response && response.success) {
					putShiftOptimize(id)
						.then((response: any) => {
							if (response && response.success) {
								setNormalized(true)
							} else {
								handleNotification('Ha ocurrido un error con la optimización', 'error')
							}
						})
						.catch(err => {
							console.error(err)
						})
				} else {
					setWrongAddress({ status: true, data: response.data })
				}
			})
			.catch(error => {
				setSendingData(false)
				console.log('error', error)
				handleNotification('Ha ocurrido un error con la normalización', 'error')
			})
		resetTab()
	}

	const handleApproveShift = () => {
		putApproveShift(id).then(response => {
			if (response && response.success) {
				MySwal.fire({
					title: <p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>Turno Aprobado</p>,
					html: <p className={'ct-light-dark-gray ff-LatoRegular fs15 m-0'}>Su turno ha sido aprobado, ¡Gracias!</p>,
					icon: 'success',
				})
				getShiftById()
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
		resetTab()
	}

	const handleServiceTitle = value => {
		switch (value) {
			case 0:
				return 'INGRESO'
			case 1:
				return 'SALIDA'
			case 2:
				return 'INGRESO - SALIDA'
			default:
				return 'No Aplica'
		}
	}

	const handleStateTipe = value => {
		switch (value) {
			case 0:
				return 'Creado'
			case 1:
				return 'Optimizado'
			case 2:
				return 'Editado'
			case 3:
				return 'Aprobado'
			default:
				return 'Desconcido'
		}
	}

	useEffect(() => {
		getShiftById()
		return checkStatus()
	}, [])

	const formatterData = responseData => {
		const newStartDate = formatDate(new Date(`${responseData.routes[0]['startDate']}T${responseData.routes[0]['entryTime'] || '00:00:00'}`))
		let newShiftData: any

		newShiftData = {
			name: responseData.name,
			startDate: newStartDate,
			data: responseData.routes,
			store: responseData.store.name,
			costCenter: responseData.costCenter.name,
		}
		setShift(newShiftData)
	}

	const handleTabChange = (event, newValue) => {
		if (entryData.length > 0 && exitData.length > 0) {
			setValueTab(newValue)
		} else if (exitData.length > 0) {
			setValueTab(1)
		} else if (entryData.length > 0) {
			setValueTab(0)
		}
	}

	const handleOptimizeRoute = (id: string, serviceType) => {
		setSendingOptimized(true)
		putRouteNormalize(id)
			.then(response => {
				setSendingDataMessage('Optimizando Ruta...')
				if (response && response.success) {
					putRouteOptimize(id)
						.then((response: any) => {
							if (response && response.success) {
								handleNotification('Ruta optimizada con éxito', 'success')
								getShiftById()
								localStorage.setItem('tab', serviceType)
							} else {
								handleNotification('Ha ocurrido un error con la optimización', 'error')
							}
						})
						.catch(err => {
							console.error(err)
						})
						.finally(() => {
							setSendingOptimized(false)
						})
				} else {
					setWrongAddress({ status: true, data: response })
				}
			})
			.catch(() => {
				handleNotification('ups! algo ocurrió', 'error')
				setSendingOptimized(false)
			})
	}

	const handleApprobeRoute = (id: string, serviceType) => {
		setSendingOptimized(true)
		putApproveRoute(id)
			.then(response => {
				if (response && response.success) {
					handleNotification('Ruta aprobada con éxito', 'success')
					localStorage.setItem('tab', serviceType)
				}
			})
			.catch(err => {
				console.error(err)
				setSendingOptimized(false)
				handleNotification('Ha ocurrido un error con la aprobación', 'error')
			})
			.finally(() => {
				setSendingOptimized(false)
				getShiftById()
			})
	}

	useEffect(() => {
		checkStatus()
	}, [allStatus])

	const checkStatus = () => {
		if (allStatus.length > 0) {
			setVerifyButton(allStatus.some(status => status === 1 || status === 3))
			setIsAproved(allStatus.some(status => status === 3))
		}
	}
	const collaboratorsList = (nodes: any) => {
		let arr: any = []
		for (let [key, value] of Object.entries(nodes)) {
			arr.push(value)
		}
		return arr.sort((a, b) => a.order - b.order)
	}

	const getServiceStatus = (value: any) => {
		switch (value) {
			case 0:
				return 'No Iniciado'
			case 1:
				return 'Iniciado'
			case 2:
				return 'Completado'
			case 3:
				return 'Finalizado'
			default:
				return 'Desconocido'
		}
	}

	const getBoard = value => {
		switch (value) {
			case 0:
				return <HighlightOffIcon />
			case 1:
				return <CheckCircleOutlineIcon />
		}
	}

	const vehicleDetails = (driver, routeStatus) => {
		let driverFirstName = 'No'
		let driverLastName = 'Informado'
		let driverPatent = 'No Informado'
		if (driver) {
			driverFirstName = driver.firstName
			driverLastName = driver.lastName
			driverPatent = driver.vehicle.patent
		}

		if (routeStatus === 3) {
			return (
				<div style={{ paddingRight: '2%', fontFamily: 'Lato-Regular' }}>
					<small className={classes.infoText}>Conductor:</small>
					{`${driverFirstName} ${driverLastName}`}
					<small className={classes.infoText}>Patente:</small>
					{`${driverPatent}`}
				</div>
			)
		} else {
			return null
		}
	}

	const resetTab = () => {
		setValueTab(0)
		localStorage.removeItem('tab')
	}

	if (normalized) {
		return <Redirect push to={{ pathname: `/new-route/${id}/optimized-route` }} />
	}

	if (wrongAddress.status) {
		return <Redirect push to={{ pathname: `/new-route/${id}/wrong-address`, state: wrongAddress }} />
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}
	return shift?.name !== '' ? (
		shift?.data?.length > 0 ? (
			<Box className={classes.boxBodyResume}>
				<h1 className={classes.pageTitle}>{'Resumen del Turno'}</h1>
				<Grid container alignItems="center">
					<Grid item xs={12} md={6}>
						<Box className={classes.boxHeaderRoute}>
							<Grid container>
								<Grid container style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '1% 0%' }}>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Ruta'}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sucursal'}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Centro de Costo'}</p>
									</Grid>
								</Grid>
								<Grid container style={{ paddingBottom: '1%' }}>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.name}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.store}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.costCenter}</p>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
				{!sendingData ? (
					<Fragment>
						<Tabs
							variant="fullWidth"
							value={valueTab}
							onChange={handleTabChange}
							classes={{
								indicator: classes.customTabsIndicator,
							}}
							className={'mt-2r'}
						>
							{entryData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 0 ? classes.activeTab : ''} label="Ingreso" />}
							{exitData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 1 ? classes.activeTab : ''} label="Regreso" />}
						</Tabs>
						<Grid container>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<StyledTableCell>{'Fecha'}</StyledTableCell>
											<StyledTableCell align="right">{'Tipo'}</StyledTableCell>
											<StyledTableCell align="right">{'Hora'}</StyledTableCell>
											<StyledTableCell align="right">{'Estado Ruta'}</StyledTableCell>
											<StyledTableCell align="right">{'Estado Servicio'}</StyledTableCell>
											<StyledTableCell align="right">{'Acciones'}</StyledTableCell>
											<StyledTableCell align="right">{'Pasajeros'}</StyledTableCell>
											<StyledTableCell align="right">{'Distancia'}</StyledTableCell>
											<StyledTableCell align="right">{''}</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{shift.data.map(
											(travel: any, index: number) =>
												travel.serviceType === valueTab && (
													<Fragment key={index}>
														<StyledTableRow>
															<StyledTableCell component="th" scope="row" align="center">
																{formatDate(new Date(`${travel.startDate}T${travel.entryTime}`))}
															</StyledTableCell>
															<StyledTableCell align="center">{handleServiceTitle(travel.serviceType)}</StyledTableCell>
															<StyledTableCell align="center">{travel.entryTime.slice(0, -3)}</StyledTableCell>
															<StyledTableCell align="center">{handleStateTipe(travel.state)}</StyledTableCell>
															<StyledTableCell align="center">
																{getServiceStatus(travel.plan?.tours[0]?.statusOrder ? travel.plan.tours[0].statusOrder : 0)}
															</StyledTableCell>
															<StyledTableCell align="center">
																{travel.state === 1 || travel.state === 3 ? (
																	<Button
																		classes={{ root: classes.buttonTableAction }}
																		className={classes.editRoute}
																		color="primary"
																		download
																		href={`${process.env.REACT_APP_API_XLSX}/api/routes/${travel.id}/download-excel`}
																		variant="contained"
																	>
																		{'Descargar ruta'}
																	</Button>
																) : (
																	<PopoverDetail comment={'La ruta debe estar en estado Optimizado o Aprobado para descargar excel'} index={index} />
																)}
															</StyledTableCell>
															<StyledTableCell align="center">
																{travel.visits.length > 0 ? (
																	<Button
																		classes={{
																			root: classes.buttonSee,
																		}}
																		color="primary"
																		onClick={() => handleClick(index)}
																		endIcon={<ExpandMore />}
																	>
																		{'Ver Pasajeros'}
																	</Button>
																) : (
																	'Sin pasajeros'
																)}
															</StyledTableCell>
															<StyledTableCell align="center">{travel.estimatedDistance} KM</StyledTableCell>
															<StyledTableCell align="center">
																{(travel.state === 0 || travel.state === 2) && (
																	<Button
																		classes={{ root: classes.buttonTableAction }}
																		className={classes.showRoute}
																		color="primary"
																		variant="contained"
																		disabled={sendingOptimized}
																		onClick={() => handleOptimizeRoute(travel.id, travel.serviceType)}
																		endIcon={<SendIcon />}
																	>
																		{'Optimizar'}
																		{sendingOptimized && <CircularProgress size={24} className={classes.buttonProgress} />}
																	</Button>
																)}
																{travel.state === 1 && (
																	<Button
																		classes={{ root: classes.buttonTableAction }}
																		className={classes.showRoute}
																		color="primary"
																		variant="contained"
																		disabled={sendingOptimized}
																		onClick={() => handleApprobeRoute(travel.id, travel.serviceType)}
																		endIcon={<SendIcon />}
																	>
																		{'Aprobar'}
																		{sendingOptimized && <CircularProgress size={24} className={classes.buttonProgress} />}
																	</Button>
																)}
															</StyledTableCell>
														</StyledTableRow>
														{travel.state === 0 || travel.state === 2 ? (
															<>
																<StyledTableRowBody>
																	<StyledTableCellHead className={classes.custCell} colSpan={9}>
																		<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
																			<Box margin={1}>
																				<Table size="small">
																					{travel.visits.length > 0 && (
																						<TableHead>
																							<TableRow>
																								<StyledTableCellHeader>{'Nombre del pasajero'}</StyledTableCellHeader>
																								<StyledTableCellHeader>{'Dirección'}</StyledTableCellHeader>
																								<StyledTableCellHeader>{'Correo'}</StyledTableCellHeader>
																								<StyledTableCellHeader>{'Teléfono'}</StyledTableCellHeader>
																								<StyledTableCellHeader>{'Comentarios'}</StyledTableCellHeader>
																							</TableRow>
																						</TableHead>
																					)}
																					<TableBody>
																						{travel.visits.length > 0 &&
																							travel.visits.map((passenger, indexPassengers) => (
																								<StyledTableRowBody key={indexPassengers}>
																									<StyledTableCellBody>
																										{passenger?.address.collaborator.fullName && passenger.address.collaborator.fullName}
																									</StyledTableCellBody>
																									<StyledTableCellBody>
																										{passenger.address?.countyId && passenger.address.countyId}{' '}
																										{passenger.address?.street && passenger.address.street}
																									</StyledTableCellBody>
																									<StyledTableCellBody>{passenger.address.collaborator.email}</StyledTableCellBody>
																									<StyledTableCellBody>
																										{passenger?.address.collaborator.phoneNumber && passenger.address.collaborator.phoneNumber}
																									</StyledTableCellBody>
																									<StyledTableCellBody>
																										{passenger.address.comment ? (
																											passenger.address.comment?.length > 1 ? (
																												<PopoverDetail comment={passenger.address.comment} index={indexPassengers} />
																											) : (
																												'Sin comentarios'
																											)
																										) : (
																											'Sin comentarios'
																										)}
																									</StyledTableCellBody>
																								</StyledTableRowBody>
																							))}
																					</TableBody>
																				</Table>
																			</Box>
																		</Collapse>
																	</StyledTableCellHead>
																</StyledTableRowBody>
															</>
														) : (
															<StyledTableRowBody>
																<StyledTableCellHead className={classes.custCell} colSpan={9}>
																	<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
																		{getShiftOptimizedData.map((item, index) => {
																			if (item !== undefined && item?.id === travel.id && item?.plan?.tours?.length !== 0) {
																				return item?.plan?.tours?.map((vehicle, indexVehicle) => {
																					return Object.keys(vehicle.nodes).length > 0 ? (
																						<Box key={index}>
																							<Grid container>
																								<Grid item xs={12} md={4}>
																									<p className={classes.infoText}>
																										{`Vehículo ${indexVehicle + 1}`}
																										<small className={'ml-1r'}>{`(cantidad de pasajeros ${
																											Object.keys(vehicle.nodes).length
																										})`}</small>
																									</p>
																								</Grid>
																								<Grid item xs={12} md={4}>
																									<p className={classes.infoText}>{`Distancia del viaje: ${vehicle.estimatedDistance} KM`}</p>
																								</Grid>
																								<Grid item xs={12} md={4}>
																									<div style={{ textAlign: 'right' }}>
																										<p>{vehicleDetails(vehicle.driver, travel.state)}</p>
																									</div>
																								</Grid>
																							</Grid>

																							<Table size="small">
																								<TableHead>
																									<TableRow>
																										<StyledTableCellHead>{'Nombre del pasajero'}</StyledTableCellHead>
																										<StyledTableCellHead>{'Dirección'}</StyledTableCellHead>
																										<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
																										<StyledTableCellHead>{'Orden de Recogida'}</StyledTableCellHead>
																										<StyledTableCellHead>{'Hora'}</StyledTableCellHead>
																										{travel.state === 3 && <StyledTableCellHead>{'Abordaje'}</StyledTableCellHead>}
																										{travel.state === 3 && <StyledTableCellHead>{'QR'}</StyledTableCellHead>}
																									</TableRow>
																								</TableHead>
																								<TableBody>
																									{collaboratorsList(vehicle.nodes).map((node, index) => {
																										return (
																											<StyledTableRowBody key={index}>
																												<StyledTableCellBody>{node.visit.address.collaborator.fullName}</StyledTableCellBody>
																												<StyledTableCellBody>
																													{`${node.visit.address.street}, ${
																														node.visit.address.county?.name ? node.visit.address.county.name : ''
																													}`}
																												</StyledTableCellBody>
																												<StyledTableCellBody>{node.visit.address.collaborator.phoneNumber}</StyledTableCellBody>
																												<StyledTableCellBody>{node.order}</StyledTableCellBody>
																												<StyledTableCellBody>
																													{node.arrival.length === 8 ? node.arrival.slice(0, -3) : node.arrival}
																												</StyledTableCellBody>
																												{travel.state === 3 && (
																													<StyledTableCellBody>
																														{travel.plan?.tours[0]?.statusOrder === 1
																															? getBoard(node.visit.confirmed)
																															: 'No iniciado'}
																													</StyledTableCellBody>
																												)}
																												{travel.state === 3 && (
																													<StyledTableCellBody>
																														{travel.plan?.tours[0]?.statusOrder === 1 ? (
																															<Button
																																download
																																href={`${process.env.REACT_APP_API_XLSX}/api/collaborators/${node.visit.address.collaborator.id}/qr-download`}
																															>
																																<GetAppIcon />
																															</Button>
																														) : (
																															'No iniciado'
																														)}
																													</StyledTableCellBody>
																												)}
																											</StyledTableRowBody>
																										)
																									})}
																								</TableBody>
																							</Table>
																						</Box>
																					) : (
																						<>
																							<Box>
																								<p className={classes.infoText}>
																									{`Vehículo ${indexVehicle + 1}`}
																									<small className={'ml-1r'}>{'(no hay pasajeros disponibles)'}</small>
																								</p>
																							</Box>
																							<Divider />
																						</>
																					)
																				})
																			}
																		})}
																	</Collapse>
																</StyledTableCellHead>
															</StyledTableRowBody>
														)}
													</Fragment>
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Fragment>
				) : (
					<Box className={classes.boxLoading}>
						<img src={ArrowLoader} className={classes.animatedItem} />
						<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'} style={{ color: '#EF9A1B' }}>
							{sendingDataMessage}
						</p>
					</Box>
				)}
				<Grid container alignItems="center" justify={isMobile ? 'center' : 'space-between'} className={'mt-2r'} style={{ paddingTop: '3rem' }}>
					<Grid item xs={12} md={3} className={isMobile ? 'mb-1-5r' : ''}>
						{!sendingData && (
							<Button className={classes.buttonBack} color="primary" component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
								{'Volver'}
							</Button>
						)}
					</Grid>
					<Grid item xs={12} md={1} className={isMobile ? 'mb-1-5r' : ''}></Grid>
					<Grid item xs={12} md={1} className={isMobile ? 'mb-1-5r' : ''}>
						{!sendingData && (isAllOptimized || isAproved) && (
							<Button
								className={classes.buttonEdit}
								fullWidth
								startIcon={<GetAppIcon />}
								color="primary"
								variant="contained"
								download
								href={`${process.env.REACT_APP_API_XLSX}/api/shifts/${id}/download-excel`}
							>
								{'Descargar excel'}
							</Button>
						)}
					</Grid>
					<Grid item xs={12} md={1} className={isMobile ? 'mb-1-5r' : ''}></Grid>
					<Grid item xs={12} md={1} className={isMobile ? 'mb-1-5r' : ''}>
						{!sendingData && (
							<Button component={Link} to={`/my-routes/${id}/edit`} onClick={() => resetTab()} className={classes.buttonEdit} fullWidth color="primary" variant="contained">
								{'Editar Turno'}
							</Button>
						)}
					</Grid>
					<Grid item xs={12} md={1} className={isMobile ? 'mb-1-5r' : ''}></Grid>
					<Grid item xs={12} md={2}>
						{!verifyButton && !sendingData && (
							<Button className={classes.buttonValidate} color="primary" fullWidth endIcon={<SendIcon />} disabled={sendingData} onClick={() => handleNormalizeShift()}>
								{'Optimizar Todos'}
								{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Button>
						)}
						{isAllOptimized && !sendingData && (
							<Button className={classes.buttonValidate} color="primary" fullWidth endIcon={<SendIcon />} disabled={sendingData} onClick={() => handleApproveShift()}>
								{'Aprobar'}
								{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Button>
						)}
					</Grid>
				</Grid>
			</Box>
		) : (
			<Box className={classes.boxProgress}>
				<Grid container>
					<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
						<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
					</Grid>
					<Grid item xs={12} md={4} style={{ textAlign: 'center' }}></Grid>
					<Grid item xs={12} md={4} style={{ textAlign: 'center', paddingTop: '4rem' }}>
						<Button className={classes.buttonBack} color="primary" onClick={() => resetTab()} component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
							{'Volver'}
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
				</Grid>
				<Grid item xs={12} md={4} style={{ textAlign: 'center' }}></Grid>
				<Grid item xs={12} md={4} style={{ textAlign: 'center', paddingTop: '4rem' }}>
					<Button className={classes.buttonBack} color="primary" onClick={() => resetTab()} component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
						{'Volver'}
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}
