import { useState } from 'react'
import { createStyles, InputAdornment, makeStyles, TextField, Typography, IconButton } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Form, Formik, ErrorMessage } from 'formik'
import Title from './Tools/Title'
import ButtonAction from './Tools/ButtonAction'
import ButtonLink from './Tools/ButtonLink'
import { authSchema } from '../../validations/authSchema'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'margin': '1rem 0',
			'fontFamily': 'Lato-Regular',
			'& .MuiInput-underline:before': {
				borderBottomColor: '#F2F3F8',
				borderRadius: '25px',
				boxSizing: 'border-box',
				border: '1px solid #FFFFFF',
			},
			'& .MuiInput-underline:hover:before': {
				borderBottomColor: '#F2F3F8',
				borderRadius: '25px',
				boxSizing: 'border-box',
				border: '1px solid #FFFFFF',
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: '#FFFFFF',
				borderRadius: '25px',
				boxSizing: 'border-box',
				border: '1px solid #FFFFFF',
				background: 'transparent',
			},
			'& .MuiOutlinedInput-inputAdornedStart': {
				paddingLeft: '5%',
				fontFamily: 'Lato-Regular',
				background: 'transparent',
			},
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		input: {
			'color': '#FFFFFF !important',
			'fontFamily': 'Lato-Regular',
			'borderRadius': '25px',
			'boxSizing': 'border-box',
			'border': '1px solid #FFFFFF',
			'filter': 'none',
			'& .MuiInput-underline:focus': {
				borderBottomColor: '#FFFFFF',
				borderRadius: '25px',
				boxSizing: 'border-box',
				border: '1px solid #FFFFFF !important',
				fontFamily: 'Lato-Regular',
				background: 'transparent',
			},
			'root': {
				border: '1px solid #FFFFFF !important',

				focused: {
					border: '1px solid #FFFFFF !important' ,
				},
			},
			'@global': {
				'input:-webkit-autofill': {
					'borderRadius': '25px',
					'border': '1px solid #FFFFFF !important',
					'fontFamily': 'Lato-Regular',
					'transition-delay': '9999s',
				},
				':-webkit-autofill:focus': {
					fontFamily: 'Lato-Regular',
				},
			},
		},
	})
)

const LoginForm = ({ handleLogin, setAction, sendingData }) => {
	const [visibilityPassword, setVisibilityPassword] = useState(false)
	const classes = useStyles()

	return (
		<>
			<Title label={'Iniciar Sesión'} />
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={authSchema}
				onSubmit={(values: IUser) => {
					handleLogin(values)
				}}
			>
				{({ values, handleChange, handleSubmit }) => (
					<Form onSubmit={handleSubmit} autoComplete="off">
						<TextField
							classes={{ root: classes.customTextField }}
							fullWidth
							id="email"
							placeholder="Usuario"
							name="email"
							onChange={handleChange}
							value={values.email}
							variant={'outlined'}
							InputProps={{
								className: classes.input,
								startAdornment: (
									<InputAdornment position="start">
										<PersonOutlinedIcon style={{ color: '#F28F16' }} />
									</InputAdornment>
								),
							}}
							helperText={<ErrorMessage name="email" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
						/>
						<TextField
							classes={{ root: classes.customTextField }}
							fullWidth
							id="password"
							placeholder="Contraseña"
							type={visibilityPassword ? 'text' : 'password'}
							name="password"
							onChange={handleChange}
							value={values.password}
							variant={'outlined'}
							InputProps={{
								className: classes.input,
								startAdornment: (
									<InputAdornment position="start">
										<LockIcon style={{ color: '#F28F16' }} />
									</InputAdornment>
								),
								endAdornment: (
									<IconButton className={'ct-white'} onClick={() => setVisibilityPassword(!visibilityPassword)}>
										<InputAdornment position="end">{visibilityPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</InputAdornment>
									</IconButton>
								),
							}}
							helperText={<ErrorMessage name="password" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
						/>
						<ButtonAction handleSubmit={handleSubmit} label={'Iniciar Sesión'} sendingData={sendingData} />
					</Form>
				)}
			</Formik>
			<ButtonLink type={'recovery'} setAction={setAction} label={'Recuperar Contraseña'} />
		</>
	)
}

export default LoginForm
