import { FC } from 'react'
import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import es from 'date-fns/locale/es'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import CloseIcon from '@material-ui/icons/Close'
import { putExtraHour } from '../../providers/Private/Provider'
import { useNotification } from '../../hooks/useNotification'
const es_ES = require('date-fns/locale/es')

const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#595B62',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			textAlign: 'center',
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(even)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		table: {
			minWidth: 700,
			textAlign: 'center',
			width: '100%',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		buttonActionSave: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 240,
			'opacity': 1,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		custDialogContent: {
			padding: '1.5rem 24px',
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem',
		},
	})
)

const EditHourPassengerDialog: FC<EditHourPassengerDialogProps> = ({ handleDialog, open, passengerData, routeId, serviceType, entryTime, exitTime, setEntryTime, setExitTime, startDate }) => {
	const classes = useStyles()
	const [handleNotification] = useNotification()

	const handleTime = event => {
		serviceType > 0 ? setExitTime(event) : setEntryTime(event)
	}

	const saveSchedule = () => {
		handleSubmitDialog()
		handleDialog()
	}

	const handleSubmitDialog = () => {
		let time
		serviceType > 0 ? (time = exitTime) : (time = entryTime)
		let newEntryTime = setFormatTime(time)
		const postData = {
			visits: [passengerData.id],
			startDate: setFormatDate(new Date(`${startDate}T${newEntryTime}:00`)),
			hours: setFormatTime(time),
		}
		putExtraHour(routeId, postData)
			.then(response => {
				if (response && response.success) {
					handleDialog()
					handleNotification('Hora modificada con éxito', 'success')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.log(err)
				handleNotification('Error en la modificación de la hora', 'error')
			})
	}

	const setFormatTime = (time: any) => {
		return format(time, 'HH:mm')
	}

	const setFormatDate = (date: any) => {
		return format(date, 'yyyy-MM-dd').toString()
	}

	const formatterDay = () => {
		let day: string = ''
		if (startDate !== undefined) {
			let newEntryTime = setFormatTime(entryTime)
			day = format(new Date(`${startDate}T${newEntryTime}:00`), 'PPPP', { locale: es_ES })

			return day
		}
		return day
	}

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg">
			<DialogTitle>
				<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>
					{'Editar Hora de '}
					{serviceType > 0 ? 'Salida' : 'Ingreso'}
				</h1>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent classes={{ root: classes.custDialogContent }}>
				<TableContainer component={Paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<StyledTableCellHead>Día</StyledTableCellHead>
								<StyledTableCellHead>{serviceType > 0 ? 'Salida' : 'Ingreso'}</StyledTableCellHead>
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRowBody>
								<StyledTableCellBody style={{ textTransform: 'capitalize' }}>{formatterDay()}</StyledTableCellBody>
								<StyledTableCellBody>
									<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
										<TimePicker
											ampm={false}
											className={classes.customTextField}
											clearable
											inputVariant="outlined"
											name={serviceType > 0 ? 'exitTime' : 'entryTime'}
											onChange={event => handleTime(event)}
											value={serviceType > 0 ? exitTime : entryTime}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<QueryBuilderIcon />
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</StyledTableCellBody>
							</StyledTableRowBody>
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
				<Button classes={{ root: classes.buttonActionSave }} color="primary" onClick={() => saveSchedule()} variant="contained">
					{'Guardar Horario'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default EditHourPassengerDialog
