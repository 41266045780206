import { FC, Fragment, useState } from 'react'
import {
	AppBar,
	Box,
	Button,
	createStyles,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Theme,
	Toolbar,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { Link, NavLink, Redirect } from 'react-router-dom'
import Logo from '../../assets/img/logo.svg'
import UserMobile from '../../assets/img/icons/user-mobile.svg'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		customAppBar: {
			background: '#0D1373',
			boxShadow: 'none',
		},
		customToolbar: {
			minHeight: 80,
		},
		menuButton: {
			color: 'white',
			left: '2rem',
			position: 'absolute'
		},
		customPaperDrawer: {
			width: '293px',
		},
		boxActions: {
			background: '#0D1373',
			color: 'white',
			minHeight: '164px',
			width: '100%',
		},
		buttonClose: {
			color: 'white',
			position: 'absolute',
			right: '0',
			top: '10px',
		},
		boxUserIcon: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '0.5rem 0'
		},
		customList: {
			padding: '1rem',
		},
		customItemText: {
			fontSize: 14,
			textAlign: 'start',
			'& .MuiTypography-root': {
				fontFamily: 'Lato-Regular',
				textTransform: 'uppercase',
			},
		},
		customLink: {
			color: '#0D1373',
			textDecoration: 'none',
			'&:hover, &:focus': {
				color: '#0D1373',
				textDecoration: 'none',
			},
		},
		customLinkPerfil: {
			color: '#FFFFFF',
			textDecoration: 'none',
			'&:hover, &:focus': {
				color: '#FFFFFF',
				textDecoration: 'none',
			},
		},
	})
)

const AppBarMobile: FC = () => {
	const [drawer, setDrawer] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [token, setToken] = useState(localStorage.getItem('token'))
	const classes = useStyles()

	const handleDrawer = (action) => (event) => {
		setDrawer(action)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleLogout = () => {
		handleClose()
		localStorage.removeItem('token')
		localStorage.removeItem('userId')
		setToken(null)
	}
	
	const list = () => (
		<Box className={classes.customPaperDrawer} role='presentation'>
			<Box className={classes.boxActions}>
				<Box className={classes.boxUserIcon}>
					<img alt='Mobile User' src={UserMobile} />
				</Box>
				<Button classes={{ root: classes.buttonClose }} onClick={handleDrawer(false)}>
					<CloseIcon />
				</Button>
				{token !== null ? (
					<>
						<List classes={{ root: classes.customList }}>
							<NavLink className={classes.customLinkPerfil} to={'/my-profile'} onClick={handleDrawer(false)}>
								<ListItem button>
									<ListItemText className={classes.customItemText} primary={'Mi Perfil'} />
								</ListItem>
							</NavLink>
							<NavLink className={classes.customLinkPerfil} to={'/administration'} onClick={handleDrawer(false)}>
								<ListItem button>
									<ListItemText className={classes.customItemText} primary={'Administración'} />
								</ListItem>
							</NavLink>
							<NavLink className={classes.customLinkPerfil} to={'/'} onClick={handleLogout}>
								<ListItem button>
									<ListItemText className={classes.customItemText} primary={'Cerrar Sesión'} />
								</ListItem>
							</NavLink>
						</List>
					</>
				) : (
					<NavLink className={classes.customLinkPerfil} to={'/auth'}>
						<ListItem button>
							<ListItemText className={classes.customItemText} primary={'Login'} />
						</ListItem>
					</NavLink>
				)}
			</Box>
      <List classes={{ root: classes.customList }}>
				<NavLink className={classes.customLink} to={'/'} onClick={handleDrawer(false)}>
					<ListItem button>
						<ListItemText className={classes.customItemText} primary={'Aeropuerto'} />
					</ListItem>
				</NavLink>
				<NavLink className={classes.customLink} to={'/new-route'} onClick={handleDrawer(false)}>
					<ListItem button>
						<ListItemText className={classes.customItemText} primary={'Rutas'} />
					</ListItem>
				</NavLink>
				<NavLink className={classes.customLink} to={'/reports'} onClick={handleDrawer(false)}>
					<ListItem button>
						<ListItemText className={classes.customItemText} primary={'Reportes'} />
					</ListItem>
				</NavLink>
				<NavLink className={classes.customLink} to={'/'} onClick={handleDrawer(false)}>
					<ListItem button>
						<ListItemText className={classes.customItemText} primary={'Ayuda'} />
					</ListItem>
				</NavLink>
      </List>
		</Box>
  )

	return (
		<Fragment>
			<AppBar className={classes.customAppBar}>
				<Toolbar className={classes.customToolbar}>
					<Box display='flex' alignItems='center' justifyContent='center' width='100%'>
						{/* <IconButton edge='start' className={classes.menuButton} onClick={handleDrawer(true)}>
							<MenuIcon />
						</IconButton>
						<Drawer anchor='left' open={drawer} onClose={handleDrawer(false)} classes={{ docked: classes.customPaperDrawer }}>
							{list()}
						</Drawer> */}
						{/* <Link to='/'> */}
							<img alt='Mobile Logo' src={Logo} />
						{/* </Link> */}
					</Box>
				</Toolbar>
			</AppBar>
		</Fragment>
  )
}

export default AppBarMobile
