import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from '../../context/UserContext'
import clsx from 'clsx'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Box, Button, Grid, Menu, MenuItem } from '@material-ui/core'
import { Link, NavLink, Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Eye from '../../assets/img/navbar/eyeAppbar.png'
import Route from '../../assets/img/navbar/createRouteAppbar.png'
import Back from '../../assets/img/navbar/backAppbar.png'
import LogoHeader from '../../assets/img/logoHeader.png'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import Profile from '../../assets/img/navbar/userMenu.png'
import Aeroport from '../../assets/img/navbar/aeroportMenu.png'
import Reports from '../../assets/img/navbar/reportsMenu.png'
import ProfileSelected from '../../assets/img/navbar/userMenuSelected.png'
import AeroportSelected from '../../assets/img/navbar/aeroportMenuSelected.png'
import ReportsSelected from '../../assets/img/navbar/reportMenuSelected.png'
import Routes from '../../assets/img/navbar/routesMenu.png'
import RoutesSelected from '../../assets/img/navbar/routesMenuSelected.png'
import Help from '../../assets/img/navbar/helpMenu.png'
import HelpSelected from '../../assets/img/navbar/helpMenuSelected.png'
import Session from '../../assets/img/navbar/sessionMenu.png'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			background: '#323688',
			height: '70px',
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerCommon: {
			height: '88px',
			position: 'fixed',
			backgroundColor: '#0D1373',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		commonIconMenu: {
			marginTop: '-25px',
			width: `${drawerWidth}px`,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			background: '#0D1373',
			color: '#FFFFFF',
			width: drawerWidth,
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
			justifyContent: 'flex-end',
		},
		content: {
			flexGrow: 1,
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginLeft: -drawerWidth,
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		},
		itemFullCenter: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			margin: '1.5rem 0',
		},
		customButton: {
			'backgroundColor': 'transparent',
			'border': '2px',
			'borderColor': 'white',
			'borderRadius': '75px',
			'borderStyle': 'solid',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 48,
			'width': 210,
			'justifyContent': 'space-evenly',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover, &:focus': {
				background: '#0D1373',
				borderColor: '#F28F16',
				color: '#F28F16',
			},
		},
		customButtonMob: {
			'backgroundColor': 'transparent',
			'border': '2px',
			'borderColor': 'white',
			'borderRadius': '100px',
			'borderStyle': 'solid',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 10,
			'height': 10,
			'justifyContent': 'space-evenly',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover, &:focus': {
				background: '#0D1373',
			},
			'& img': {
				height: 'auto',
				width: '55px',
			},
		},
		imgButton: {
			width: '37px',
			height: '44px',
			left: '30px',
		},
		customLink: {
			'padding': '10% 5% 10% 5%',
			'color': '#FFFFFF',
			'minWidth': 113,
			'textDecoration': 'none',
			'textAlign': 'center',
			'display': 'flex',
			'flexDirection': 'column',
			'&:hover, &:focus': {
				color: '#FFFFFF',
				textDecoration: 'none',
				cursor: 'pointer',
			},
		},
		link: {
			textDecoration: 'none',
			color: 'white',
		},
		customItemText: {
			'fontSize': 14,
			'textAlign': 'center',
			'& .MuiTypography-root': {
				fontFamily: 'Lato-Regular',
				textTransform: 'uppercase',
			},
		},
		itemSelected: {
			'color': '#F28F16',
			'&:link': {
				color: '#F28F16',
				textDecoration: 'none',
				cursor: 'pointer',
			},
		},
		customMenuItem: {
			display: 'flex',
			flexDirection: 'column',
			fontFamily: 'Lato-Regular',
		},
		contentStyle: {
			padding: 0,
			margin: 0,
			width: '100%',
		},
	})
)

export const AppBarDesktop = ({ content }) => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const { routesSection, setRoutesSection, selectedOption, setSelectedOption } = useContext(UserContext)
	const [open, setOpen] = useState(true)

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [token, setToken] = useState(localStorage.getItem('token'))
	const openMenu = Boolean(anchorEl)

	const handleReset = () => {
		setSelectedOption({ selected: false, item: 0 })
	}

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleSelect = (option: number) => {
		setRoutesSection(false)
		if (option !== selectedOption.item) {
			setSelectedOption({ ...selectedOption, item: option })
		}
	}	

	const handleLogout = () => {
		handleCloseMenu()
		localStorage.removeItem('token')
		setToken(null)
	}

	if (token === null) {
		return <Redirect push to={{ pathname: '/auth' }} />
	}

	return (
		<div className={classes.root}>
			{selectedOption.selected ? (
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: open,
					})}
				>
					<Toolbar>
						<IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, open && classes.hide)}>
							<MenuIcon fontSize="large" />
						</IconButton>
						{routesSection && (
							<Grid container justify="flex-end" spacing={2}>
								<Grid item xs={4} md={2} className={classes.itemFullCenter}>
									<Button className={isMobile ? classes.customButtonMob : classes.customButton} color="primary" component={Link} fullWidth to={'/my-routes'} variant="contained">
										<img alt={'Street Route'} src={Eye} style={{ width: '28px', height: '20px' }} />
										{'MIS RUTAS'}
									</Button>
								</Grid>
								<Grid item xs={4} md={2} className={classes.itemFullCenter}>
									<Button
										className={isMobile ? classes.customButtonMob : classes.customButton}
										color="primary"
										component={Link}
										fullWidth
										to={'/new-route/common-route'}
										variant="contained"
									>
										<img alt={'Street Route'} src={Route} className={classes.imgButton} />
										{'CREAR RUTA'}
									</Button>
								</Grid>
								<Grid item xs={4} md={1} className={classes.itemFullCenter}>
									<Link to={'/new-route'}>
										<img alt={'atrás'} src={Back} />
									</Link>
								</Grid>
								<Grid item xs={4} md={1} className={classes.itemFullCenter}></Grid>
							</Grid>
						)}
					</Toolbar>
				</AppBar>
			) : (
				<Drawer
					variant="persistent"
					anchor="left"
					open={!open}
					classes={{
						paper: classes.drawerCommon,
					}}
				>
					<IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.commonIconMenu, open && !classes.hide)}>
						<MenuIcon fontSize="large" style={{ color: '#FFFFFF' }} />
					</IconButton>
				</Drawer>
			)}

			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<IconButton style={{ color: 'white', margin: 'auto' }} onClick={handleDrawerClose}>
						<MenuIcon fontSize="large" />
					</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem className={classes.customLink} onClick={handleReset}>
						<Link to={'/'} className={`${classes.link}`}>
							<img alt={'Logo'} src={LogoHeader} />
						</Link>
					</ListItem>
					{token !== null ? (
						<>
							<ListItem className={classes.customMenuItem} onClick={handleProfileMenu}>
								{selectedOption.item === 1 ? <img src={ProfileSelected} /> : <img src={Profile} />}
								<ListItem onClick={handleProfileMenu} color="inherit">
									<ListItemText className={classes.customItemText} primary={'Mi Perfil'} />
								</ListItem>
							</ListItem>
							<Menu id="menu-appbar" anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
								<MenuItem
									component={Link}
									to={'/my-profile'}
									onClick={() => {
										handleCloseMenu()
										handleSelect(1)
									}}
								>
									<ListItemIcon>
										<AccountCircle fontSize="small" />
									</ListItemIcon>
									{'Mi Perfil'}
								</MenuItem>
								<MenuItem
									component={Link}
									to={'/administration'}
									onClick={() => {
										handleCloseMenu()
										handleSelect(1)
									}}
								>
									<ListItemIcon>
										<SupervisorAccountIcon fontSize="small" />
									</ListItemIcon>
									{'Administración'}
								</MenuItem>
							</Menu>
						</>
					) : (
						<NavLink className={classes.customLink} to={'/auth'}>
							<ListItem button>
								<ListItemText className={classes.customItemText} primary={'Login'} />
							</ListItem>
						</NavLink>
					)}
					<ListItem className={classes.customLink}>
						<Link to={'/'} className={`${classes.link} ${selectedOption.item === 2 && classes.itemSelected}`} onClick={() => handleSelect(2)}>
							{selectedOption.item === 2 ? <img src={AeroportSelected} style={{ padding: '2.8994px 0px 0px 0px', width: '50.2px' }} /> : <img src={Aeroport} style={{ width: '50px' }} />}
							<ListItemText className={classes.customItemText} primary={'Aeropuerto'} />
						</Link>
					</ListItem>
					<ListItem className={classes.customLink}>
						<Link to={'/new-route'} className={`${classes.link} ${selectedOption.item === 3 && classes.itemSelected}`} onClick={() => handleSelect(3)}>
							{selectedOption.item === 3 ? <img src={RoutesSelected} /> : <img src={Routes} />}
							<ListItemText className={classes.customItemText} primary={'Rutas'} />
						</Link>
					</ListItem>
					<ListItem className={`${classes.customLink}`}>
						<Link to={'/reports'} className={`${classes.link} ${selectedOption.item === 4 && classes.itemSelected}`} onClick={() => handleSelect(4)}>
							{selectedOption.item === 4 ? <img src={ReportsSelected} style={{ padding: '4.5px 0px', width: '50px' }} /> : <img src={Reports} style={{ width: '50px' }} />}
							<ListItemText className={classes.customItemText} primary={'Reportes'} />
						</Link>
					</ListItem>
					<ListItem className={`${classes.customLink} ${selectedOption.item === 5 && { color: '#F28F16' }}`}>
						<Link to={'/'} className={`${classes.link} ${selectedOption.item === 5 && classes.itemSelected}`} onClick={() => handleSelect(5)}>
							{selectedOption.item === 5 ? <img src={HelpSelected} style={{ width: '50px', height: '45px' }} /> : <img src={Help} style={{ width: '50px', height: '45px' }} />}
							<ListItemText className={classes.customItemText} primary={'Ayuda'} />
						</Link>
					</ListItem>
					<ListItem onClick={handleLogout} className={classes.customLink}>
						<img src={Session} />
					</ListItem>
				</List>
			</Drawer>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				{selectedOption.selected && <div className={classes.drawerHeader} />}
				<Box className={`${classes.contentStyle}`}>{content}</Box>
			</main>
		</div>
	)
}
