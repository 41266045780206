import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import AppBarDesktop from './PublicAppBarDesktop'
import AppBarMobile from './PublicAppBarMobile'

const Header = props => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

	return isMobile ? <AppBarMobile /> : <AppBarDesktop />
}

export default Header
