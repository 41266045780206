import {
	Box,
	CircularProgress,
	createStyles,
	makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() =>
	createStyles({
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
	})
)

const Loading = () => {
	const classes = useStyles()

  return (
    <Box className={classes.boxProgress}>
      <CircularProgress size={40} className={classes.customProgress} />
    </Box>
  )
}

export default Loading
