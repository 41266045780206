import { FC, useState, useEffect } from 'react'
import { Box, Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import Manually from '../EditRoute/Passengers/Manually'
import { getCounties } from '../../providers/Public/Provider'
import CloseIcon from '@material-ui/icons/Close'
import { Theme } from '@material-ui/core/styles'
import { putAddCollaborator } from '../../providers/Private/Provider'
import { useNotification } from '../../hooks/useNotification'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customLabel: {
			marginBottom: '5px',
		},
		passengersLabel: {
			'color': '#007AFF',
			'fontSize': '20px',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		buttonActionSave: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'opacity': 1,
			'minWidth': 240,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem 0 1.5rem 0',
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
			opacity: 0.8,
		},
	})
)

const AddPassengerDialog: FC<AddPassengerDialogProps> = ({ routeId, handleDialog, open, setPassenger, passenger, setCollaborators, collaborators }) => {
	const classes = useStyles()
	const [counties, setCounties] = useState([])
	const [sendingData, setSendingData] = useState(false)
	const [handleNotification] = useNotification()
	const addPassenger = e => {
		e.preventDefault()
		setSendingData(true)
		let setCollaborator = { visits: [passenger] }
		putAddCollaborator(routeId, setCollaborator)
			.then(response => {
				if (response && response.success) {
					handleDialog()
					setCollaborators([...collaborators, passenger])
					handleNotification('Pasajero agregado con éxito', 'success')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.log(err)
				handleNotification('Ha ocurrido un error al agregar pasajero', 'error')
			})
	}

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg">
			<form onSubmit={addPassenger} autoComplete="off">
				<DialogTitle>
					<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>{'Agregar Pasajero'}</h1>
					<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Manually passenger={passenger} setPassenger={setPassenger} />
				</DialogContent>
				<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
					<Button classes={{ root: classes.buttonActionSave }} type="submit" disabled={sendingData} color="primary" variant="contained">
						{'Guardar Cambios'}
						{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default AddPassengerDialog
