import { FC, useState } from 'react'
import { createStyles, makeStyles, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Theme } from '@material-ui/core/styles'
import { deleteShift } from '../../providers/Private/Provider'
import { useNotification } from '../../hooks/useNotification'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		buttonActionSave: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 240,
			'opacity': 1,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		custDialogContent: {
			padding: '1.5rem 24px',
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem',
		},
		customFormLabel: {
			'marginTop': '1rem',
			'& .MuiFormControlLabel-label': {
				color: '#000000',
				fontFamily: 'Lato-Regular',
			},
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
			opacity: 0.8,
		},
	})
)

export const RemoveShiftDialog = ({ handleDialog, open, setShifts, shifts, shift }) => {
	const [removed, setRemoved] = useState(false)
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [handleNotification] = useNotification()

	const removePassenger = (shiftId: string) => {
		setSendingData(true)
		deleteShift(shiftId, { enabled: false })
			.then(response => {
				if (response && response.success) {
					let filterShifts = shifts.filter(shift => shift.id !== shiftId)
					setShifts(filterShifts)
					setRemoved(false)
					handleNotification('Ruta eliminada con éxito', 'success')
					handleDialog()
				}
			})
			.catch(err => {
				console.log(err)
				handleNotification('Ha ocurrido un error con la eliminación', 'error')
			})
	}

	const cancelAction = () => {
		handleDialog()
	}

	const handleRemovedChange = () => {
		setRemoved(!removed)
	}

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg">
			<DialogTitle>
				<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>{'Eliminar Ruta'}</h1>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent classes={{ root: classes.custDialogContent }}>
				<p className={'ct-black ff-LatoRegular fs16 m-0'}>
					¿Estás seguro que quieres eliminar la ruta <span className="fw-bold">{`${shift?.shiftName}`}</span>?
				</p>
				<FormControlLabel
					classes={{ root: classes.customFormLabel }}
					control={<Checkbox checked={removed} onChange={handleRemovedChange} name="checkedB" color="primary" />}
					label="Sí, eliminar"
				/>
			</DialogContent>
			<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
				<Button classes={{ root: classes.buttonActionSave }} color="primary" disabled={!removed} onClick={() => removePassenger(shift.id)} variant="contained">
					{'Eliminar'}
					{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
