import { FC, useState, useEffect, Fragment } from 'react'
import { Box, CircularProgress, Collapse, Grid, Paper, Table, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab } from '@material-ui/core'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Redirect, Link } from 'react-router-dom'

import ExpandMore from '@material-ui/icons/ExpandMore'
import SendIcon from '@material-ui/icons/Send'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import ArrowLoader from '../assets/img/icons/arrowLoader.png'

import { getShift, putShiftNormalize, putShiftOptimize } from '../providers/Private/Provider'
import { useNotification } from '../hooks/useNotification'
import { PopoverDetail } from '../components/utils/PopoverDetails'
import { useMediaQuery } from 'react-responsive'

const { format } = require('date-fns')
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
		},
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)
const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		body: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
		},
	})
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'fontFamily': 'Lato-Regular',
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
				textAlign: 'center',
			},
		},
	})
)(TableRow)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const useStyles = makeStyles(() =>
	createStyles({
		'pageTitle': {
			color: '#113047',
			fontFamily: 'Lato-Regular',
			fontSize: 22,
			margin: '0',
		},
		'boxBodyResume': {
			minHeight: 'calc(100vh - 155px)',
			padding: '24px 0',
			paddingTop: '4rem',
		},
		'boxProgress': {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		'customProgress': {
			color: '#EF9A1B',
		},
		'boxHeaderRoute': {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		'buttonSee': {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		'buttonValidate': {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'buttonBack': {
			'backgroundColor': '#FFFFFF',
			'borderRadius': 40,
			'border': '1px solid #7E7E7E',
			'color': '#7E7E7E',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				color: '#7E7E7E',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		'boxLoading': {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 323px)',
			justifyContent: 'center',
			color: '#EF9A1B',
		},
		'buttonProgress': {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		'customTabsIndicator': {
			display: 'none',
		},
		'customTab': {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		'activeTab': {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		'custCell': {
			padding: '0 !important',
		},
		'animatedItem': {
			animation: `$spin 2s infinite linear`,
		},
		'@global': {
			'@keyframes spin': {
				'0%': {
					transform: 'rotate(0deg)',
				},
				'100%': {
					transform: 'rotate(360deg)',
				},
			},
		},
	})
)
const ResumeRoutePage: FC = (props: any) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const [openPassengers, setOpenPassengers] = useState()
	const id = props.match.params.id
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [loader, setLoader] = useState(true)
	const [normalized, setNormalized] = useState(false)
	const [wrongAddress, setWrongAddress] = useState({ status: false, data: null })
	const [wrongMaxTime, setWrongMaxTime] = useState({ status: false, data: null })
	const [sendingDataMessage, setSendingDataMessage] = useState('Validando Datos...')
	const [shift, setShift] = useState<any>()
	const [handleNotification] = useNotification()
	const [valueTab, setValueTab] = useState(0)
	const [entryData, setEntryData] = useState([])
	const [exitData, setExitData] = useState([])

	useEffect(() => {
		getShiftById()
	}, [])

	useEffect(() => {
		if (entryData.length > 0 || exitData.length > 0) {
			setValueTab(entryData.length > 0 ? 0 : 1)
		}
	}, [entryData, exitData])

	const handleClick = (value: any) => {
		setOpenPassengers(openPassengers === value ? false : value)
	}

	const getShiftById = () => {
		getShift(id)
			.then((response: any) => {
				if (response && response.success) {
					const { routes } = response.data
					routes
						.sort((a, b) => new Date(`2020-01-01T${a.entryTime}`).getTime() - new Date(`2020-01-01T${b.entryTime}`).getTime())
						.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
					formatterData(response.data)
					let entry = routes.filter(item => item.serviceType === 0)
					let exit = routes.filter(item => item.serviceType === 1)
					setEntryData(entry)
					setExitData(exit)
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error', 'error')
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy').toString()
	}

	const handleNormalizeShift = () => {
		setSendingData(true)
		putShiftNormalize(id)
			.then((response: any) => {
				if (response && response.success) {
					setSendingDataMessage('Optimizando Rutas...')
					putShiftOptimize(id)
						.then((response: any) => {
							if (response) {
								const { success } = response
								if (success) {
									setNormalized(true)
								} else {
									setWrongMaxTime({ status: true, data: response.message })
								}
							} else {
								handleNotification('Ha ocurrido un error con la optimización', 'error')
							}
						})
						.catch(err => {
							console.error(err)
						})
				} else {
					setWrongAddress({ status: true, data: response.data })
				}
			})
			.catch(error => {
				setSendingData(false)
				console.log('error', error)
				handleNotification('Ha ocurrido un error con la normalización', 'error')
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const handleServiceTipe = (serviceType, entryTime?, exitTime?) => {
		switch (serviceType) {
			case 0:
				return entryTime.slice(0, -3)
			case 1:
				return exitTime?.slice(0, -3) || entryTime.slice(0, -3)
			case 2:
				return `${entryTime.slice(0, -3)} - ${exitTime.slice(0, -3)}`
			default:
				return 'No Aplica'
		}
	}

	const handleServiceTitle = value => {
		switch (value) {
			case 0:
				return 'INGRESO'
			case 1:
				return 'SALIDA'
			case 2:
				return 'INGRESO - SALIDA'
			default:
				return 'No Aplica'
		}
	}

	const formatterData = responseData => {
		const newStartDate = formatDate(new Date(`${responseData.routes[0]['startDate']}T${responseData.routes[0]['entryTime'] || '00:00:00'}`))
		let newShiftData: any

		newShiftData = {
			name: responseData.name,
			startDate: newStartDate,
			data: responseData.routes,
			store: responseData.store.name,
			costCenter: responseData.costCenter.name,
		}
		setShift(newShiftData)
	}

	const handleTabChange = (event, newValue) => {
		if (entryData.length > 0 && exitData.length > 0) {
			setValueTab(newValue)
		} else if (exitData.length > 0) {
			setValueTab(1)
		} else if (entryData.length > 0) {
			setValueTab(0)
		}
	}

	const parseGoogleAddress = (value: string) => {
		let address = value.split(',')
		return address[0]
	}

	if (normalized) {
		return <Redirect push to={{ pathname: `/new-route/${id}/optimized-route` }} />
	}

	if (wrongAddress.status) {
		return <Redirect push to={{ pathname: `/new-route/${id}/wrong-address`, state: wrongAddress }} />
	}

	if (wrongMaxTime.status) {
		return <Redirect push to={{ pathname: `/new-route/${id}/wrong-max-time`, state: wrongMaxTime }} />
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}

	return shift?.name !== '' ? (
		shift.data?.length > 0 ? (
			<Box className={classes.boxBodyResume}>
				<h1 className={classes.pageTitle}>{'Resumen de Turno'}</h1>
				<Grid container alignItems="center">
					<Grid item xs={12} md={6}>
						<Box className={classes.boxHeaderRoute}>
							<Grid container>
								<Grid container style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '1% 0%' }}>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Ruta'}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sucursal'}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Centro de Costo'}</p>
									</Grid>
								</Grid>
								<Grid container style={{ paddingBottom: '1%' }}>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.name}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.store}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.costCenter}</p>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
				{!sendingData ? (
					<Fragment>
						<Tabs
							variant="fullWidth"
							value={valueTab}
							onChange={handleTabChange}
							classes={{
								indicator: classes.customTabsIndicator,
							}}
							className={'mt-2r'}
						>
							{entryData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 0 ? classes.activeTab : ''} label="Ingreso" />}
							{exitData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 1 ? classes.activeTab : ''} label="Regreso" />}
						</Tabs>
						<Grid container>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<StyledTableCell>{'Fecha'}</StyledTableCell>
											<StyledTableCell align="right">{'Tipo'}</StyledTableCell>
											<StyledTableCell align="right">{'Hora'}</StyledTableCell>
											<StyledTableCell align="right">{'Pasajeros'}</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{shift.data.map(
											(travel: any, index: number) =>
												travel.serviceType === valueTab && (
													<Fragment key={index}>
														<StyledTableRow>
															<StyledTableCell component="th" scope="row" align="center">
																{formatDate(new Date(`${travel.startDate}T${travel.entryTime}`))}
															</StyledTableCell>
															<StyledTableCell align="center">{handleServiceTitle(travel.serviceType)}</StyledTableCell>
															<StyledTableCell align="center">{handleServiceTipe(travel.serviceType, travel.entryTime, travel.exitTime)}</StyledTableCell>
															<StyledTableCell align="center">
																{travel.visits.length > 0 ? (
																	<Button
																		classes={{
																			root: classes.buttonSee,
																		}}
																		color="primary"
																		onClick={() => handleClick(index)}
																		endIcon={<ExpandMore />}
																	>
																		{'Ver Pasajeros'}
																	</Button>
																) : (
																	'Sin pasajeros'
																)}
															</StyledTableCell>
														</StyledTableRow>
														<StyledTableRow>
															<StyledTableCellHeader component="td" scope="row" align="center" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
																<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
																	<Box margin={1}>
																		<Grid container justify="center">
																			<Grid item xs={12} md={2}>
																				{'Nombre del pasajero'}
																			</Grid>
																			<Grid item xs={12} md={2}>
																				{'Dirección'}
																			</Grid>
																			<Grid item xs={12} md={2}>
																				{'Correo'}
																			</Grid>
																			<Grid item xs={12} md={2}>
																				{'Teléfono'}
																			</Grid>
																			<Grid item xs={12} md={2}>
																				{'Comentarios'}
																			</Grid>
																		</Grid>
																	</Box>
																</Collapse>
															</StyledTableCellHeader>
														</StyledTableRow>
														<StyledTableRowBody>
															<StyledTableCell className={classes.custCell} colSpan={8}>
																<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
																	{travel.visits.length > 0 &&
																		travel.visits.map((passenger, indexPassengers) => (
																			<Box margin={1}>
																				<Grid container justify="center" key={indexPassengers}>
																					<Grid item xs={12} md={2}>
																						{passenger?.address.collaborator.fullName && passenger.address.collaborator.fullName}
																					</Grid>
																					<Grid item xs={12} md={2}>
																						{passenger.address.googleAddress !== null
																							? parseGoogleAddress(passenger.address.googleAddress)
																							: `${passenger.address?.street && passenger.address.street}, ${passenger.address.county?.name ? passenger.address.county.name : ''}`}
																					</Grid>
																					<Grid item xs={12} md={2}>
																						{passenger.address.collaborator.email}
																					</Grid>
																					<Grid item xs={12} md={2}>
																						{passenger?.address.collaborator.phoneNumber && passenger.address.collaborator.phoneNumber}
																					</Grid>
																					<Grid item xs={12} md={2}>
																						{passenger.address.comment ? (
																							passenger.address.comment?.length > 1 ? (
																								<PopoverDetail comment={passenger.address.comment} index={indexPassengers} />
																							) : (
																								'Sin comentarios'
																							)
																						) : (
																							'Sin comentarios'
																						)}
																					</Grid>
																				</Grid>
																			</Box>
																		))}
																</Collapse>
															</StyledTableCell>
														</StyledTableRowBody>
													</Fragment>
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Fragment>
				) : (
					<Box className={classes.boxLoading}>
						<img src={ArrowLoader} className={classes.animatedItem} />
						<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'} style={{ color: '#EF9A1B' }}>
							{sendingDataMessage}
						</p>
					</Box>
				)}
				<Grid container alignItems="center" justify={isMobile ? 'center' : 'space-between'} className={'mt-2r'} style={{ paddingTop: '1rem' }}>
					{!sendingData && (
						<Grid item xs={12} md={4} className={isMobile ? 'mb-1-5r' : ''}>
							<Button className={classes.buttonBack} color="primary" component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
								{'Volver'}
							</Button>
						</Grid>
					)}
					<Grid item xs={12} md={4}>
						{!sendingData && (
							<Button className={classes.buttonValidate} color="primary" fullWidth endIcon={<SendIcon />} disabled={sendingData} onClick={event => handleNormalizeShift()}>
								{'Optimizar'}
								{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Button>
						)}
					</Grid>
				</Grid>
			</Box>
		) : (
			<Box className={classes.boxProgress}>
				<Grid container>
					<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
						<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
					</Grid>
				</Grid>
			</Box>
		)
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
				</Grid>
			</Grid>
		</Box>
	)
}

export default ResumeRoutePage
