import { useState } from 'react'
import { Button, createStyles, Grid, InputAdornment, makeStyles, TextField, ThemeProvider } from '@material-ui/core'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { useNotification } from '../../hooks/useNotification'
import MainColorTheme from '../utils/MainColorTheme'
import { useMediaQuery } from 'react-responsive'

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { addCompanySchema } from '../../validations/addCompanySchema'
import { postCompany, putCompany } from '../../providers/Private/Provider'
const { validate, format } = require('rut.js')

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		actionButton: {
			background: '#0D1373',
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			height: 49,
			width: 240,
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			margin: 0,
			position: 'absolute',
		},
		spanPhone: {
			fontFamily: 'Lato-Regular',
			marginLeft: 20,
		},
	})
)

export const AddCompany = ({ setComponentValue, handleSetSelected, companyEdit }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const [rutValid, setRutValid] = useState(true)
	const classes = useStyles()
	const [handleNotification] = useNotification()

	const checkRut = (rut: any) => {
		validate(rut) ? setRutValid(true) : setRutValid(false)
	}

	const handleSubmit = (values: any) => {
		postCompany(values)
			.then(response => {
				if (response && response.success) {
					handleNotification('Compañía creada con éxito', 'success')
					setComponentValue(2)
					handleSetSelected('listCompanies')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(() => {
				handleNotification('Error al crear la compañía', 'error')
			})
	}

	const handleUpdate = (values: any) => {
		putCompany(companyEdit.id, values)
			.then(response => {
				if (response && response.success) {
					handleNotification('Compañía editada con éxito', 'success')
					setComponentValue(2)
					handleSetSelected('listCompanies')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(() => {
				handleNotification('Error al editar la compañía', 'error')
			})
	}

	const formik = useFormik({
		initialValues: {
			name: companyEdit ? companyEdit.name : '',
			rut: companyEdit ? companyEdit.rut : '',
			phoneNumber: companyEdit ? companyEdit.phoneNumber : '',
			taxicallerId: companyEdit ? companyEdit.taxicallerId : '',
		},
		validationSchema: addCompanySchema,
		onSubmit: values => {
			companyEdit ? handleUpdate(values) : handleSubmit(values)
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit} autoComplete="off">
				<Grid container justify="center">
					<Grid item xs={12} md={12}>
						<h2 className={'ct-black ff-LatoRegular fs20'}>{'Nueva Compañía'}</h2>
					</Grid>
					<Grid container spacing={2} className={classes.containerInput}>
						<Grid item xs={12} md={12}>
							<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Nombre'}</p>
							<TextField
								className={classes.customTextField}
								id="name"
								name="name"
								variant="outlined"
								onChange={formik.handleChange}
								value={formik.values.name}
								fullWidth
								helperText={<ErrorMessage name="name" render={msg => <p className={classes.error}>{msg}</p>} />}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<AccountCircleIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Rut</p>
							<TextField
								className={classes.customTextField}
								fullWidth
								helperText={<ErrorMessage name="rut" render={msg => <p className={classes.error}>{msg}</p>} />}
								id="rut"
								name={'rut'}
								onBlur={event => checkRut(formik.values.rut)}
								onChange={event => formik.setFieldValue('rut', format(event.target.value))}
								value={format(formik.values.rut)}
								variant="outlined"
								inputProps={{
									maxLength: 12,
									startAdornment: (
										<InputAdornment position="start">
											<AssignmentIndIcon />
										</InputAdornment>
									),
								}}
							/>
							{!rutValid && <p className={`${classes.error} m-0`}>{'Debes ingresar un RUT valido'}</p>}
						</Grid>
						<Grid item xs={12} md={12}>
							<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Teléfono</p>
							<TextField
								className={classes.customTextField}
								id="phoneNumber"
								name={'phoneNumber'}
								variant="outlined"
								onChange={formik.handleChange}
								value={formik.values.phoneNumber}
								fullWidth
								helperText={<ErrorMessage name="phoneNumber" render={msg => <p className={classes.error}>{msg}</p>} />}
								inputProps={{ maxLength: 9 }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PhoneIphoneIcon />
											<span className={classes.spanPhone}>+56</span>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>ID Taxicaller</p>
							<TextField
								className={classes.customTextField}
								id="taxicallerId"
								name={'taxicallerId'}
								variant="outlined"
								onChange={formik.handleChange}
								value={formik.values.taxicallerId}
								fullWidth
								helperText={<ErrorMessage name="taxicallerId" render={msg => <p className={classes.error}>{msg}</p>} />}
							/>
						</Grid>
						<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
							<Button className={`${classes.actionButton} ${isMobile && 'w-100'}`} type="submit" variant={'contained'}>
								{companyEdit?.id ? 'Editar' : 'Agregar'}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormikProvider>
	)
}
