import { FC, useState, useEffect } from 'react'
import { Box, CircularProgress, createStyles, Divider, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PhoneIcon from '@material-ui/icons/Phone'
import HomeIcon from '@material-ui/icons/Home'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import NoteIcon from '@material-ui/icons/Note'

//providers
import { getCounties } from '../../../providers/Public/Provider'

import validator from 'validator'

// const { validate, format } = require('rut.js')
const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		passengersLabel: {
			'color': 'rgba(0, 122, 255, 0.7)',
			'fontFamily': 'Lato-Bold',
			'fontSize': '20px',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			letterSpacing: '0.00938em',
			lineHeight: 1.5,
			position: 'absolute',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: '381.19px',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
	})
)

const initPassengers = {
	fullName: '',
	passengerCode: '',
	address: {
		street: '',
		countyId: '',
	},
	phoneNumber: '',
	email: '',
	isValid: true,
	ccCompany: '',
	comment: '',
}
const initValidation = {
	passengerName: false,
	passengerCounty: false,
	passengerStreet: false,
	passengerPhone: false,
	passengerMail: false,
	passengerRut: false,
}

const Manually: FC<PassengersManuallyProps> = ({ passengersTotal, setPassengersTotal, setValidatePassengers, preSubmitted, setPreSubmitted, validatePassengers }) => {
	const [counter, setCounter] = useState(passengersTotal.length)
	const classes = useStyles()
	const [counties, setCounties] = useState([])
	const [verificationField, setVerificationFields] = useState<PassengerValidate[]>([initValidation])

	useEffect(() => {
		getCounties().then(response => {
			response !== undefined &&
				setCounties(
					response.sort((a, b) => {
						if (a.name > b.name) {
							return 1
						}
						if (a.name < b.name) {
							return -1
						}
						return 0
					})
				)
		})
	}, [])

	const handleChangePasseger = (value: string, indexPassenger: number, field: string) => {
		let clone = JSON.parse(JSON.stringify(passengersTotal))
		let cloneValidation = JSON.parse(JSON.stringify(verificationField))

		clone.map(passenger => {
			if (field === 'email') {
				cloneValidation.map(passenger => {
					if (validator.isEmail(value)) {
						cloneValidation[indexPassenger]['passengerMail'] = false
					} else {
						cloneValidation[indexPassenger]['passengerMail'] = true
					}
				})
			}
			clone[indexPassenger][field] = value
		})
		setVerificationFields(cloneValidation)
		setPassengersTotal(clone)
	}

	const handleAddressChangePassenger = (value: any, indexPassenger: number, field: string) => {
		let clone = JSON.parse(JSON.stringify(passengersTotal))

		clone.map(passenger => {
			clone[indexPassenger]['address'][field] = value
		})
		setPassengersTotal(clone)
	}

	const handleCountyChange = (event: any, indexPassenger: number, field: string) => {
		const { value } = event.target
		let clone = JSON.parse(JSON.stringify(passengersTotal))
		const filterCounties = counties.filter(({ name }) => value === name)

		clone.map(passenger => {
			if (filterCounties.length > 0) {
				clone[indexPassenger]['address'][field] = filterCounties[0]['id']
			}
		})
		setPassengersTotal(clone)
	}

	const addPassenger = () => {
		setCounter(counter + 1)
		passengersTotal.push(initPassengers)
		verificationField.push(initValidation)
		setPassengersTotal(passengersTotal)
		setVerificationFields(verificationField)
	}
	const removePassenger = (index: number) => {
		setCounter(counter - 1)
		const newArray = passengersTotal
		newArray.splice(index, 1)
		const newArrayValidator = verificationField
		newArrayValidator.splice(index, 1)
		setPassengersTotal(newArray)
		setVerificationFields(newArrayValidator)
	}

	const handleValidationPassenger = (validatorField: string, index: number, value: string | null) => {
		let clone = JSON.parse(JSON.stringify(verificationField))
		if (value === null || value === undefined || value.length === 0) {
			clone.map(passenger => {
				clone[index][validatorField] = true
			})
		} else {
			clone.map(passenger => {
				clone[index][validatorField] = false
			})
		}
		setVerificationFields(clone)
	}

	return (
		<>
			{passengersTotal?.length > 0
				? passengersTotal.map((item, index) => (
						<Grid item xs={12} md={12} key={index}>
							{counties.length > 0 ? (
								<Grid container>
									<Grid item xs={12} md={12}>
										<h2 className={'ct-black ff-LatoRegular fs20'}>{'Pasajero ' + (index + 1)}</h2>
									</Grid>
									<Grid container spacing={2} className={classes.containerInput}>
										<Grid item xs={12} md={6}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Nombre y Apellido</p>
											<TextField
												className={classes.customTextField}
												id="passengerName"
												name="passengerName"
												variant="outlined"
												required={true}
												onChange={event => handleChangePasseger(event.target.value, index, 'fullName')}
												value={passengersTotal[index].fullName}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<AccountCircleIcon />
														</InputAdornment>
													),
												}}
												onBlur={() => {
													handleValidationPassenger('passengerName', index, passengersTotal[index].fullName)
												}}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Codigo Pasajero'}</p>
											<TextField
												className={classes.customTextField}
												id="passengerCode"
												variant="outlined"
												required={true}
												onChange={event => handleChangePasseger(event.target.value, index, 'passengerCode')}
												value={passengersTotal[index].passengerCode}
												fullWidth
												inputProps={{
													maxLength: 20,
													startAdornment: (
														<InputAdornment position="start">
															<AssignmentIndIcon />
														</InputAdornment>
													),
												}}
											/>
											{!passengersTotal[index].isValid && <p className={classes.error + ' ' + 'm-0'}>{'Debes ingresar un RUT valido'}</p>}
										</Grid>
									</Grid>
									<Grid container spacing={2} className={classes.containerInput}>
										<Grid item xs={12} md={4}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Comuna</p>
											<Autocomplete
												classes={{ option: classes.customMenuItem }}
												getOptionLabel={option => option['name']}
												id="passengerCounty"
												options={counties}
												noOptionsText={'Sin coincidencias'}
												renderInput={params => (
													<TextField
														{...params}
														name="passengerCounty"
														classes={{
															root: classes.customAutocompleteField,
														}}
														required={true}
														variant="outlined"
														onSelect={event => handleCountyChange(event, index, 'countyId')}
														onBlur={() => {
															handleValidationPassenger('passengerCounty', index, passengersTotal[index]['address']['countyId'])
														}}
														value={passengersTotal[index].address.countyId}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12} md={8}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Calle</p>
											<TextField
												className={classes.customTextField}
												id="passengerStreet"
												name="passengerStreet"
												variant="outlined"
												required={true}
												onChange={event => handleAddressChangePassenger(event.target.value, index, 'street')}
												value={passengersTotal[index].address.street}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<HomeIcon />
														</InputAdornment>
													),
												}}
												onBlur={() => {
													handleValidationPassenger('passengerStreet', index, passengersTotal[index].address.street)
												}}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2} className={classes.containerInput}>
										<Grid item xs={12} md={6}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Celular</p>
											<TextField
												className={classes.customTextField}
												id="passengerPhone"
												name="passengerPhone"
												variant="outlined"
												required={true}
												onChange={event => handleChangePasseger(event.target.value, index, 'phoneNumber')}
												value={passengersTotal[index].phoneNumber}
												fullWidth
												inputProps={{ maxLength: 9 }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<PhoneIcon />
															<span style={{ marginLeft: '20px' }}>+56</span>
														</InputAdornment>
													),
												}}
												onBlur={() => {
													handleValidationPassenger('passengerPhone', index, passengersTotal[index].phoneNumber)
												}}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>E-mail</p>
											<TextField
												className={classes.customTextField}
												id="passengerMail"
												name="passengerMail"
												variant="outlined"
												required={true}
												onChange={event => handleChangePasseger(event.target.value, index, 'email')}
												value={passengersTotal[index].email}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<AlternateEmailIcon />
														</InputAdornment>
													),
												}}
												onBlur={() => {
													handleValidationPassenger('passengerMail', index, passengersTotal[index].email)
												}}
												helperText={verificationField[index]?.passengerMail && <Typography className={classes.error}>{'Debe ingresar un correo al pasajero'}</Typography>}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2} className={classes.containerInput}>
										<Grid item xs={12} md={12}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Centro de Costo Compañia</p>
											<TextField
												className={classes.customTextField}
												id="passengerccCompany"
												name="passengerccCompany"
												variant="outlined"
												onChange={event => handleChangePasseger(event.target.value, index, 'ccCompany')}
												value={passengersTotal[index].ccCompany}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															 <NoteIcon />{/*//Icono */}
														</InputAdornment>
													),
												}}
												onBlur={() => {
													handleValidationPassenger('passengerPhone', index, passengersTotal[index].ccCompany)
												}}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2} className={classes.containerInput}>
										<Grid item xs={12} md={12}>
											<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Comentarios</p>
											<TextField
												className={classes.customTextField}
												id="passengerComments"
												name="passengerComments"
												variant="outlined"
												onChange={event => handleChangePasseger(event.target.value, index, 'comment')}
												value={passengersTotal[index].comment}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<NoteIcon />
														</InputAdornment>
													),
												}}
												onBlur={() => {
													handleValidationPassenger('passengerPhone', index, passengersTotal[index].comment)
												}}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2} className={classes.containerInput}>
										<Grid item xs={12} md={6}>
											{index + 1 === passengersTotal.length && (
												<Typography align="left" className={classes.passengersLabel} onClick={() => addPassenger()}>
													+ Agregar pasajero
												</Typography>
											)}
										</Grid>
										<Grid item xs={12} md={6}>
											{index !== 0 && (
												<Typography align="right" className={classes.passengersLabel} onClick={() => removePassenger(index)}>
													+ Quitar pasajero
												</Typography>
											)}
										</Grid>
									</Grid>
								</Grid>
							) : (
								<Box className={classes.boxProgress}>
									<CircularProgress size={34} className={classes.customProgress} />
								</Box>
							)}
							<Divider variant="middle" />
						</Grid>
				  ))
				: addPassenger()}
		</>
	)
}

export default Manually
