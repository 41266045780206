import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AppBarDesktop } from './AppBarDesktop'
import AppBarMobile from './AppBarMobile'

const Header = ({ content }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

	return isMobile ? <AppBarMobile content={content} /> : <AppBarDesktop content={content} />
}

export default Header
