import { FC, useState, useEffect, Fragment } from 'react'
import { Box, Button, CircularProgress, Collapse, Divider, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SendIcon from '@material-ui/icons/Send'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import { getShiftsOptimized, putApproveShift } from '../providers/Private/Provider'
import { Link, Redirect } from 'react-router-dom'
import { useNotification } from '../hooks/useNotification'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		pageTitle: {
			color: '#113047',
			fontFamily: 'Lato-Regular',
			fontSize: 20,
			margin: '0',
			paddingTop: '3rem',
		},
		boxBodyResume: {
			minHeight: 'calc(100vh - 155px)',
			paddingBottom: '5%',
			paddingTop: '4rem',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxHeaderRoute: {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		buttonValidate: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'fontFamily': 'Lato-Regular',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 323px)',
			justifyContent: 'center',
		},
		customTabsIndicator: {
			display: 'none',
		},
		customTab: {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		activeTab: {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		custCell: {
			padding: '0 !important',
		},
		infoText: {
			fontFamily: 'Lato-Regular',
			fontSize: '16px',
			margin: 0,
			padding: '1rem',
		},
	})
)

const OptimizedRoutePage: FC = (props: any) => {
	const [openPassengers, setOpenPassengers] = useState()
	const [sendingData, setSendingData] = useState(false)
	const id = props.match.params.id
	const classes = useStyles()
	const [loader, setLoader] = useState(true)
	const [getShiftOptimizedData, setShiftOptimizedData] = useState<any>([])
	const [redirect, setRedirect] = useState(false)
	const [handleNotification] = useNotification()
	const [valueTab, setValueTab] = useState(0)
	const [entryData, setEntryData] = useState([])
	const [exitData, setExitData] = useState([])

	useEffect(() => {
		getShiftOptimizedById()
	}, [])

	useEffect(() => {
		if (entryData.length > 0 || exitData.length > 0) {
			setValueTab(entryData.length > 0 ? 0 : 1)
		}
	}, [entryData, exitData])

	const handleClick = (value: any) => {
		setOpenPassengers(openPassengers === value ? false : value)
	}

	const getShiftOptimizedById = () => {
		getShiftsOptimized(id)
			.then((response: any) => {
				if (response && response.success) {
					const { data } = response
					data.data.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
					let entry = data.data.filter(item => item.serviceType === 0)
					let exit = data.data.filter(item => item.serviceType === 1)
					setEntryData(entry)
					setExitData(exit)
					setShiftOptimizedData(data)
				} else {
					setShiftOptimizedData([])
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error', 'error')
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const handleServiceTitle = value => {
		switch (value) {
			case 0:
				return 'INGRESO'
			case 1:
				return 'SALIDA'
			case 2:
				return 'INGRESO - SALIDA'
			default:
				return 'No Aplica'
		}
	}

	const handleApprove = () => {
		setSendingData(true)
		putApproveShift(id).then(response => {
			if (response && response.success) {
				handleNotification('Ruta aprobada con éxito', 'success')
				MySwal.fire({
					title: <p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>Turno Aprobado</p>,
					html: <p className={'ct-light-dark-gray ff-LatoRegular fs15 m-0'}>Su turno ha sido aprobado, ¡Gracias!</p>,
					icon: 'success',
				})
				setRedirect(true)
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
	}

	const handleTabChange = (event, newValue) => {
		if (entryData.length > 0 && exitData.length > 0) {
			setValueTab(newValue)
		} else if (exitData.length > 0) {
			setValueTab(1)
		} else if (entryData.length > 0) {
			setValueTab(0)
		}
	}

	const collaboratorsList = (nodes: any) => {
		let arr: any = []
		for (let [key, value] of Object.entries(nodes)) {
			arr.push(value)
		}
		return arr
	}

	const getPassengersQuantity = (tours: any) => {
		let passengersQuantity: any = []

		for (let index = 0; index < tours.length; index++) {
			let list = collaboratorsList(tours[index]['nodes'])
			Array.prototype.push.apply(passengersQuantity, list)
		}

		return passengersQuantity.length
	}

	if (redirect) {
		return <Redirect push to={{ pathname: `/my-routes` }} />
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}

	return getShiftOptimizedData.data?.length > 0 ? (
		<Box className={classes.boxBodyResume}>
			<h1 className={classes.pageTitle}>{'Ruta Optimizada'}</h1>
			<Grid container alignItems="center">
				<Grid item xs={12} md={6}>
					<Box className={classes.boxHeaderRoute}>
						<Grid container>
							<Grid container style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '1% 0%' }}>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Ruta'}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sucursal'}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Centro de Costo'}</p>
								</Grid>
							</Grid>
							<Grid container style={{ paddingBottom: '1%' }}>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{getShiftOptimizedData.name}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{getShiftOptimizedData.store.name}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{getShiftOptimizedData.costCenter.name}</p>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			{!sendingData ? (
				<Fragment>
					<Tabs
						variant="fullWidth"
						value={valueTab}
						onChange={handleTabChange}
						classes={{
							indicator: classes.customTabsIndicator,
						}}
						className={'mt-2r'}
					>
						{entryData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 0 ? classes.activeTab : ''} label="Ingreso" />}
						{exitData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 1 ? classes.activeTab : ''} label="Regreso" />}
					</Tabs>
					<Grid container>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<StyledTableCellHead>{'Fecha'}</StyledTableCellHead>
										<StyledTableCellHead>{'Tipo'}</StyledTableCellHead>
										<StyledTableCellHead>{'Hora'}</StyledTableCellHead>
										<StyledTableCellHead>{'Pasajeros'}</StyledTableCellHead>
										<StyledTableCellHead>{'Vehiculos'}</StyledTableCellHead>
										<StyledTableCellHead>{'Distancia'}</StyledTableCellHead>
										<StyledTableCellHead>{'Ver Detalle'}</StyledTableCellHead>
									</TableRow>
								</TableHead>
								<TableBody>
									{getShiftOptimizedData.data.map(
										(travel: any, index: number) =>
											travel.serviceType === valueTab && (
												<Fragment key={index}>
													<StyledTableRowBody>
														<StyledTableCellBody>{travel.startDate}</StyledTableCellBody>
														<StyledTableCellBody>{handleServiceTitle(travel.serviceType)}</StyledTableCellBody>
														<StyledTableCellBody>{travel.entryTime.slice(0, -3)}</StyledTableCellBody>
														<StyledTableCellBody>{travel.tours && travel.tours.length > 0 ? getPassengersQuantity(travel.tours) : '0'}</StyledTableCellBody>
														<StyledTableCellBody>{travel.tours ? travel.tours.length : '0'}</StyledTableCellBody>
														<StyledTableCellBody>{travel.estimatedDistance ? `${travel.estimatedDistance} KM` : 'Sin distancia estimada'}</StyledTableCellBody>
														<StyledTableCellBody>
															{travel.tours.length > 0 ? (
																<Button
																	classes={{
																		root: classes.buttonSee,
																	}}
																	color="primary"
																	onClick={() => {
																		handleClick(index)
																	}}
																	endIcon={<ExpandMore />}
																>
																	{'Ver Pasajeros'}
																</Button>
															) : (
																'Sin pasajeros'
															)}
														</StyledTableCellBody>
													</StyledTableRowBody>
													<StyledTableRowBody>
														<StyledTableCellHead className={classes.custCell} colSpan={7}>
															<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
																{travel.tours.length > 0 &&
																	travel.tours.map((vehicle, indexVehicle) =>
																		Object.keys(vehicle.nodes).length > 0 ? (
																			<Box key={indexVehicle}>
																				<p className={classes.infoText}>
																					{`Vehículo ${indexVehicle + 1}`}
																					<small className={'ml-1r'}>{`(cantidad de pasajeros ${Object.keys(vehicle.nodes).length})`}</small>
																				</p>
																				<p className={classes.infoText}>
																					{`Distancia: ${vehicle.estimatedDistance} KM`}
																				</p>
																				<Table size="small">
																					<TableHead>
																						<TableRow>
																							<StyledTableCellHeader>{'Nombre del pasajero'}</StyledTableCellHeader>
																							<StyledTableCellHeader>{'Dirección'}</StyledTableCellHeader>
																							<StyledTableCellHeader>{'Teléfono'}</StyledTableCellHeader>
																							<StyledTableCellHeader>{'Orden de Recogida'}</StyledTableCellHeader>
																							<StyledTableCellHeader>{'Hora'}</StyledTableCellHeader>
																						</TableRow>
																					</TableHead>
																					<TableBody>
																						{collaboratorsList(vehicle.nodes).map((node, index) => {
																							return (
																								<StyledTableRowBody key={index}>
																									<StyledTableCellBody>{node.collaborator.fullName}</StyledTableCellBody>
																									<StyledTableCellBody>
																										{`${node.street}, ${node.county?.name ? node.county.name : ''}`}
																									</StyledTableCellBody>
																									<StyledTableCellBody>{node.collaborator.phoneNumber}</StyledTableCellBody>
																									<StyledTableCellBody>{index + 1}</StyledTableCellBody>
																									<StyledTableCellBody>
																										{node.arrival.length === 8 ? node.arrival.slice(0, -3) : node.arrival}
																									</StyledTableCellBody>
																								</StyledTableRowBody>
																							)
																						})}
																					</TableBody>
																				</Table>
																			</Box>
																		) : (
																			<>
																				<Box>
																					<p className={classes.infoText}>
																						{`Vehículo ${indexVehicle + 1}`}
																						<small className={'ml-1r'}>{'(no hay pasajeros disponibles)'}</small>
																					</p>
																				</Box>
																				<Divider />
																			</>
																		)
																	)}
															</Collapse>
														</StyledTableCellHead>
													</StyledTableRowBody>
												</Fragment>
											)
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid container alignItems="center" justify="center" className={'mt-2r'} style={{ paddingBottom: '3%', textAlign: 'center', paddingTop: '2rem' }}>
						<Grid item xs={12} md={3}>
							<Button component={Link} to={'/my-routes'} className={classes.buttonValidate} color="primary" fullWidth startIcon={<TransitEnterexitIcon />}>
								{'Mis Rutas'}
							</Button>
						</Grid>
						<Grid item xs={12} md={6}></Grid>
						<Grid item xs={12} md={3}>
							<Button className={classes.buttonValidate} color="primary" fullWidth endIcon={<SendIcon />} onClick={event => handleApprove()}>
								{'Aprobar Ruta'}
							</Button>
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Box className={classes.boxLoading}>
					<CircularProgress size={34} className={classes.customProgress} />
					<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'}>{'Aprobando Rutas...'}</p>
				</Box>
			)}
		</Box>
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
				</Grid>
			</Grid>
		</Box>
	)
}

export default OptimizedRoutePage
