import axios from 'axios'

const client = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
})

export const response = async (options: any) => {
	options.headers = {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${localStorage.getItem('token')}`,
	}

	return await client(options)
		.then(response => {
			if (response.data) {
				const { data } = response
				return data
			} else {
				return response
			}
		})
		.catch(error => {
			console.error('error', error)
		})
}

const clientExcel = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
})

export const responseExcel = async (options: any) => {
	options.headers = {
		'Content-Type': 'application/vnd.ms-excel',
		'Authorization': `Bearer ${localStorage.getItem('token')}`,
	}
	return await clientExcel(options)
		.then(response => {
			if (response.data) {
				const { data } = response
				return data
			} else {
				return response
			}
		})
		.catch(error => {
			console.error('error', error)
		})
}

export const responseCreateExcel = async (options: any, excelName: string) => {
	options.headers = {
		Authorization: `Bearer ${localStorage.getItem('token')}`,
	}
	
	return await clientExcel(options)
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${excelName}.xlsx`) //or any other extension
			document.body.appendChild(link)
			link.click()
		})
		.catch(error => {
			console.error('error', error)
		})
}
