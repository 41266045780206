import { FC, useState, useEffect } from 'react'
import { createStyles, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import { Theme } from '@material-ui/core/styles'
import { getShift, putChangeRouteCollaborator } from '../../providers/Private/Provider'
import { useNotification } from '../../hooks/useNotification'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		customDialog: {
			minWidth: 350,
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		buttonActionSave: {
			'backgroundColor': '#2445A6',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 240,
			'opacity': 1,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#2445A6',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		buttonCancel: {
			'backgroundColor': '#FFFFFF',
			'border': '1px solid #CDCDCD',
			'borderRadius': 40,
			'color': '#7E7E7E',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				color: '#7E7E7E',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		custDialogContent: {
			padding: '1.5rem 24px',
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem',
		},
		customFormLabel: {
			'marginTop': '1rem',
			'& .MuiFormControlLabel-label': {
				color: '#000000',
				fontFamily: 'Lato-Regular',
			},
		},
	})
)

const ChangeRoutePassengerDialog: FC<ChangeRoutePassengerDialogProps> = ({ handleDialog, open, setPassengerData, passengerData, setCollaborators, collaborators, shiftId, serviceType, date }) => {
	const [routes, setRoutes] = useState([])
	const classes = useStyles()
	const [selected, setSelected] = useState<any>()
	const [handleNotification] = useNotification()

	const handleRouteChange = () => {
		const setPostData = {
			newRouteId: selected,
		}
		putChangeRouteCollaborator(passengerData.id, setPostData)
			.then(response => {
				if (response && response.success) {
					handleDialog()
					handleNotification('Se ha modificado la ruta del pasajero', 'success')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.log(err)
				handleNotification('Error en la modificación de ruta', 'error')
			})
	}

	const getRoutes = () => {
		getShift(shiftId)
			.then(response => {
				if (response && response.success) {
					const { routes } = response.data
					setRoutes(routes.filter(route => route.serviceType === serviceType).filter(route => route.startDate === date && route.visits.length > 0))
				} else {
					setRoutes([])
				}
			})
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getRoutes()
	}, [])

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg" classes={{ paper: classes.customDialog }}>
			<DialogTitle>
				<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>{'Cambiar Ruta'}</h1>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent classes={{ root: classes.custDialogContent }}>
				<p className={'ct-black ff-LatoRegular fs16 mb-2r'}>Seleccionar Ruta</p>
				<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Ruta</p>
				<Autocomplete
					classes={{ option: classes.customMenuItem }}
					getOptionLabel={(option: any) => (option ? option['entryTime'] : '')}
					getOptionSelected={option => option['id']}
					id="newRouteCollaborator"
					options={routes}
					noOptionsText={'Sin rutas disponibles'}
					onChange={(event, value: any) => setSelected(value.id)}
					renderInput={params => (
						<TextField
							{...params}
							name="newRouteCollaborator"
							classes={{
								root: classes.customAutocompleteField,
							}}
							required={true}
							variant="outlined"
						/>
					)}
				/>
			</DialogContent>
			<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
				<Button classes={{ root: classes.buttonActionSave }} color="primary" onClick={() => handleRouteChange()} variant="contained" type="submit">
					{'Cambiar Ruta'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ChangeRoutePassengerDialog
