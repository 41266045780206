import { useContext, useEffect, useState } from 'react'
import { createStyles, Divider, Grid, ListItemIcon, makeStyles, MenuItem, MenuList, Paper, Typography } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

import BusinessIcon from '@material-ui/icons/Business'
import QueueIcon from '@material-ui/icons/Queue'
import ListAltIcon from '@material-ui/icons/ListAlt'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import StoreIcon from '@material-ui/icons/Store'

import { AddCompany } from '../components/Administration/AddCompany'
import { AddStore } from '../components/Administration/AddStore'
import { ListStores } from '../components/Administration/ListStores'
import { ListCompanies } from '../components/Administration/ListCompanies'
import { AddUser } from '../components/Administration/AddUser'
import { ListUsers } from '../components/Administration/ListUsers'
import { AddCostCenter } from '../components/Administration/AddCostCenter'
import { ListCostCenter } from '../components/Administration/ListCostCenter'
import { UserContext } from '../context/UserContext'

const useStyles = makeStyles(() =>
	createStyles({
		containerMobile: {
			padding: '1rem 0',
		},
		containerApp: {
			padding: '2rem 0',
		},
		custList: {
			'& li': {
				fontFamily: 'Lato-Regular',
			},
		},
		contentWrapper: {
			background: '#EFF1F3',
			height: 'calc(100vh - 160px)',
			paddingTop: '80px',
		},
		container: {
			padding: '3% 3%',
		},
		menuWidth: {
			width: '90%',
		},
		selectedItem: {
			background: '#EFF1F3',
		},
		contentSelected: {
			background: '#EFF1F3',
			borderRadius: '4px',
			boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
			height: 'calc(100vh - 260px)',
			overflow: 'auto',
		},
		itemSelected: {
			height: 'calc(100vh - 260px)',
			overflow: 'auto',
		},
	})
)

const INIT_MENU = {
	addCompany: false,
	listCompanies: false,
	addStore: false,
	listStores: false,
	addCostCenter: false,
	listCostCenters: false,
	addUser: false,
	listUsers: false,
}

export const AdministrationPage = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [menuItem, setMenuItem] = useState(INIT_MENU)
	const [componentValue, setComponentValue] = useState(0)
	const [storeEdit, setStoreEdit] = useState<any>()
	const [companyEdit, setCompanyEdit] = useState<any>()
	const [costCenterEdit, setCostCenterEdit] = useState<any>()
	const [userEdit, setUserEdit] = useState<any>()
	const role = localStorage.getItem('role')

	const { selectedOption, setSelectedOption } = useContext(UserContext)

	useEffect(() => {
		setSelectedOption({ ...selectedOption, item: 1 })
	}, [])

	const handleSetSelected = value => {
		setMenuItem({
			...INIT_MENU,
			[value]: true,
		})
	}

	const renderComponent = value => {
		switch (value) {
			case 0:
				return null
			case 1:
				return <AddCompany setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} companyEdit={companyEdit} />
			case 2:
				return <ListCompanies setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} setCompanyEdit={setCompanyEdit} />
			case 3:
				return <AddStore setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} storeEdit={storeEdit} />
			case 4:
				return <ListStores setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} setStoreEdit={setStoreEdit} />
			case 5:
				return <AddCostCenter setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} costCenterEdit={costCenterEdit} />
			case 6:
				return <ListCostCenter setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} setCostCenterEdit={setCostCenterEdit} />
			case 7:
				return <AddUser setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} userEdit={userEdit} />
			case 8:
				return <ListUsers setComponentValue={setComponentValue} handleSetSelected={handleSetSelected} setUserEdit={setUserEdit} />
			default:
				break
		}
	}

	return (
		<Grid container spacing={3} className={isMobile ? classes.containerMobile : classes.containerApp} justify="space-between">
			<Grid item xs={12} md={3}>
				<Paper>
					<MenuList className={classes.custList}>
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN') && (
							<MenuItem
								className={menuItem.addCompany ? classes.selectedItem : ''}
								onClick={() => {
									handleSetSelected('addCompany')
									setComponentValue(1)
								}}
							>
								<ListItemIcon>
									<BusinessIcon fontSize="small" />
								</ListItemIcon>
								<Typography variant="inherit">Agregar Compañía</Typography>
							</MenuItem>
						)}
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN' || role === 'USUARIO EMPRESA') && (
							<>
								<MenuItem
									className={menuItem.listCompanies ? classes.selectedItem : ''}
									onClick={() => {
										handleSetSelected('listCompanies')
										setComponentValue(2)
									}}
								>
									<ListItemIcon>
										<ListAltIcon fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">Lista de Compañías</Typography>
								</MenuItem>
								<Divider variant="middle" style={{ margin: '5% 0%' }} />
							</>
						)}
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN' || role === 'USUARIO EMPRESA') && (
							<MenuItem
								className={menuItem.addStore ? classes.selectedItem : ''}
								onClick={() => {
									handleSetSelected('addStore')
									setStoreEdit(null)
									setComponentValue(3)
								}}
							>
								<ListItemIcon>
									<StoreIcon fontSize="small" />
								</ListItemIcon>
								<Typography variant="inherit">Agregar Sucursal</Typography>
							</MenuItem>
						)}
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN' || role === 'USUARIO SUCURSAL' || role === 'USUARIO EMPRESA') && (
							<>
								<MenuItem
									className={menuItem.listStores ? classes.selectedItem : ''}
									onClick={() => {
										handleSetSelected('listStores')
										setComponentValue(4)
									}}
								>
									<ListItemIcon>
										<ListAltIcon fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">Lista de Sucursales</Typography>
								</MenuItem>{' '}
								<Divider variant="middle" style={{ margin: '5% 0%' }} />
							</>
						)}
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN' || role === 'USUARIO SUCURSAL' || role === 'USUARIO EMPRESA') && (
							<MenuItem
								className={menuItem.addCostCenter ? classes.selectedItem : ''}
								onClick={() => {
									handleSetSelected('addCostCenter')
									setStoreEdit(null)
									setComponentValue(5)
								}}
							>
								<ListItemIcon>
									<QueueIcon fontSize="small" />
								</ListItemIcon>
								<Typography variant="inherit">Agregar Centro de Costo</Typography>
							</MenuItem>
						)}
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN' || role === 'USUARIO SUCURSAL' || role === 'USUARIO CENTRO COSTO' || role === 'USUARIO EMPRESA') && (
							<>
								<MenuItem
									className={menuItem.listCostCenters ? classes.selectedItem : ''}
									onClick={() => {
										handleSetSelected('listCostCenters')
										setComponentValue(6)
									}}
								>
									<ListItemIcon>
										<ListAltIcon fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">Lista de Centros de Costos</Typography>
								</MenuItem>
								<Divider variant="middle" style={{ margin: '5% 0%' }} />
							</>
						)}
						{(role === 'ADMINISTRADOR' || role === 'SUPERADMIN' || role === 'USUARIO SUCURSAL' || role === 'USUARIO EMPRESA') && (
							<>
								<MenuItem
									className={menuItem.addUser ? classes.selectedItem : ''}
									onClick={() => {
										handleSetSelected('addUser')
										setStoreEdit(null)
										setComponentValue(7)
									}}
								>
									<ListItemIcon>
										<PersonAddIcon fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">Agregar Usuario</Typography>
								</MenuItem>
								<MenuItem
									className={menuItem.listUsers ? classes.selectedItem : ''}
									onClick={() => {
										handleSetSelected('listUsers')
										setComponentValue(8)
									}}
								>
									<ListItemIcon>
										<RecentActorsIcon fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">Lista de Usuarios</Typography>
								</MenuItem>
							</>
						)}
					</MenuList>
				</Paper>
			</Grid>
			<Grid item xs={12} md={9} className={classes.itemSelected}>
				<Grid container justify="flex-end">
					<Grid item xs={12} md={12}>
						{renderComponent(componentValue)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
