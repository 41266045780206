import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	createStyles,
	makeStyles,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	ThemeProvider,
	withStyles,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { RemoveUserDialog } from '../Dialogs/RemoveUserDialog'
import MainColorTheme from '../utils/MainColorTheme'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { getUsers } from '../../providers/Private/Provider'

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '1% 1%',
		},
		table: {
			textAlign: 'center',
			width: '100%',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonDelete: {
			border: '2px solid rgba(0, 0, 0, 0.54)',
			color: 'rgba(0, 0, 0, 0.54)',
		},
		editHour: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		emptyBox: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
			width: '100%',
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		custCell: {
			padding: '0 !important',
		},
	})
)

const INIT_DIALOGS = {
	remove: false,
}

export const ListUsers = ({ setUserEdit, setComponentValue, handleSetSelected }) => {
	const classes = useStyles()
	const [users, setUsers] = useState([])
	const [dialogs, setDialogs] = useState(INIT_DIALOGS)
	const [userSelected, setUserSelected] = useState(0)
	const [loader, setLoader] = useState(true)

	useEffect(() => {
		getUsers()
			.then(response => {
				if (response && response.success && response?.data?.length > 0) {
					setUsers(response.data)
				}
			})
			.catch(err => console.error('error', err))
			.finally(() => {
				setLoader(false)
			})
	}, [])

	const handleSubmit = e => {
		e.preventDefault()
	}

	const editUser = user => {
		setUserEdit(user)
		handleSetSelected('addUser')
		setComponentValue(7)
	}

	const handleOpenRemoveDialog = (user: any) => {
		setUserSelected(user)
		setDialogs({ ...INIT_DIALOGS, remove: true })
	}

	const handleRemoveDialog = () => event => {
		setDialogs({ ...INIT_DIALOGS, remove: false })
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={34} className={classes.customProgress} />
			</Box>
		)
	}

	return users && users.length > 0 ? (
		<ThemeProvider theme={MainColorTheme}>
			<form onSubmit={handleSubmit} autoComplete="off">
				<h2 className={'ct-black ff-LatoRegular fs20'}>{'Lista de Usuarios'}</h2>
				<TableContainer component={Paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<StyledTableCellHead>{'Nombres'}</StyledTableCellHead>
								<StyledTableCellHead>{'Apellidos'}</StyledTableCellHead>
								<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
								<StyledTableCellHead>{'Correo'}</StyledTableCellHead>
								<StyledTableCellHead>{'Compañía'}</StyledTableCellHead>
								<StyledTableCellHead>{'Rol'}</StyledTableCellHead>
								<StyledTableCellHead> {'Acciones'}</StyledTableCellHead>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map((user: any, index) => (
								<>
									<StyledTableRowBody key={index}>
										<StyledTableCellBody>{user.firstName ? user.firstName : 'Sin Nombres'}</StyledTableCellBody>
										<StyledTableCellBody>{user.lastName ? user.lastName : 'Sin Apellidos'}</StyledTableCellBody>
										<StyledTableCellBody>{user.phoneNumber ? user.phoneNumber : 'Sin Télefono'}</StyledTableCellBody>
										<StyledTableCellBody>{user.email ? user.email : 'Sin Email'}</StyledTableCellBody>
										<StyledTableCellBody>{user.company ? user.company.name : 'Sin Compañía'}</StyledTableCellBody>
										<StyledTableCellBody>{user.role?.name ? user.role.name : 'Sin Rol'}</StyledTableCellBody>

										<StyledTableCellBody>
											<Button
												classes={{ root: classes.buttonTableAction }}
												className={classes.editHour}
												color="primary"
												fullWidth
												variant="contained"
												onClick={() => editUser(user)}
											>
												{'Editar'}
											</Button>
										</StyledTableCellBody>
									</StyledTableRowBody>
								</>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</form>
			<RemoveUserDialog handleDialog={handleRemoveDialog()} open={dialogs.remove} user={userSelected} />
		</ThemeProvider>
	) : (
		<Box className={classes.emptyBox}>
			<h1 className={'ct-black ff-LatoRegular fs20'}>No hay usuarios disponibles</h1>
		</Box>
	)
}

const dummy = [
	{
		firstName: 'Nombre Nombre',
		lastName: 'Apellido Apellido',
		phoneNumber: '+56912345678',
		email: 'correo@correo.cl',
		company: 'Devaid',
		role: 'USUARIO EMPRESA',
	},
	{
		firstName: 'Nombre2 Nombre2',
		lastName: 'Apellido2 Apellido2',
		phoneNumber: '+56912345677',
		email: 'correo1@correo.cl',
		company: 'VMOVE',
		role: 'SUPERUSUARIO',
	},
]
