import { FC, useState, useEffect, useContext } from 'react'
import {
	Box,
	Button,
	CircularProgress,
	Container,
	createStyles,
	Grid,
	InputAdornment,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Theme,
	ThemeProvider,
	useTheme,
	withStyles,
	IconButton,
} from '@material-ui/core'
import DateShiftSearch from '../components/FrequencyTime/DateShiftSearch'
import { getShifts, getStores, postGetCCByStore } from '../providers/Private/Provider'
import { Link } from 'react-router-dom'
import MainColorTheme from '../components/utils/MainColorTheme'
import { RemoveShiftDialog } from '../components/Dialogs/RemoveShiftDialog'

//icons
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import SearchIcon from '@material-ui/icons/Search'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

//time manager
import 'date-fns'
import { useNotification } from '../hooks/useNotification'
import { UserContext } from '../context/UserContext'
const es_ES = require('date-fns/locale/es')
const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#595B62',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		table: {
			minWidth: 700,
			textAlign: 'center',
			width: '100%',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				padding: '14px',
			},
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customLabel: {
			marginBottom: '5px',
		},
		buttonTableAction: {
			borderRadius: 40,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			minWidth: 120,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		showRoute: {
			'backgroundColor': '#0D1373',
			'color': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		editRoute: {
			'backgroundColor': '#008589',
			'color': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#008589',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		failMessage: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 600px)',
			justifyContent: 'center',
			alignContent: 'center',
			margin: 'auto',
		},
		customProgress: {
			color: '#0D1373',
		},
		buttonBack: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		customPagination: {
			'& .MuiTablePagination-caption': {
				fontFamily: 'Lato-Regular',
			},
		},
		boxArrows: {
			display: 'flex',
			marginLeft: '1.5rem',
		},
		customContainer: {
			padding: '4rem 0',
		},
		customContainerTable: {
			paddingBottom: '2rem',
		},
		buttonDelete: {
			border: '2px solid rgba(0, 0, 0, 0.54)',
			color: 'rgba(0, 0, 0, 0.54)',
		},
	})
)

interface TablePaginationActionsProps {
	count: number
	page: number
	rowsPerPage: number
	onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
	const classes = useStyles()
	const theme = useTheme()
	const { count, page, rowsPerPage, onChangePage } = props

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, 0)
	}

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1)
	}

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1)
	}

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<Box className={classes.boxArrows}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	)
}

const INIT_FILTER = { name: 'Todas', id: 0, state: 1, address: { street: '', number: '' } }

const MyShiftsPage: FC = () => {
	const classes = useStyles()
	const [loader, setLoader] = useState({ shifts: true, stores: false, costCenters: false, pagination: false })
	const [selectedDate, setSelectedDate] = useState<Date | null>(null)
	const [shifts, setShifts] = useState<any>([])
	const [totalShifts, setTotalShifts] = useState(0)
	const [stores, setStores] = useState<any>([INIT_FILTER])
	const [costCenters, setCostCenters] = useState<any>([INIT_FILTER])
	const [error, setError] = useState(false)
	const [searchData, setSearchData] = useState({
		filterStore: 0,
		filterCostCenter: 0,
		data: '',
	})
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const [removeDialog, setRemoveDialog] = useState(false)
	const [shiftToRemove, setShiftToRemove] = useState()
	const [handleNotification] = useNotification()

	const { setSelectedOption, selectedOption, setRoutesSection } = useContext(UserContext)

	useEffect(() => {
		getAllStores()
		setRoutesSection(true)
		setSelectedOption({ item: 3, selected: true })
	}, [])

	const getAllStores = () => {
		getStores()
			.then(response => {
				if (response && response.success) {
					setStores([...stores, ...response.data])
					setError(false)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(error => {
				console.error('STORES_ERROR', error)
				setError(true)
			})
			.finally(() => {
				setLoader({ ...loader, stores: false })
			})
	}

	useEffect(() => {
		searchList()
		handleCCbyStores()
	}, [searchData, selectedDate, page, rowsPerPage])

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy', { locale: es_ES }).toString()
	}

	const formatDateToSeach = (date: any) => {
		return format(date, 'yyyy-MM-dd', { locale: es_ES }).toString()
	}

	const handleSearchChange = event => {
		const { value } = event.target
		setSearchData({ ...searchData, data: value })
	}

	const handleStoreChange = event => {
		const { value } = event.target
		setSearchData({ ...searchData, filterStore: value })
	}

	const handleCostCenterChange = event => {
		const { value } = event.target
		setSearchData({ ...searchData, filterCostCenter: value })
	}

	const handleCCbyStores = () => {
		const { filterStore } = searchData
		if (filterStore !== 0) {
			const setPostdata = {
				id: filterStore,
			}
			postGetCCByStore(setPostdata)
				.then(response => {
					if (response && response.success) {
						const { data } = response
						setCostCenters([INIT_FILTER, ...data])
						setError(false)
					}
				})
				.catch(error => {
					console.error('STORES_ERROR', error)
					setError(true)
				})
				.finally(() => {
					setLoader({ ...loader, costCenters: false })
				})
		}
	}

	const searchList = () => {
		setLoader({ ...loader, pagination: true })
		const { filterStore, filterCostCenter, data } = searchData

		let filter: string = '?'
		filter = selectedDate ? `${filter}&startDate=${formatDateToSeach(selectedDate)}` : filter
		filter = filterStore !== 0 ? `${filter}&store=${filterStore}` : filter
		filter = filterCostCenter !== 0 ? `${filter}&costCenter=${filterCostCenter}` : filter
		filter = data !== '' ? `${filter}&name=${data.toString().replace(/\s+/g, '+')}` : filter
		filter = `${filter}&perPage=${rowsPerPage}&page=${page}`

		if (data.length > 2 || data.length === 0) {
			getShifts(filter)
				.then(response => {
					if (response && response.success) {
						const { data, count } = response
						setShifts(data)
						setTotalShifts(count)
					} else {
						setShifts([])
						setTotalShifts(0)
					}
				})
				.finally(() => {
					setLoader({ ...loader, shifts: false, pagination: false })
				})
		} else {
			getShifts(filter)
				.then(response => {
					if (response && response.success) {
						const { data } = response
						setShifts(data)
						setTotalShifts(response.count)
					}
				})
				.finally(() => {
					setLoader({ ...loader, shifts: false, pagination: false })
				})
		}
	}

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setLoader({ ...loader, pagination: true })
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (parseInt(event.target.value) === -1) {
			setRowsPerPage(totalShifts)
		} else {
			setRowsPerPage(parseInt(event.target.value, 10))
		}
		setPage(0)
	}

	const storeAddress = store => {
		return store.id > 0 ? (store.address !== null ? `(${store.address.street})` : 'Sin Dirección') : ''
	}

	const handleOpenRemoveDialog = (data: any) => event => {
		setShiftToRemove(data)
		setRemoveDialog(!removeDialog)
	}

	const handleRemoveDialog = () => event => {
		setRemoveDialog(!removeDialog)
	}

	if (loader.stores) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}

	if (loader.shifts) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}

	return !error ? (
		<ThemeProvider theme={MainColorTheme}>
			<Container maxWidth="lg" style={{ paddingTop: '3%' }}>
				<div>
					<h1 className={'ct-light-black ff-LatoRegular fs25 tt-upper'}>{'Mis Rutas'}</h1>
					<p className={'ct-light-black ff-LatoRegular fs16'}>{'En este apartado, encontrarás todas las rutas que has creado en el formulario de creación de rutas.'}</p>
					<Grid container>
						<Grid container spacing={2} className={classes.customContainerTable}>
							<Grid item xs={12} md={3}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Escriba nombre de la ruta</p>
								<TextField
									className={classes.customTextField}
									fullWidth
									name={'routeName'}
									variant="outlined"
									onChange={handleSearchChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position="end">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Seleccione Sucursal</p>
								<Select id="office" name="office" variant="outlined" className={classes.customTextField} fullWidth onChange={handleStoreChange}>
									{stores.map(
										(store, index) =>
											store.state === 1 && (
												<MenuItem key={index} value={store.id} classes={{ root: classes.customMenuItem }}>
													{`${store.name} ${storeAddress(store)}`}
												</MenuItem>
											)
									)}
								</Select>
							</Grid>
							<Grid item xs={12} md={3}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Seleccione Centro de Costo</p>
								<Select
									id="costCenter"
									name="costCenter"
									defaultValue={costCenters[0]}
									variant="outlined"
									className={classes.customTextField}
									fullWidth
									onChange={handleCostCenterChange}
								>
									{costCenters.map((costCenter, index) => (
										<MenuItem key={index} value={costCenter.id} classes={{ root: classes.customMenuItem }}>
											{`${costCenter.name} ${storeAddress(costCenter)}`}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item xs={12} md={3}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Fecha</p>
								<DateShiftSearch selectedDate={selectedDate} setSelectedDate={setSelectedDate} disabled={false} />
							</Grid>
						</Grid>
						{shifts.length !== 0 ? (
							<Grid item xs={12} md={12}>
								<TableContainer component={Paper}>
									<Table className={classes.table}>
										<TableHead>
											<TableRow>
												<StyledTableCellHead>{'Sucursal'}</StyledTableCellHead>
												<StyledTableCellHead>{'Centro de Costo'}</StyledTableCellHead>
												<StyledTableCellHead>{'Nombre de la Ruta'}</StyledTableCellHead>
												<StyledTableCellHead>{'Fecha de Inicio'}</StyledTableCellHead>
												<StyledTableCellHead>{'Estado'}</StyledTableCellHead>
												<StyledTableCellHead>{'Creado por'}</StyledTableCellHead>
												<StyledTableCellHead colSpan={3}>{'Acciones'}</StyledTableCellHead>
											</TableRow>
										</TableHead>
										<TableBody>
											{!loader.pagination ? (
												shifts.map((shift: any, index: number) => (
													<StyledTableRowBody key={index}>
														<StyledTableCellBody>{shift.store ? shift.store : 'Oficina No Informada'}</StyledTableCellBody>
														<StyledTableCellBody>{shift.costCenter ? shift.costCenter : 'Centro de costo no informado'}</StyledTableCellBody>
														<StyledTableCellBody>{shift.shiftName ? shift.shiftName : 'No Informado'}</StyledTableCellBody>
														<StyledTableCellBody>{shift.startDate ? formatDate(new Date(`${shift.startDate}T00:00:00`)) : 'No Aplica'}</StyledTableCellBody>
														<StyledTableCellBody>
															{shift.state !== undefined ? shift.state ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : 'Incompleto' : 'Desconocido'}
														</StyledTableCellBody>
														<StyledTableCellBody>{shift.createdBy ? shift.createdBy : 'No Disponible'}</StyledTableCellBody>
														<StyledTableCellBody>
															<Button
																component={Link}
																to={`/${shift.id}/existing-route`}
																classes={{ root: classes.buttonTableAction }}
																className={classes.showRoute}
																color="primary"
																variant="contained"
															>
																{'Ver Turno'}
															</Button>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Button
																component={Link}
																to={`/my-routes/${shift.id}/edit`}
																classes={{ root: classes.buttonTableAction }}
																className={classes.editRoute}
																color="primary"
																variant="contained"
															>
																{'Editar Turno'}
															</Button>
														</StyledTableCellBody>
														<StyledTableCellBody>
															<IconButton aria-label="delete" classes={{ root: classes.buttonDelete }} onClick={handleOpenRemoveDialog(shift)}>
																<DeleteIcon />
															</IconButton>
														</StyledTableCellBody>
													</StyledTableRowBody>
												))
											) : (
												<StyledTableRowBody>
													<StyledTableCellBody colSpan={6}>
														<CircularProgress size={40} className={classes.customProgress} />
													</StyledTableCellBody>
												</StyledTableRowBody>
											)}
										</TableBody>
										<TableFooter>
											<TableRow>
												<TablePagination
													className={classes.customPagination}
													rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
													count={totalShifts}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={handleChangePage}
													onChangeRowsPerPage={handleChangeRowsPerPage}
													labelRowsPerPage={'Ítems por Página'}
													ActionsComponent={TablePaginationActions}
													labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
												/>
											</TableRow>
										</TableFooter>
									</Table>
								</TableContainer>
								<RemoveShiftDialog handleDialog={handleRemoveDialog()} open={removeDialog} setShifts={setShifts} shifts={shifts} shift={shiftToRemove} />
							</Grid>
						) : (
							<Box className={classes.failMessage}>
								<Grid container>
									<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Sin coincidencias'}</p>
									</Grid>
								</Grid>
							</Box>
						)}
					</Grid>
					<Grid container alignItems="center" justify="space-between" className={classes.customContainer}>
						<Grid item xs={12} md={4}>
							<Button component={Link} to={'/new-route'} className={classes.buttonBack} color="primary" fullWidth startIcon={<TransitEnterexitIcon />}>
								{'Volver'}
							</Button>
						</Grid>
					</Grid>
				</div>
			</Container>
		</ThemeProvider>
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ha ocurrido un error, intentalo más tarde'}</p>
				</Grid>
				<Grid container alignItems="center" justify="center" className={'mt-2r'} style={{ paddingBottom: '3%', textAlign: 'center' }}>
					<Grid item xs={12} md={3}>
						<Button component={Link} to={'/new-route'} className={classes.buttonBack} color="primary" fullWidth startIcon={<TransitEnterexitIcon />}>
							{'Volver'}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default MyShiftsPage
