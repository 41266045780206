import { Box, Button, CircularProgress, createStyles, Grid, InputAdornment, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useMediaQuery } from 'react-responsive'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { useNotification } from '../../hooks/useNotification'
import { getCounties } from '../../providers/Public/Provider'
import { getCompanies, postStores, putStoreNormalize, putStores } from '../../providers/Private/Provider'

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HomeIcon from '@material-ui/icons/Home'
import PhoneIcon from '@material-ui/icons/Phone'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { addStoresSchema } from '../../validations'

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 260px)',
			justifyContent: 'center',
		},
		actionButton: {
			background: '#0D1373',
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			height: 49,
			width: 240,
		},
		wrongTitle: {
			color: '#FF5E05',
			textTransform: 'uppercase',
			fontWeight: 600,
			fontFamily: 'Lato-Regular',
			fontStyle: 'normal',
			fontSize: '20px',
		},
		error: {
			'fontFamily': 'Lato-Regular',
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: 'red',
				},
				'&:hover fieldset': {
					borderColor: 'red',
				},
				'&.Mui-focused fieldset': {
					borderColor: 'red',
				},
			},
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		errorValidation: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		errorAutocomplete: {
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: 'red',
				},
				'&:hover fieldset': {
					borderColor: 'red',
				},
				'&.Mui-focused fieldset': {
					borderColor: 'red',
				},
			},
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		spanPhone: {
			fontFamily: 'Lato-Regular',
			marginLeft: 20,
		},
	})
)

export const AddStore = ({ setComponentValue, storeEdit, handleSetSelected }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [counties, setCounties] = useState<any>([])
	const [companies, setCompanies] = useState<any>([])
	const [companiesLoader, setCompaniesLoader] = useState(true)
	const [handleNotification] = useNotification()
	const [normalizeError, setNormalizeError] = useState(false)
	const [storeId, setStoreId] = useState('')
	const [sendingdata, setSendingdata] = useState(false)
	const [sendDataMessage, setSendDataMessage] = useState('')

	useEffect(() => {
		getCounties()
			.then(response => {
				response !== undefined &&
					setCounties(
						response.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error al listar las comunas', 'error')
			})

		getCompanies()
			.then(response => {
				if (response && response.success) {
					setCompanies(
						response.data.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error al listar las compañías', 'error')
			})
			.finally(() => setCompaniesLoader(false))
	}, [])

	const handleSubmit = (values: any) => {
		setSendingdata(true)
		if (normalizeError) {
			setSendDataMessage('Actualizando Sucursal')
			putStores(storeId, values)
				.then(response => {
					if (response && response.success) {
						setSendDataMessage('Normalizando Sucursal')
						putStoreNormalize(response.data.id)
							.then(responseNormalized => {
								if (responseNormalized) {
									const { success } = responseNormalized
									if (success) {
										handleNotification('Se ha creado la sucursal con éxito', 'success')
										formik.resetForm()
										setNormalizeError(false)
										setComponentValue(4)
										handleSetSelected('listStores')
									} else {
										setNormalizeError(true)
									}
								} else {
									handleNotification('Ha ocurrido un error', 'error')
								}
								setSendingdata(false)
							})
							.catch(() => {
								handleNotification('No se reconoce la dirección ingresada', 'error')
								setSendingdata(false)
							})
					} else {
						handleNotification('Error en la modificación de la sucursal', 'error')
						setSendingdata(false)
					}
				})
				.catch(() => {
					handleNotification('Error en la modificación de la sucursal', 'error')
					setSendingdata(false)
				})
		} else {
			setNormalizeError(false)
			setSendDataMessage('Creando Sucursal')
			const setPutData = {
				name: values.name,
				companyId: values.companyId,
				phoneNumber: values.phoneNumber,
				emailInCharge: values.emailInCharge,
				numberInCharge: values.numberInCharge,
				address: {
					street: values.address.street,
					countyId: values.address.county.id,
				},
			}
			postStores(setPutData)
				.then(response => {
					if (response && response.success) {
						setSendDataMessage('Normalizando Sucursal')
						setStoreId(response.data.id)
						putStoreNormalize(response.data.id)
							.then(responseNormalized => {
								if (responseNormalized) {
									const { success } = responseNormalized
									if (success) {
										handleNotification('Se ha creado la sucursal con éxito', 'success')
										formik.resetForm()
										setNormalizeError(false)
										setComponentValue(4)
										handleSetSelected('listStores')
									} else {
										setNormalizeError(true)
									}
								} else {
									handleNotification('Ha ocurrido un error', 'error')
								}
								setSendingdata(false)
							})
							.catch(() => {
								setSendingdata(false)
								handleNotification('No se reconoce la dirección ingresada', 'error')
							})
					} else {
						setSendingdata(false)
						handleNotification('Error en la creación de la sucursal', 'error')
					}
				})
				.catch(() => {
					setSendingdata(false)
					handleNotification('Error en la creación de la sucursal', 'error')
				})
		}
	}

	const handleUpdate = (values: any) => {
		const setPutData = {
			name: values.name,
			companyId: values.companyId,
			phoneNumber: values.phoneNumber,
			emailInCharge: values.emailInCharge,
			numberInCharge: values.numberInCharge,
			address: {
				street: values.address.street,
				countyId: values.address.county.id,
			},
		}
		setSendingdata(true)
		putStores(storeEdit.id, setPutData)
			.then(response => {
				if (response && response.success) {
					setSendDataMessage('Normalizando Sucursal')
					putStoreNormalize(response.data.id)
						.then(responseNormalized => {
							if (responseNormalized) {
								const { success } = responseNormalized
								if (success) {
									handleNotification('Se ha creado la sucursal con éxito', 'success')
									formik.resetForm()
									setNormalizeError(false)
									setComponentValue(4)
									handleSetSelected('listStores')
								} else {
									setNormalizeError(true)
								}
							} else {
								handleNotification('Ha ocurrido un error', 'error')
							}
							setSendingdata(false)
						})
						.catch(() => {
							handleNotification('No se reconoce la dirección ingresada', 'error')
							setSendingdata(false)
						})
				} else {
					handleNotification('Error en la modificación de la sucursal', 'error')
					setSendingdata(false)
				}
			})
			.catch(() => {
				handleNotification('Error en la modificación de la sucursal', 'error')
				setSendingdata(false)
			})
	}

	const formik = useFormik({
		initialValues: {
			name: storeEdit ? storeEdit.name : '',
			companyId: storeEdit ? storeEdit.company.id : '',
			phoneNumber: storeEdit ? storeEdit.phoneNumber : '',
			emailInCharge: storeEdit ? storeEdit.emailInCharge : '',
			numberInCharge: storeEdit ? storeEdit.numberInCharge : '',
			address: {
				street: storeEdit ? storeEdit.address.street : '',
				county: storeEdit ? storeEdit.address.county : null,
			},
		},
		validationSchema: addStoresSchema,
		onSubmit: values => {
			storeEdit ? handleUpdate(values) : handleSubmit(values)
		},
	})

	return !sendingdata ? (
		<Grid item xs={12} md={12}>
			{counties?.length > 0 && companies?.length > 0 ? (
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit} autoComplete="off">
						<Grid container>
							<Grid item xs={12} md={6}>
								<h2 className={'ct-black ff-LatoRegular fs20'}>{'Nueva Sucursal'}</h2>
							</Grid>
							<Grid item xs={12} md={6}>
								{normalizeError && <p className={classes.wrongTitle}>{'Problemas al normalizar'}</p>}
								{normalizeError && (
									<p className={classes.wrongTitle} style={{ fontSize: '14px', margin: 'auto', textTransform: 'none' }}>
										{'Por favor, ingresa una dirección válida'}
									</p>
								)}
							</Grid>
							<Grid container spacing={2} className={classes.containerInput}>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Nombre'}</p>
									<TextField
										className={classes.customTextField}
										id="name"
										name="name"
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.name}
										fullWidth
										helperText={<ErrorMessage name="name" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AccountCircleIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Compañía</p>
									<Field
										className={classes.customTextField}
										as={TextField}
										name={'companyId'}
										onChange={formik.handleChange}
										value={formik.values.companyId}
										variant="outlined"
										fullWidth
										select
										helperText={<ErrorMessage name="companyId" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									>
										{companies.map(({ id, name }) => (
											<MenuItem key={id} value={id} className={'ff-LatoRegular'}>
												{name}
											</MenuItem>
										))}
									</Field>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.containerInput}>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Teléfono'}</p>
									<TextField
										className={classes.customTextField}
										id="street"
										name={`phoneNumber`}
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.phoneNumber}
										fullWidth
										helperText={<ErrorMessage name="phoneNumber" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										inputProps={{ maxLength: 9 }}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIcon />
													<span className={classes.spanPhone}>+56</span>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Comuna</p>
									<Autocomplete
										classes={{ option: classes.customMenuItem }}
										getOptionLabel={(option: any) => (option ? option['name'] : '')}
										id="county"
										options={counties}
										noOptionsText={'Sin coincidencias'}
										onChange={(e, value) => {
											formik.setFieldValue(`address.county`, value !== null ? value : 0)
										}}
										value={formik.values.address.county}
										renderInput={params => (
											<TextField
												{...params}
												name={`address.county`}
												className={normalizeError ? classes.errorAutocomplete : classes.customAutocompleteField}
												required={true}
												variant="outlined"
												helperText={<ErrorMessage name={`address.county`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
												value={formik.values.address.county}
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.containerInput}>
								<Grid item xs={12} md={12}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Calle</p>
									<TextField
										className={normalizeError ? classes.error : classes.customTextField}
										id="street"
										name={`address.street`}
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.address.street}
										fullWidth
										helperText={<ErrorMessage name={`address.street`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<HomeIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.containerInput}>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Email encargado sucursal</p>
									<TextField
										className={classes.customTextField}
										id="street"
										name={`emailInCharge`}
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.emailInCharge}
										fullWidth
										helperText={<ErrorMessage name={`emailInCharge`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AlternateEmailIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Número encargado sucursal</p>
									<TextField
										className={classes.customTextField}
										id="number"
										name={`numberInCharge`}
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.numberInCharge}
										inputProps={{ maxLength: 9 }}
										fullWidth
										helperText={<ErrorMessage name={`numberInCharge`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneIcon />
													<span className={classes.spanPhone}>+56</span>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} style={{ textAlign: 'center', paddingTop: '2%' }}>
									<Button className={`${classes.actionButton} ${isMobile && 'w-100'}`} type="submit" variant={'contained'}>
										{storeEdit?.id ? 'Confirmar Edición' : 'Agregar'}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</FormikProvider>
			) : (
				<Box className={classes.boxLoading}>
					{companiesLoader ? <CircularProgress size={34} className={classes.customProgress} /> : <h1 className={'ct-black ff-LatoRegular fs20'}>{'No hay compañías disponibles'}</h1>}
				</Box>
			)}
		</Grid>
	) : (
		<Box className={classes.boxLoading}>
			<CircularProgress size={34} className={classes.customProgress} />
			<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'}>{sendDataMessage}</p>
		</Box>
	)
}
