import * as Yup from 'yup'

export const authSchema = Yup.object().shape({
	email: Yup.string().email('E-mail inválido').required('Debes ingresar un e-mail').nullable(),
	password: Yup.string().required('Debes ingresar una contraseña').nullable(),
})

export const recoveryPasswordSchema = Yup.object().shape({
	email: Yup.string().email('E-mail inválido').required('Debes ingresar un e-mail').nullable(),
})

export const restorePasswordSchema = Yup.object().shape({
	password: Yup.string().required('Debes ingresar contraseña').nullable(),
	confirmPassword: Yup.string().required('Debes ingresar contraseña').nullable(),
})