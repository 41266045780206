import {
	Grid,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Table,
	Button,
	withStyles,
	createStyles,
	Theme,
	TableCell,
	Paper,
	makeStyles,
	Box,
	CircularProgress,
	MenuItem,
} from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

import SendIcon from '@material-ui/icons/Send'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import { Fragment, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { putWrongAddresses } from '../providers/Private/Provider'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import ReactDependentScript from 'react-dependent-script'
import { useNotification } from '../hooks/useNotification'

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#595B62',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxHeaderRoute: {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		gridHeaderWrong: {
			padding: '0 1rem',
			marginTop: '2%',
		},
		wrongTitle: {
			color: '#FF5E05',
			textTransform: 'uppercase',
			fontWeight: 600,
			fontFamily: 'Lato-Regular',
			fontStyle: 'normal',
			fontSize: '20px',
		},
		wrongSubTitle: {
			fontFamily: 'Lato-Regular',
			fontStyle: 'normal',
			fontSize: '15px',
		},
		buttonValidate: {
			'backgroundColor': '#F28F15',
			'borderRadius': 20,
			'color': '#FFFFFF',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#F28F15',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		ulAutoComplete: {
			background: '#FFFFFF',
			boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
			listStyleType: 'none',
			maxHeight: '210px',
			overflowY: 'auto',
			overflowX: 'auto',
			paddingLeft: 0,
			position: 'absolute',
			zIndex: 100,
			margin: 0,
			width: '300px',
		},
		ulMob: {
			left: 0,
		},
		liAutocomplete: {
			fontFamily: 'Lato-Regular',
			backgroundColor: '#fff',
			padding: '12px 15px',
			cursor: 'pointer',
			fontSize: '.875rem',
		},
		liActive: {
			backgroundColor: '#41b6e6',
		},
		liProgress: {
			backgroundColor: '#fff',
			padding: '12px 15px',
			width: '210px',
			height: '50px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	})
)

export const WrongAddressPage = props => {
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [visits, setVisits] = useState<any>([])
	const id = props.match.params.id
	const [isOk, setIsOk] = useState(false)
	const { data } = props.location.state
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const [handleNotification] = useNotification()

	useEffect(() => {
		data && fillVisits(data.collaborators)
	}, [data])

	const fillVisits = collaborators => {
		let newArr: any = []
		collaborators.map(collaborator => {
			newArr.push({ ...collaborator })
		})
		setVisits(newArr)
	}

	const handleSubmit = e => {
		e.preventDefault()
		const setData = {
			visits: visits,
		}
		if (!verifySelectedOptions()) {
			setSendingData(true)
			putWrongAddresses(setData)
				.then(response => {
					if (response && response.success) {
						setIsOk(true)
					} else {
						handleNotification('Ha ocurrido un error', 'error')
					}
				})
				.catch(err => console.error(err))
		} else {
			handleNotification('Asegurate de seleccionar una dirección de las sugerencias', 'warning')
		}
	}

	const handleSelect = (value, index) => {
		let result: any
		geocodeByAddress(value).then(results => {
			result = results[0]
			let clone = JSON.parse(JSON.stringify(visits))

			clone.map(visit => {
				clone[index]['address']['googleAddress'] = result.formatted_address
				clone[index]['address']['isSelected'] = true
			})
			setVisits(clone)
		})
	}

	const handleChange = (value, index) => {
		let clone = JSON.parse(JSON.stringify(visits))

		clone.map(visit => {
			clone[index]['address']['googleAddress'] = value
			clone[index]['address']['isSelected'] = false
		})

		setVisits(clone)
	}

	const verifySelectedOptions = () => {
		return visits.some(visit => visit.address.isSelected === false || visit.address.isSelected === undefined)
	}

	const searchOptions = {
		componentRestrictions: { country: 'cl' },
	}

	if (isOk) {
		return <Redirect push to={{ pathname: `/new-route/${id}/resume` }} />
	}

	return data ? (
		<ReactDependentScript scripts={[`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_AUTOCOMPLETE}&libraries=places`]}>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className={classes.container}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Grid container>
								<Grid item xs={12} md={6} style={{ height: '80px', alignItems: 'center' }}>
									<h1 className={'ct-light-black ff-LatoRegular fs25 tt-upper'}>{'Mis Rutas'}</h1>
								</Grid>
								<Grid item xs={12} md={6} style={{ height: '80px', alignItems: 'center', lineHeight: '55px' }}>
									<h1 className={classes.wrongTitle}>{'Corregir Información'}</h1>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}></Grid>
						</Grid>
						<Grid container alignItems="center">
							<Grid item xs={12} md={6}>
								<Box className={classes.boxHeaderRoute}>
									<Grid container>
										<Grid container style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '1% 0%' }}>
											<Grid item xs={12} md={4}>
												<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Ruta'}</p>
											</Grid>
											<Grid item xs={12} md={4}>
												<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sucursal'}</p>
											</Grid>
										</Grid>
										<Grid container style={{ paddingBottom: '1%' }}>
											<Grid item xs={12} md={4}>
												<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{data.shiftName}</p>
											</Grid>
											<Grid item xs={12} md={4}>
												<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{data.shiftStore}</p>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item xs={12} md={6} className={classes.gridHeaderWrong}>
								<p className={classes.wrongTitle}>{'DIRECCIÓN MAL INGRESADA'}</p>
								<p className={classes.wrongSubTitle}>Por favor, ingresar correctamente los datos.</p>
							</Grid>
						</Grid>
						<Grid item xs={12} md={12} style={{ padding: '2% 0' }}>
							<TableContainer component={Paper}>
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											<StyledTableCellHead>{'Nombre del Pasajero'}</StyledTableCellHead>
											<StyledTableCellHead>{'Dirección Anterior'}</StyledTableCellHead>
											<StyledTableCellHead>{'Dirección'}</StyledTableCellHead>
											<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
											<StyledTableCellHead>{'E-mail'}</StyledTableCellHead>
										</TableRow>
									</TableHead>
									<TableBody>
										{visits?.map((visit: any, index) => (
											<Fragment key={index}>
												<StyledTableRowBody>
													<StyledTableCellBody>{visit.address.collaborator.fullName || 'Sin Nombre'}</StyledTableCellBody>
													<StyledTableCellBody>{`${visit.address.street}, ${visits[index].address.county.name}`}</StyledTableCellBody>
													<StyledTableCellBody>
														<PlacesAutocomplete
															value={visit.address.googleAddress}
															onChange={value => handleChange(value, index)}
															searchOptions={searchOptions}
															onSelect={addresses => {
																handleSelect(addresses, index)
															}}
														>
															{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																<>
																	<TextField
																		className={classes.customTextField}
																		fullWidth
																		variant={'outlined'}
																		{...getInputProps({ placeholder: 'Escribe una dirección' })}
																	/>
																	<ul className={`${classes.ulAutoComplete} ${isMobile && classes.ulMob}`}>
																		{loading ? (
																			<li className={classes.liProgress}>
																				<CircularProgress />
																			</li>
																		) : null}

																		{suggestions.map(suggestion => {
																			return (
																				<MenuItem
																					className={`${classes.liAutocomplete} ${suggestion.active && classes.liActive}`}
																					variant="outlined"
																					{...getSuggestionItemProps(suggestion)}
																				>
																					{suggestion.description}
																				</MenuItem>
																			)
																		})}
																	</ul>
																</>
															)}
														</PlacesAutocomplete>
													</StyledTableCellBody>
													<StyledTableCellBody>{visit.address.collaborator.phoneNumber}</StyledTableCellBody>
													<StyledTableCellBody>{visit.address.collaborator.email}</StyledTableCellBody>
												</StyledTableRowBody>
											</Fragment>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					<Grid container alignItems="center" justify="flex-start" className={'mt-2r'} style={{ paddingBottom: '3%', textAlign: 'center' }}>
						<Grid item xs={12} md={3}>
							<Button component={Link} to={'/new-route'} className={classes.buttonValidate} color="primary" fullWidth startIcon={<TransitEnterexitIcon />}>
								{'Volver'}
							</Button>
						</Grid>
						<Grid item md={6}></Grid>
						<Grid item xs={12} md={3}>
							<Button className={classes.buttonValidate} color="primary" fullWidth disabled={sendingData} type={'submit'} endIcon={<SendIcon />}>
								{'Validar'}
								{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Button>
						</Grid>
					</Grid>
				</div>
			</form>
		</ReactDependentScript>
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Error con los pasajeros'}</p>
				</Grid>
			</Grid>
			<Grid container alignItems="center" justify="flex-start" className={'mt-2r'} style={{ paddingBottom: '3%', textAlign: 'center' }}>
				<Grid item xs={12} md={3}>
					<Button component={Link} to={'/new-route'} className={classes.buttonValidate} color="primary" fullWidth startIcon={<TransitEnterexitIcon />}>
						{'Volver'}
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}
