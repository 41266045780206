import React from "react";
import { Route } from "react-router-dom";
import { MapLayout } from "./MapLayout";

export const RouteMapLayout = ({ component: Component, fullCover, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <MapLayout fullCover={fullCover}>
          <Component {...matchProps} />
        </MapLayout>
      )}
    />
  );
};

