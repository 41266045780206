import { createStyles, makeStyles, Popover, Typography } from '@material-ui/core'
import { useState } from 'react'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles(theme =>
	createStyles({
		popover: {
			pointerEvents: 'none',
		},
		paper: {
			padding: theme.spacing(1),
		},
	})
)

export const PopoverDetail = props => {
	const { comment, index } = props
	const [openPopoverId, setOpenPopoverId] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)

	const classes = useStyles()

	const handlePopoverClose = () => {
		setOpenPopoverId(null)
		setAnchorEl(null)
	}

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const open = Boolean(anchorEl)

	return (
		<>
			<Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
				<HelpOutlineIcon />
			</Typography>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>{comment}</Typography>
			</Popover>
		</>
	)
}
