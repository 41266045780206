import { FC, useContext, useState, useEffect } from 'react'
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Grid,
	Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMediaQuery } from 'react-responsive'
import queryString from 'query-string'
import { UserContext } from '../context/UserContext'
import { Redirect, useLocation } from 'react-router-dom'
import { postLogin, recoveryPassword, restorePassword } from '../providers/Access/Provider'
import LogoTransparent from '../assets/img/logoAuth.png'
import Auth from '../assets/img/authBgV2.png'
import LoginForm from '../components/Auth/LoginForm'
import RecoveryPasswordForm from '../components/Auth/RecoveryPasswordForm'
import RestorePasswordForm from '../components/Auth/RestorePasswordForm'
import Loading from '../components/Loading/Loading'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			borderBottom: '1px solid #FFFFFF',
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 48,
			textTransform: 'uppercase'
		},
		subtitle: {
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 20,
		},
		containerLogin: {
			backgroundColor: '#133147',
			backgroundImage: `url(${Auth})`,
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			justifyContent: 'center',
			height: '100vh',
			width: '100%',
		},
		boxContentFormDesk: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			width: 382
		},
		boxHeader: {
			'display': 'flex',
			'alignItems': 'center',
			'justifyContent': 'center',
			'flexDirection': 'column',
			'textAlign': 'center',
			'padding': '1rem',
			'& img, & h1, & p': {
				margin: '1.5rem 0',
				width: '50%',
			},
		},
		itemFullCenter: {
			alignItems:  'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center'
		},
	})
)

const AuthPage: FC = () => {
	const location = useLocation()
	const { setUser } = useContext(UserContext)
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [submitted, setSubmitted] = useState(false)
	const [action, setAction] = useState('')
	const [loading, setLoading] = useState(true)
	const [snackSettings, setSnackSettings] = useState({ show: false, type: '', message: '' })
	const [sendingData, setSendingData] = useState(false)
	const [token, setToken] = useState<any>('');

	const getQueryParams = () => {
		const params = queryString.parse(location.search)
		params['token'] !== undefined ? setAction('confirm') : setAction('login')
		params['token'] !== undefined && setToken(params['token'])
		setLoading(false)
	}
	
	const handleLogin = (values: IUser) => {
		setSendingData(true)
		postLogin(values).then(response => {
			const { success, id, email, message } = response
			if (success) {
				setSendingData(false)
				setUser({ isLogged: true, user: { id: id, mail: email } })
				launchSnack('success', message)
				setSubmitted(true)
			} else {
				setSendingData(false)
				message !== undefined ? launchSnack('error', message) : launchSnack('error', 'Ha ocurrido un error, intente nuevamente')
			}
		})
	}

	const handleRecoveryPassword = (values: IUserRecoveryPassword) => {
		setSendingData(true)
		recoveryPassword(values).then(response => {
			
			if (response !== false) {
				setSendingData(false)
			} else {
				setSendingData(false)
			}
		})
	}

	const handleRestorePassword = (values: IUserRestorePassword) => {
		setSendingData(true)
		restorePassword(values, token).then(response => {
			
			if (response !== false) {
				setSendingData(false)
			} else {
				setSendingData(false)
			}
		})
	}

	const launchSnack = (type: string, message: string) => {
		setSnackSettings({ show: true, type: type, message: message  })
	}

	const handleCloseSnack = () => {
		setSnackSettings({ ...snackSettings, show: false })
	}

	const renderTypeAlert = (type: any) => {
		return type
	}

	const renderForm = () => {
		switch (action) {
			case 'login':
				return <LoginForm handleLogin={handleLogin} setAction={setAction} sendingData={sendingData} />
			case 'recovery':
				return <RecoveryPasswordForm handleRecoveryPassword={handleRecoveryPassword} setAction={setAction} sendingData={sendingData} />
			case 'confirm':
				return <RestorePasswordForm handleRestorePassword={handleRestorePassword} sendingData={sendingData}  />
			default:
				return <LoginForm handleLogin={handleLogin} setAction={setAction} sendingData={sendingData} />
		}
	}

	const renderSubtitle = () => {
		switch (action) {
			case 'login':
				return 'Solicita la activación de tu cuenta a tu Administrador'
			case 'recovery':
				return 'Recupera tu contraseña para volver a utilizar tu cuenta'
			case 'confirm':
				return 'Confirma tu nueva contraseña para volver a utilizar tu cuenta'
			default:
				return 'Solicita la activación de tu cuenta a tu Administrador'
		}
	}
	
	useEffect(() => {
		getQueryParams()
	}, [])

	if (submitted) {
		return <Redirect push to={{ pathname: `/` }} />
	}
	
	if (loading) {
		return <Loading />
	}

	return  (
		<Box className={classes.containerLogin}>
			<Grid container justify="center">
				<Grid item xs={12} md={6} className={classes.itemFullCenter}>
					<Box p={3} className={`${isMobile && 'w-auto'} ${classes.boxContentFormDesk}`}>
						{renderForm()}
					</Box>
				</Grid>
				{!isMobile && (
					<Grid item xs={12} md={6}>
						<Box className={classes.boxHeader}>
							<img alt="Logo" src={LogoTransparent} />
							<h1 className={classes.title}>Bienvenido</h1>
							<h1 className={classes.subtitle}>
								{renderSubtitle()}
							</h1>
						</Box>
					</Grid>
				)}
			</Grid>
			<Snackbar
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				autoHideDuration={4000}
				onClose={handleCloseSnack}
				open={snackSettings.show}
			>
				<Alert
					className={'ff-LatoRegular'}
					onClose={handleCloseSnack}
					severity={renderTypeAlert(snackSettings.type)}
					variant="filled"
				>
					{snackSettings.message}
				</Alert>
			</Snackbar>
		</Box>
	)
}

export default AuthPage
