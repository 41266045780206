import { useEffect, useState } from 'react'
import {
	Box,
	Container,
	Grid,
	createMuiTheme,
	makeStyles,
	ThemeProvider,
	CircularProgress,
	createStyles,
	withStyles,
	Theme,
	TableContainer,
	Table,
	Button,
	TableRow,
	TableCell,
	TableBody,
	Paper,
} from '@material-ui/core'
import { getCollaborator } from '../../providers/Private/Provider'
import { useNotification } from '../../hooks/useNotification'

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(even)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
			textTransform: 'uppercase',
		},
	})
)(TableCell)

const StyledTableCellBodyStrong = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
			textTransform: 'uppercase',
			fontWeight: 'bolder',
			backgroundColor: 'darkgrey',
		},
	})
)(TableCell)

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			margin: '50px 0',
		},
		textTitle: {
			textAlign: 'center',
		},
		textData: {
			textAlign: 'left',
		},
		boxCircularProgress: {
			height: '400px',
		},
		head: {
			textAlign: 'center',
			fontWeight: 600,
			width: '400px',
			marginBottom: 30,
			fontSize: 18,
		},
		body: {
			textAlign: 'center',
			fontWeight: 400,
			width: '400px',
			marginBottom: 30,
			fontSize: 16,
		},
		return: {
			'textAlign': 'center',
			'color': '#113047',
			'fontWeight': 400,
			'width': '400px',
			'fontSize': 16,
			'&:hover': {
				cursor: 'pointer',
			},
		},
		contentWrapper: {
			background: '#EFF1F3',
			height: 'auto',
			minHeight: 'calc(100% - 120px)',
			paddingTop: '120px',
		},
		table: {
			textAlign: 'center',
			width: '100%',
		},
		boxAction: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1.5rem 0',
		},
		buttonAction: {
			'borderRadius': 43,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 120,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		labelText: {
			textAlign: 'center',
			fontFamily: 'Lato-Regular',
			fontWeight: 600,
			marginBottom: 30,
		},
	})
)

const SuccessScanner = props => {
	const classes = useStyles()
	const { data, handleReturn } = props
	const [loader, setLoader] = useState(true)
	const [responseData, setResponseData] = useState<any>(null)
	const [handleNotification] = useNotification()

	useEffect(() => {
		handleVerification()
	}, [])

	const handleVerification = () => {
		if (data !== null) {
			getCollaborator(data.split('"').join(''))
				.then(response => {
					if (response && response.success) {
						setResponseData(response.data)
						setLoader(false)
					} else {
						handleNotification('Ha ocurrido un error', 'error')
					}
				})
				.catch(err => {
					handleNotification('Ha ocurrido un error', 'error')
					setResponseData(null)
				})
		}
	}

	const defaultMaterialTheme = createMuiTheme({
		palette: {
			primary: {
				main: '#113047',
				dark: '#113047',
				contrastText: '#FFFFFF',
			},
		},
	})

	if (loader) {
		return (
			<Container>
				<ThemeProvider theme={defaultMaterialTheme}>
					<Grid
						item
						xs={12}
						md={12}
						className={classes.boxCircularProgress}
						style={{
							height: '500px',
							alignItems: 'center',
							paddingTop: '40%',
							paddingLeft: '50%',
						}}
					>
						<CircularProgress />
					</Grid>
				</ThemeProvider>
			</Container>
		)
	}

	return (
		<Box>
			<Grid justify="center" alignItems="center" container>
				<Grid className={classes.container}>
					{responseData === null ? (
						<div className={classes.head}>{'El colaborador no ha podido ser validado'}</div>
					) : (
						<div className={classes.body}>
							<>
								<p className={classes.labelText}>{'Confirmar Pasajero'}</p>
								<TableContainer component={Paper}>
									<Table className={classes.table}>
										<TableBody>
											<StyledTableRowBody>
												<StyledTableCellBodyStrong>Nombre del Pasajero</StyledTableCellBodyStrong>
												<StyledTableCellBody>{responseData.address.collaborator.fullName}</StyledTableCellBody>
											</StyledTableRowBody>
											<StyledTableRowBody>
												<StyledTableCellBodyStrong>Nombre de Ruta</StyledTableCellBodyStrong>
												<StyledTableCellBody>{responseData.route.shift.shiftName}</StyledTableCellBody>
											</StyledTableRowBody>

											<StyledTableRowBody>
												<StyledTableCellBodyStrong>Fecha</StyledTableCellBodyStrong>
												<StyledTableCellBodyStrong>Hora de entrada</StyledTableCellBodyStrong>
											</StyledTableRowBody>
											<StyledTableRowBody>
												<StyledTableCellBody>{responseData.route.startDate}</StyledTableCellBody>
												<StyledTableCellBody>{responseData.route.entryTime}</StyledTableCellBody>
											</StyledTableRowBody>
										</TableBody>
									</Table>
								</TableContainer>
							</>
						</div>
					)}

					<Box className={classes.boxAction}>
						<Button className={classes.buttonAction} fullWidth onClick={() => handleReturn()}>
							{'Escanear otro código'}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}

export default SuccessScanner
