import { FC } from 'react'
import { Box, Button, Container, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Plane from '../assets/img/icons/plane.svg'
import StreetRoute from '../assets/img/icons/street-route.svg'
import { Link } from 'react-router-dom'
import Home from '../assets/img/homeBgV2.png'
import { useNotification } from '../hooks/useNotification'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxCover: {
			alignItems: 'center',
			backgroundImage: `url(${Home})`,
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			display: 'flex',
			height: 'calc(100vh)',
			justifyContent: 'center',
			// marginTop: 80,
			width: '100%',
			// backgroundColor: '#134288',
			// backgroundBlendMode: 'multiply',
		},
		boxContent: {
			margin: '0 auto',
			width: 500,
		},
		boxContentMob: {
			width: '100%',
		},
		itemFlex: {
			'display': 'flex',
			'alignItems': 'center',
			'border': '3px solid white',
			'borderRadius': '100px 100px 100px 100px',
			'margin': '1.5rem 0',
			'&:hover, &:focus': {
				border: '3px solid #F28F16',
				borderRadius: '100px 100px 100px 100px',
			},
		},
		boxImage: {
			'alignItems': 'center',
			'background': '#FFFFFF',
			'borderRadius': '100px 0 0 100px',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'display': 'flex',
			'height': 120,
			'justifyContent': 'center',
			'width': 220,
			'& img': {
				height: 100,
				width: '100%',
			},
		},
		boxImageMob: {
			'alignItems': 'center',
			'background': '#FFFFFF',
			'borderRadius': '100px 0 0 100px',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'display': 'flex',
			'height': 100,
			'justifyContent': 'center',
			'width': 220,
			'& img': {
				height: 100,
				width: '100%',
			},
		},
		customButton: {
			'background': '#D0D7E2',
			'borderRadius': '0 100px 100px 0',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'color': '#2A4C9C',
			'fontFamily': 'Lato-Regular',
			'fontSize': 30,
			'height': 120,
			'&:hover, &:focus': {
				background: '#F7F7F7',
				color: '#F28F16',
			},
		},
		customButtonMob: {
			'background': '#D0D7E2',
			'borderRadius': '0 100px 100px 0',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'color': '#2A4C9C',
			'fontFamily': 'Lato-Regular',
			'fontSize': 22,
			'height': 100,
			'&:hover, &:focus': {
				background: '#F7F7F7',
			},
		},
		fs50: {
			fontSize: '50px !important',
		},
		fs65: {
			fontSize: '65px !important',
		},
	})
)

const HomePage: FC = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [handleNotification] = useNotification()

	return (
		<Box className={classes.boxCover}>
			<Container>
				<Box className={isMobile ? classes.boxContentMob : classes.boxContent}>
					<Grid container alignItems="center">
						<Grid item xs={12} md={12} className={classes.itemFlex}>
							<Box className={isMobile ? classes.boxImageMob : classes.boxImage}>
								<img alt={'Plane'} src={Plane} />
							</Box>
							<Button
								fullWidth
								className={isMobile ? classes.customButtonMob : classes.customButton}
								endIcon={<ChevronRightIcon className={isMobile ? classes.fs50 : classes.fs65} />}
								onClick={() => handleNotification('Función no disponible temporalmente', 'warning')}
							>
								{'Aeropuerto'}
							</Button>
						</Grid>
						<Grid item xs={12} md={12} className={classes.itemFlex}>
							<Box className={isMobile ? classes.boxImageMob : classes.boxImage}>
								<img alt={'Street Route'} src={StreetRoute} />
							</Box>
							<Button
								className={isMobile ? classes.customButtonMob : classes.customButton}
								component={Link}
								endIcon={<ChevronRightIcon className={isMobile ? classes.fs50 : classes.fs65} />}
								fullWidth
								href={'/new-route'}
								to={'/new-route'}
							>
								{'Rutas'}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}

export default HomePage
