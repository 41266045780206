import { Box, Button, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import SendIcon from '@material-ui/icons/Send'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { putPassengersExcel, getShift } from '../providers/Private/Provider'
import Files from '../components/NewRoutes/Passengers/Files'
import Loading from '../components/Loading/Loading'
import { useNotification } from '../hooks/useNotification'

const useStyles = makeStyles(() =>
	createStyles({
		boxContent: {
			minHeight: 'calc(100vh - 155px)',
		},
		boxHeaderRoute: {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		gridHeaderWrong: {
			marginTop: '2%',
		},
		wrongTitle: {
			color: '#FF5E05',
			textTransform: 'uppercase',
			fontWeight: 600,
			fontFamily: 'Lato-Regular',
			fontStyle: 'normal',
			fontSize: '20px',
		},
		wrongSubTitle: {
			fontFamily: 'Lato-Bold',
			fontStyle: 'normal',
			fontSize: '18px',
			color: 'red',
		},
		buttonAction: {
			borderRadius: 20,
			fontFamily: 'Lato-Bold',
			fontSize: 16,
			height: 49,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonCancel: {
			'color': '#000000',
			'backgroundColor': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#CDCDCD',
				color: '#000000',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonValidate: {
			'color': '#FFFFFF',
			'backgroundColor': '#F28F15',
			'&:hover': {
				backgroundColor: '#F28F15',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		spaceContainer: {
			padding: '1.5rem 0',
		},
		labelName: {
			color: '#4F4F4F',
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			magin: 0,
		},
	})
)

const WrongPassengersFilePage = props => {
	const id = props.match.params.id
	const [errorMessage, setErrorMessage] = useState(props.location.state.data)
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [file, setFile] = useState('')
	const [shiftData, setShiftData] = useState({ name: '', store: '' })
	const [loading, setLoading] = useState(true)
	const [isOk, setIsOk] = useState(false)
	const [handleNotification] = useNotification()

	let passengersFile = new FormData()

	useEffect(() => {
		getShift(id).then(response => {
			if (response && response.success) {
				const { name, store } = response.data
				setShiftData({ name: name, store: store.name })
				setLoading(false)
			}
		})
	}, [])

	const handleSubmit = e => {
		e.preventDefault()
		setSendingData(true)
		passengersFile.append('excel', file)
		putPassengersExcel(id, passengersFile)
			.then(response => {
				if (response) {
					const { success, message } = response
					if (success) {
						handleNotification(message, 'success')
						setIsOk(true)
					} else {
						handleNotification(message, 'error')
						setErrorMessage('Ha ocurrido un error')
						setSendingData(false)
					}
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.error(err)
			})
	}

	if (isOk) {
		return <Redirect push to={{ pathname: `/new-route/${id}/resume` }} />
	}

	if (loading) {
		return <Loading />
	}

	return (
		<Box className={classes.boxContent}>
			<Grid>
				<Grid item xs={12} md={6}>
					<Grid container alignItems="center">
						<Grid item xs={12} md={6}>
							<h1 className={'ct-light-black ff-LatoRegular fs25 tt-upper'}>{'Mis Rutas'}</h1>
						</Grid>
						<Grid item xs={12} md={6}>
							<h1 className={classes.wrongTitle}>{'Corregir Información'}</h1>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={12} md={6}>
						<Box className={classes.boxHeaderRoute}>
							<Grid container>
								<Grid item xs={12} md={6}>
									<p className={classes.labelName}>{shiftData.name}</p>
								</Grid>
								<Grid item xs={12} md={6} style={{ textAlign: isMobile ? 'start' : 'center' }}>
									<p className={classes.labelName}>{shiftData.store}</p>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} className={classes.gridHeaderWrong}>
						<p className={classes.wrongTitle}>{'INFORMACIÓN MAL INGRESADA'}</p>
						<p className={classes.wrongSubTitle}>{errorMessage ? errorMessage : ''}</p>
					</Grid>
				</Grid>
			</Grid>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2} className={classes.spaceContainer}>
					<Files file={file} setFile={setFile} type={0} />
				</Grid>
				<Grid alignItems="center" container justify="space-between" spacing={2} className={classes.spaceContainer}>
					<Grid item xs={12} md={3}>
						<Button
							classes={{ root: classes.buttonAction }}
							className={classes.buttonCancel}
							color="primary"
							component={Link}
							fullWidth
							startIcon={<TransitEnterexitIcon />}
							to={'/new-route'}
							variant="contained"
						>
							{'Volver'}
						</Button>
					</Grid>
					<Grid item xs={12} md={3}>
						<Button
							classes={{ root: classes.buttonAction }}
							className={classes.buttonValidate}
							color="primary"
							disabled={sendingData || file === ''}
							endIcon={<SendIcon />}
							fullWidth
							type={'submit'}
							variant="contained"
						>
							{'Validar'}
							{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default WrongPassengersFilePage
