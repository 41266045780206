import {
	createStyles,
	makeStyles,
  Button,
  CircularProgress
} from '@material-ui/core'

const useStyles = makeStyles(() =>
	createStyles({
    customButton: {
      backgroundColor: '#F28F16',
      borderColor: '#FFFFFF',
      borderRadius: 30,
      borderStyle: 'solid',
      borderWidth: '2px',
      color: '#FFFFFF',
      fontFamily: 'Lato-Bold',
      fontSize: 18,
      height: 60,
      margin: '2rem 0',
      textAlign: 'center',
      textTransform: 'uppercase',
      transition: '0.3s',
      '&:hover': {
        backgroundColor: '#FFFFFF',
        borderColor: '#113047',
        color: '#113047',
        cursor: 'pointer',
        opacity: '0.8',
      },
      "&:disabled": {
        backgroundColor: '#FFFFFF',
        borderColor: '#113047',
        color: '#113047',
        opacity: '0.8'
      },
    },
    buttonProgress: {
      color: '#113047',
      left: '50%',
      marginLeft: -12,
      marginTop: -12,
      position: 'absolute',
      top: '50%'
    },
	})
)

const ButtonAction = ({ handleSubmit, label, sendingData }) => {
	const classes = useStyles()

	return (
    <Button
      className={`${classes.customButton}`}
      color="primary"
      disabled={sendingData}
      fullWidth
      onClick={handleSubmit}
      type="submit"
      variant="contained"
    >
      {label}
      {sendingData && <CircularProgress size={28} className={classes.buttonProgress} />}
    </Button>
	)
}

export default ButtonAction
