import { response } from './Client'

export const postLogin = (data: IUser) => {
	const options = {
		method: 'POST',
		url: `/login`,
		data,
	}
	return response(options)
}

export const postRegister = (data: IUserRegister) => {
	const options = {
		method: 'POST',
		url: `/register`,
		data,
	}
	return response(options)
}

export const recoveryPassword = (data: IUserRecoveryPassword) => {
  const options = {
    method: 'PUT',
    url: `/users/forget`,
    data,
  }
  return response(options)
}

export const restorePassword = (data: IUserRestorePassword, token: string) => {
  const options = {
    method: 'PUT',
    url: `/users/restore?token=${token}`,
    data,
  }
  return response(options)
}