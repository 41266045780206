import { Box, Button, CircularProgress, createStyles, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, withStyles } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useNotification } from '../../hooks/useNotification'
import { getCompanies } from '../../providers/Private/Provider'

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#595B62',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		emptyBox: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
			width: '100%',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		editHour: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
	})
)

export const ListCompanies = ({ setCompanyEdit, setComponentValue, handleSetSelected }) => {
	const classes = useStyles()
	const [companies, setCompanies] = useState([])
	const [loader, setLoader] = useState(true)
	const [handleNotification] = useNotification()

	useEffect(() => {
		getCompanies()
			.then(response => {
				if (response && response.success) {
					setCompanies(
						response.data.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.finally(() => {
				setLoader(false)
			})
	}, [])

	const editCompany = company => {
		setCompanyEdit(company)
		handleSetSelected('addCompany')
		setComponentValue(1)
	}

	const handleSubmit = e => {
		e.preventDefault()
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={34} className={classes.customProgress} />
			</Box>
		)
	}

	return companies && companies.length > 0 ? (
		<form onSubmit={handleSubmit} autoComplete="off">
			<h2 className={'ct-black ff-LatoRegular fs20'}>{'Lista de Compañías'}</h2>
			<TableContainer component={Paper}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<StyledTableCellHead>{'Nombre'}</StyledTableCellHead>
							<StyledTableCellHead>{'RUT'}</StyledTableCellHead>
							<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
							<StyledTableCellHead></StyledTableCellHead>
						</TableRow>
					</TableHead>
					<TableBody>
						{companies.map((company: any, index) => (
							<StyledTableRowBody key={index}>
								<StyledTableCellBody>{company.name ? company.name : 'Sin Nombre'}</StyledTableCellBody>
								<StyledTableCellBody>{company.rut ? company.rut : 'Sin RUT'}</StyledTableCellBody>
								<StyledTableCellBody>{company.phoneNumber ? `+56 ${company.phoneNumber}` : 'Sin Télefono'}</StyledTableCellBody>
								<StyledTableCellBody>
									<Button
										classes={{ root: classes.buttonTableAction }}
										className={classes.editHour}
										color="primary"
										fullWidth
										disabled={company.name ? false : true}
										variant="contained"
										onClick={() => editCompany(company)}
									>
										{'Editar'}
									</Button>
								</StyledTableCellBody>
							</StyledTableRowBody>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</form>
	) : (
		<Box className={classes.emptyBox}>
			<h1 className={'ct-black ff-LatoRegular fs20'}>No hay compañías disponibles</h1>
		</Box>
	)
}
