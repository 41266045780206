import axios from 'axios'

const client = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	timeout: 15000,
	headers: {
		'Content-Type': 'application/json',
		//Authorization: `APIKEY`,
	},
})

export const response = async (options: any) => {
	return await client(options)
		.then(response => {
			const { data } = response
			if (data.success === true) {
				localStorage.setItem('token', data.token)
				localStorage.setItem('userId', data.id)
				localStorage.setItem('role', data.role)
			}
			return data
		})
		.catch(error => {
			console.error('error', error)
			return false
		})
}

export default client
