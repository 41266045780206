import 'date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import es from 'date-fns/locale/es'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		customTextField: {
			'& .MuiOutlinedInput-input ': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
	})
)

const FrequencyTime = ({ setSelectedDate, selectedDate, disabled = false }: FrequencyTimeProps) => {
	const classes = useStyles();
	
	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date)
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
			<DatePicker
				autoOk
				disabled={disabled}
				className={classes.customTextField}
				disablePast
				format={'dd/MM/yyyy'}
				fullWidth
				inputVariant="outlined"
				onChange={handleDateChange}
				value={selectedDate}
				emptyLabel="Seleccione fecha de inicio"
				variant="inline"
			/>
		</MuiPickersUtilsProvider>
	)
}

export default FrequencyTime
