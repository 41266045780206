import { makeStyles, createStyles, Theme, Box, Grid, Button } from '@material-ui/core'
import { useState, Fragment } from 'react'
import QrReader from 'react-qr-reader'
import SuccessScanner from '../components/Qr/SuccessScanner'
import { useNotification } from '../hooks/useNotification'
import { getCollaborator } from '../providers/Private/Provider'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			background: '#EFF1F3',
			height: 'calc(100vh - 80px)',
			paddingTop: '80px',
		},
		customContainer: {
			height: '100%',
		},
		customItem: {
			padding: '0 24px',
		},
		labelText: {
			textAlign: 'center',
			fontFamily: 'Lato-Regular',
			fontWeight: 600,
			marginBottom: 30,
		},
		boxAction: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1.5rem 0',
		},
		buttonAction: {
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 120,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
	})
)

const QrVerification = () => {
	const [response, setResponse] = useState<any>(null)
	const [correctQr, setCorrectQr] = useState(false)
	const [error, setError] = useState(false)
	const classes = useStyles()
	const [enableButton, setEnableButton] = useState(true)
	const [handleNotification] = useNotification()

	const handleScan = data => {
		if (data) {
			setResponse(data)
			handleSubmit()
		}
	}

	const handleSubmit = () => {
		setCorrectQr(true)
		if (response !== undefined && response !== null) {
			getCollaborator(response)
				.then(response => {
					if (response && response.success) {
						setResponse(response.data)
						setCorrectQr(true)
					} else {
						handleNotification('Ha ocurrido un error', 'error')
					}
				})
				.catch(err => {
					setError(true)
					handleNotification('Ha ocurrido un error', 'error')
				})
		} else {
			setError(true)
		}
	}

	const handleError = err => {
		console.error(err)
	}

	const handleReturn = () => {
		setResponse(null)
		setCorrectQr(false)
	}

	return (
		<Box className={classes.contentWrapper}>
			<Grid container justify="center" alignItems="center" className={classes.customContainer}>
				<Grid item xs={12} md={4} className={classes.customItem}>
					{correctQr ? (
						<SuccessScanner data={response} handleReturn={handleReturn} />
					) : (
						<Fragment>
							<p className={classes.labelText}>{'Escanear código QR que se encuentra en el correo de ruta:'}</p>
							<QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Box>
	)
}

export default QrVerification
