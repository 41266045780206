import { FC, useState, useEffect, Fragment } from 'react'
import { Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tab, Tabs, IconButton } from '@material-ui/core'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import { useNotification } from '../hooks/useNotification'
import ExtraHourDialog from '../components/Dialogs/Shifts/ExtraHourDialog'

import { getShift, cancelRoute } from '../providers/Private/Provider'

import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import DeleteIcon from '@material-ui/icons/Delete'

import moment from 'moment'
const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		pageTitle: {
			color: '#113047',
			fontFamily: 'Lato-Regular',
			fontSize: 22,
			margin: '0',
		},
		pageSubTitle: {
			color: '#113047',
			fontFamily: 'Lato-Regular',
			fontSize: 20,
			margin: '0',
			paddingTop: '2rem',
			paddingBottom: '2rem',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			minWidth: 120,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonEdit: {
			'backgroundColor': '#7E9BF2',
			'&:hover': {
				backgroundColor: '#7E9BF2',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		overTime: {
			'backgroundColor': '#5E75B5',
			'color': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#5E75B5',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		boxBodyResume: {
			minHeight: 'calc(100vh - 155px)',
			padding: '1.5rem 0',
			paddingTop: '5%',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		boxEmpty: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 300px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxHeaderRoute: {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		buttonValidate: {
			'backgroundColor': '#0D1373',
			'borderRadius': 20,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonBack: {
			'backgroundColor': '#0D1373',
			'borderRadius': 20,
			'color': '#FFFFFF',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'fontFamily': 'Lato-Regular',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#CDCDCD',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 323px)',
			justifyContent: 'center',
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		table: {
			minWidth: 700,
			textAlign: 'center',
			width: '100%',
		},
		customLabel: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			marginBottom: '5px',
		},
		customContainer: {
			padding: '4rem 0',
		},
		customTabsIndicator: {
			display: 'none',
		},
		customTab: {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		activeTab: {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		buttonDelete: {
			border: '2px solid rgba(0, 0, 0, 0.54)',
			color: 'rgba(0, 0, 0, 0.54)',
		},
		buttonCancel: {
			'backgroundColor': '#6C6C6C',
			'color': '#FFFFFF',
			'&:hover': {
				opacity: '0.8',
				backgroundColor: '#938f8f',
			},
		},
	})
)

const EditShiftPage: FC = (props: any) => {
	const { shiftId } = props.match.params
	const classes = useStyles()
	const [loader, setLoader] = useState(true)
	const [extraHourDialog, setExtraHourDialog] = useState(false)
	const [shift, setShift] = useState<any>()
	const [route, setRoute] = useState<any>({})
	const [shiftNotFound, setShiftNotFound] = useState(true)

	const [collaborators, setCollaborators] = useState<any>([])
	const [startDate, setStartDate] = useState<Date | string>(new Date())
	const [exitTime, setExitTime] = useState(new Date())
	const [valueTab, setValueTab] = useState(0)
	const [handleNotification] = useNotification()
	const [minDay, setMinDay] = useState()
	const [entryData, setEntryData] = useState([])
	const [exitData, setExitData] = useState([])

	const getShiftById = () => {
		getShift(shiftId)
			.then((response: any) => {
				if (response && response.success) {
					setShiftNotFound(false)
					const { routes } = response.data
					routes.filter(item => item.startDate)
					routes
						.sort((a, b) => new Date(`2020-01-01T${a.entryTime}`).getTime() - new Date(`2020-01-01T${b.entryTime}`).getTime())
						.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
						.map(i => i.startDate)
					setShift(response.data)
					let entry = routes.filter(item => item.serviceType === 0)
					let exit = routes.filter(item => item.serviceType === 1)
					setEntryData(entry)
					setExitData(exit)
					let newArr = routes.filter(item => item.startDate).sort((a, b) => a.startDate - b.startDate)
					setMinDay(newArr[0].startDate)
				} else {
					setShiftNotFound(true)
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error', 'error')
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const setServiceTypeText = (service: number) => {
		switch (service) {
			case 0:
				return 'INGRESO'
			case 1:
				return 'REGRESO'
			case 2:
				return 'INGRESO - REGRESO'
			default:
				return 'No Aplica'
		}
	}

	const getServiceStatus = (value: any) => {
		switch (value) {
			case 0:
				return 'No Iniciado'
			case 1:
				return 'Iniciado'
			case 2:
				return 'Completado'
			case 3:
				return 'Finalizado'
			default:
				return 'Desconocido'
		}
	}

	const handleCancelRoute = (routeId: string) => {
		cancelRoute(routeId)
			.then(response => {
				if (response && response.success) {
					handleNotification('Ruta cancelada con éxito', 'success')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.error('error', err)
				handleNotification('Ocurriú un error con la cancelación', 'errro')
			})
	}

	useEffect(() => {
		if (entryData.length > 0 || exitData.length > 0) {
			setValueTab(entryData.length > 0 ? 0 : 1)
		}
	}, [entryData, exitData])

	const formatDate = (date: any) => {
		return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
	}

	const setFormatTime = (time: any) => {
		return format(time, 'HH:mm')
	}

	const handleOpenExtraHourDialog = (data: any) => event => {
		setCollaborators(data.visits)
		setExitTime(new Date(`${data.startDate} ${data.entryTime}`))
		setStartDate(data.startDate)
		setRoute(data)
		setExtraHourDialog(!extraHourDialog)
	}

	const handleExtraHourDialog = () => event => {
		setExtraHourDialog(!extraHourDialog)
		getShiftById()
	}

	const handleTabChange = (event, newValue) => {
		if (entryData.length > 0 && exitData.length > 0) {
			setValueTab(newValue)
		} else if (exitData.length > 0) {
			setValueTab(1)
		} else if (entryData.length > 0) {
			setValueTab(0)
		}
	}

	useEffect(() => {
		getShiftById()
	}, [])

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}
	if (shiftNotFound) {
		return (
			<Box className={classes.boxProgress}>
				<Grid container justify="center" spacing={8}>
					<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
						<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
					</Grid>
					<Grid item xs={12} md={4}>
						<Button className={classes.buttonBack} color="primary" component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
							{'Volver'}
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	}

	return shift !== [] ? (
		<Box className={classes.boxBodyResume}>
			<h1 className={classes.pageTitle}>{'Editar Turno'}</h1>
			<Grid container alignItems="center">
				<Grid item xs={12} md={6}>
					<Box className={classes.boxHeaderRoute}>
						<Grid container>
							<Grid container style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '1% 0%' }}>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Ruta'}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sucursal'}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Centro de Costo'}</p>
								</Grid>
							</Grid>
							<Grid container style={{ paddingBottom: '1%' }}>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.name}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.store.name}</p>
								</Grid>
								<Grid item xs={12} md={4}>
									<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.costCenter.name}</p>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			{shift.routes.length > 0 ? (
				<Fragment>
					<h1 className={classes.pageSubTitle}>{'Rutas Disponibles'}</h1>
					<Tabs
						variant="fullWidth"
						value={valueTab}
						onChange={handleTabChange}
						classes={{
							indicator: classes.customTabsIndicator,
						}}
					>
						{entryData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 0 ? classes.activeTab : ''} label="Ingreso" />}
						{exitData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 1 ? classes.activeTab : ''} label="Regreso" />}
					</Tabs>
					<Grid container>
						<Grid item xs={12} md={12}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<StyledTableCellHead>{'Fecha'}</StyledTableCellHead>
											<StyledTableCellHead>{'Tipo'}</StyledTableCellHead>
											<StyledTableCellHead>{'Hora'}</StyledTableCellHead>
											<StyledTableCellHead>{'Estado Servicio'}</StyledTableCellHead>
											<StyledTableCellHead>{'Pasajeros'}</StyledTableCellHead>
											<StyledTableCellHead colSpan={3}>{'Acciones'}</StyledTableCellHead>
										</TableRow>
									</TableHead>
									<TableBody>
										{shift.routes.map(
											(route: any, index: number) =>
												route.serviceType === valueTab &&
												route.visits.length > 0 && (
													<Fragment key={index}>
														<StyledTableRowBody>
															<StyledTableCellBody component="th" scope="row" align="center">
																{formatDate(route.startDate)}
															</StyledTableCellBody>
															<StyledTableCellBody align="center">{setServiceTypeText(route.serviceType)}</StyledTableCellBody>
															<StyledTableCellBody align="center">{route.entryTime?.slice(0, -3)}</StyledTableCellBody>
															<StyledTableCellBody align="center">{getServiceStatus(route.plan?.tours[0]?.statusOrder)}</StyledTableCellBody>
															<StyledTableCellBody align="center">{route.visits.length}</StyledTableCellBody>
															<StyledTableCellBody align="right">
																{valueTab === 1 && (
																	<Button
																		classes={{ root: classes.buttonTableAction }}
																		className={classes.overTime}
																		color="primary"
																		onClick={handleOpenExtraHourDialog(route)}
																		variant="contained"
																	>
																		{'Hora Extra'}
																	</Button>
																)}
															</StyledTableCellBody>
															<StyledTableCellBody>
																{route.visits.length > 0 ? (
																	<Button
																		classes={{ root: classes.buttonTableAction }}
																		className={classes.buttonEdit}
																		color="primary"
																		component={Link}
																		to={{ pathname: `/my-routes/${shiftId}/edit/route/${route.id}` }}
																		variant="contained"
																	>
																		{'Editar Ruta'}
																	</Button>
																) : (
																	'Sin pasajeros'
																)}
															</StyledTableCellBody>
															<StyledTableCellBody>
																{/* <IconButton aria-label="delete" classes={{ root: classes.buttonDelete }} onClick={() => handleCancelRoute(route.id)}>
																	<DeleteIcon />
																</IconButton> */}
																<Button
																	classes={{ root: classes.buttonTableAction }}
																	className={classes.buttonCancel}
																	color="primary"
																	onClick={() => handleCancelRoute(route.id)}
																	variant="contained"
																>
																	{'Cancelar Ruta'}
																</Button>
															</StyledTableCellBody>
														</StyledTableRowBody>
													</Fragment>
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					<ExtraHourDialog
						handleDialog={handleExtraHourDialog()}
						open={extraHourDialog}
						route={route}
						setRoute={setRoute}
						collaborators={collaborators}
						setCollaborators={setCollaborators}
						exitTime={exitTime}
						setExitTime={setExitTime}
						startDate={startDate}
						setStartDate={setStartDate}
						minDay={minDay}
					/>
				</Fragment>
			) : (
				<Box className={classes.boxEmpty}>
					<Grid container>
						<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
							<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Sin rutas disponibles'}</p>
						</Grid>
					</Grid>
				</Box>
			)}
			<Grid container alignItems="center" justify="space-between" className={classes.customContainer}>
				<Grid item xs={12} md={4}>
					<Button className={classes.buttonBack} color="primary" component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
						{'Volver'}
					</Button>
				</Grid>
			</Grid>
		</Box>
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
				</Grid>
			</Grid>
		</Box>
	)
}

export default EditShiftPage
