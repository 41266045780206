import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const ProfileSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'Es necesario un nombre más largo')
		.max(200, 'El nombre es demasiado largo')
		.required('Debes ingresar un nombre')
		.nullable(),
	lastName: Yup.string()
		.min(2, 'Es necesario un apellido más largo')
		.max(200, 'El apellido es demasiado largo')
		.required('Debes ingresar un apellido')
		.nullable(),
	phoneNumber: Yup.string()
		.matches(phoneRegExp, 'El teléfono no es valido')
		.min(9, 'El teléfono debe tener 9 números')
		.required('Debes ingresar un teléfono')
		.nullable(),
	email: Yup.string()
		.email('E-mail inválido')
		.required('Debes ingresar un e-mail')
		.nullable()
})
