import {
	Container,
	createStyles,
	Grid,
	makeStyles,
	TextField,
	withStyles,
	Theme,
	TableCell,
	TableRow,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	Paper,
	Box,
	Button,
	CircularProgress,
	Collapse,
	Fab,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getStores, postGetCCByStore, postGetVisitServices, getReportExcelVisits } from '../../providers/Private/Provider'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

//time manager
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import es from 'date-fns/locale/es'
import { useNotification } from '../../hooks/useNotification'

const es_ES = require('date-fns/locale/es')
const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
			textTransform: 'uppercase',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
			fontFamily: 'Lato-Regular',
		},
		customLabel: {
			marginBottom: '5px',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				padding: '14px',
			},
		},
		failMessage: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 600px)',
			justifyContent: 'center',
			alignContent: 'center',
			margin: 'auto',
		},
		customTabsIndicator: {
			display: 'none',
		},
		customTab: {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		activeTab: {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		customProgress: {
			color: '#0D1373',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		editRoute: {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		custCell: {
			padding: '0 !important',
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 760px)',
			justifyContent: 'center',
		},
		buttonShift: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		boxHeaderRoute: {
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			margin: '2rem 0 2rem 0',
			padding: '0 1rem',
		},
		downloadButton: {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		toBottom: {
			position: 'absolute',
			right: '9%',
			bottom: '10%',
			backgroundColor: '#0D1373',
			color: '#FFFFFF',
		},
	})
)

export const SearchVisit = () => {
	const classes = useStyles()
	let today = new Date()
	let oneWeekAgo = new Date(today.getTime() - 168 * 60 * 60 * 1000)
	const [stores, setStores] = useState([])
	const [storeSelected, setStoreSelected] = useState<any>({})
	const [responseData, setResponseData] = useState<any>([])
	const [filters, setFilters] = useState({ startDate: oneWeekAgo, endDate: today })
	const [loader, setLoader] = useState(false)
	const [costCenters, setCostCenters] = useState([])
	const [costCenterSelected, setCostCenterSelected] = useState('')
	const [openPassenger, setOpenPassenger] = useState()
	const [handleNotification] = useNotification()

	const bottomArchor = useRef<HTMLDivElement>(null)

	const scrollToBottom = () => bottomArchor.current?.scrollIntoView({ behavior: 'smooth' })

	useEffect(() => {
		getStores().then(response => {
			if (response && response.success) {
				setStores(response.data)
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
	}, [])

	const handleStoreSelect = id => {
		if (id) {
			setStoreSelected(stores.filter((store: any) => store.id === id)[0])
			postGetCCByStore({ id: id }).then(response => {
				if (response && response.success) {
					setCostCenters(response.data)
				}
			})
		}
	}

	const handleCCSelect = id => {
		if (id) {
			setCostCenterSelected(id)
		}
	}

	const formatDateToSeach = (date: any) => {
		return format(date, 'yyyy-MM-dd', { locale: es_ES }).toString()
	}

	const handleSearchStore = () => {
		if (storeSelected?.name) {
			setLoader(true)
			const { startDate, endDate } = filters
			const { id } = storeSelected
			const setPostData = {
				store: id,
				costCenter: costCenterSelected,
				startDate: formatDateToSeach(startDate),
				endDate: formatDateToSeach(endDate),
			}

			postGetVisitServices(setPostData)
				.then(response => {
					if (response && response.success) {
						const { data } = response
						setResponseData(data)
					} else {
						handleNotification('Ha ocurrido un error', 'error')
					}
				})
				.finally(() => setLoader(false))
		}
	}

	const handleDateChange = (date, type) => {
		if (type === 0) {
			if (format(date, 'yyyy-MM-dd').toString() > format(filters.endDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, startDate: date })
			}
		} else if (type === 1) {
			if (format(date, 'yyyy-MM-dd').toString() < format(filters.startDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, endDate: date })
			}
		}
	}

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy').toString()
	}

	const handleClickExpandPassenger = (value: any) => {
		setOpenPassenger(openPassenger === value ? false : value)
	}

	const handleDownloadExcel = () => {
		if (storeSelected?.name) {
			const { startDate, endDate } = filters
			const { id } = storeSelected
			const setBody = {
				store: id,
				costCenter: costCenterSelected,
				startDate: formatDateToSeach(startDate),
				endDate: formatDateToSeach(endDate),
			}
			getReportExcelVisits(setBody, 'visits').catch(error => console.error('ERROR_EXCEL', error))
		}
	}

	const getServiceStatus = (value: any) => {
		switch (value) {
			case 0:
				return 'No Iniciado'
			case 1:
				return 'Iniciado'
			case 2:
				return 'Completado'
			case 3:
				return 'Finalizado'
			default:
				return 'Desconocido'
		}
	}

	return (
		<Container maxWidth={'xl'}>
			<div className={classes.container}>
				<h2 className={'ct-black ff-LatoRegular fs25'}>{'Buscar Viajes'}</h2>
				<Grid container>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Escriba el nombre de la sucursal'}</p>
							<Autocomplete
								classes={{ option: classes.customMenuItem }}
								getOptionLabel={option => `${option['name']}`}
								getOptionSelected={option => option['id']}
								id="stores"
								options={stores}
								noOptionsText={'Sin coincidencias'}
								onChange={(event, value: any) => handleStoreSelect(value?.id)}
								renderInput={params => (
									<TextField
										{...params}
										name="stores"
										classes={{
											root: classes.customAutocompleteField,
										}}
										required={true}
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Escriba el nombre del centro de costo'}</p>
							<Autocomplete
								classes={{ option: classes.customMenuItem }}
								getOptionLabel={option => `${option['name']}`}
								getOptionSelected={option => option['id']}
								id="costCenters"
								options={costCenters}
								noOptionsText={'Sin coincidencias'}
								onChange={(event, value: any) => handleCCSelect(value?.id)}
								renderInput={params => (
									<TextField
										{...params}
										name="costCenters"
										classes={{
											root: classes.customAutocompleteField,
										}}
										required={true}
										variant="outlined"
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} xs={12} md={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
							<Grid item xs={12} md={5}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Inicio'}</p>
								<DatePicker
									autoOk
									className={classes.customTextField}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={date => handleDateChange(date, 0)}
									value={filters.startDate}
									emptyLabel="Seleccione fecha de inicio"
									variant="inline"
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Fin'}</p>
								<DatePicker
									autoOk
									className={classes.customTextField}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={date => handleDateChange(date, 1)}
									value={filters.endDate}
									emptyLabel="Seleccione fecha de fin"
									variant="inline"
								/>
							</Grid>
						</MuiPickersUtilsProvider>
						<Grid item xs={12} md={2} style={{ marginTop: 'auto', marginBottom: '5px' }}>
							<Button style={{ backgroundColor: '#0D1373', color: '#FFFFFF' }} fullWidth variant="contained" onClick={() => handleSearchStore()}>
								Buscar
							</Button>
						</Grid>
					</Grid>
					{storeSelected?.name !== undefined && responseData.length > 0 && (
						<Grid container spacing={2} className={classes.boxHeaderRoute}>
							<Grid item xs={12} md={4} style={{ paddingTop: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Nombre</p>
								{storeSelected?.name ? storeSelected.name : 'Sin Nombre'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingTop: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Teléfono</p>
								{storeSelected?.phoneNumber ? storeSelected.phoneNumber : 'Sin Numero'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingTop: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>E-mail</p>
								{storeSelected?.emailInCharge ? storeSelected.emailInCharge : 'Sin Email'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingBottom: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Compañía</p>
								{storeSelected?.company?.name ? storeSelected.company.name : 'Sin Compañía'}
							</Grid>
							<Grid item xs={12} md={4} style={{ paddingBottom: '2%' }}>
								<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Dirección</p>
								{`${storeSelected?.address?.street ? storeSelected.address.street : 'Sin Calle'} - ${
									storeSelected?.address?.county?.name ? storeSelected.address.county.name : 'Sin Comuna'
								}`}
							</Grid>
						</Grid>
					)}
					<Grid item xs={12} md={12}>
						{!loader ? (
							responseData.length > 0 ? (
								<>
									<TableContainer component={Paper}>
										<Fab className={classes.toBottom} onClick={scrollToBottom}>
											<ArrowDownwardIcon />
										</Fab>
										<div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyItems: 'center', paddingBottom: '4%' }}>
											<Button
												onClick={() => handleDownloadExcel()}
												classes={{ root: classes.buttonTableAction }}
												className={classes.downloadButton}
												color="primary"
												variant="contained"
											>
												Descargar Todo
											</Button>
										</div>
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<StyledTableCellHead>{'Fecha Inicio'}</StyledTableCellHead>
													<StyledTableCellHead>{'Fecha Fin'}</StyledTableCellHead>
													<StyledTableCellHead>{'Estado del servicio'}</StyledTableCellHead>
													<StyledTableCellHead>{'Pasajeros'}</StyledTableCellHead>
												</TableRow>
											</TableHead>
											<TableBody>
												{responseData.map((visit: any, index) => (
													<>
														<StyledTableRowBody key={index}>
															<StyledTableCellBody>
																{visit?.route?.shift?.startDate ? formatDate(new Date(`${visit.route.shift.startDate}T00:00:00`)) : 'Sin Fecha'}
															</StyledTableCellBody>
															<StyledTableCellBody>
																{visit?.route?.shift?.endDate ? formatDate(new Date(`${visit.route.shift.endDate}T00:00:00`)) : 'Sin Fecha'}
															</StyledTableCellBody>
															<StyledTableCellBody>{visit?.route?.plan ? getServiceStatus(visit.route.plan.tours[0].statusOrder) : 'Sin Estado'}</StyledTableCellBody>
															<StyledTableCellBody>
																<Button
																	classes={{
																		root: classes.buttonSee,
																	}}
																	color="primary"
																	onClick={() => {
																		handleClickExpandPassenger(index)
																	}}
																	endIcon={<ExpandMore />}
																>
																	{'Ver pasajero'}
																</Button>
															</StyledTableCellBody>
														</StyledTableRowBody>
														<StyledTableRowBody>
															<StyledTableCellHead className={classes.custCell} colSpan={7}>
																<Collapse in={openPassenger === index} timeout="auto" unmountOnExit>
																	<Table size="small">
																		<TableHead>
																			<TableRow>
																				<StyledTableCellHead>{'Rut'}</StyledTableCellHead>
																				<StyledTableCellHead>{'Nombre'}</StyledTableCellHead>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			<StyledTableRowBody>
																				<StyledTableCellBody>{`${visit.address.collaborator.rut}-${visit.address.collaborator.dv}`}</StyledTableCellBody>
																				<StyledTableCellBody>{visit.address.collaborator.fullName}</StyledTableCellBody>
																			</StyledTableRowBody>
																		</TableBody>
																	</Table>
																</Collapse>
															</StyledTableCellHead>
														</StyledTableRowBody>
													</>
												))}
											</TableBody>
										</Table>
										<div ref={bottomArchor}>
											{/* <Button classes={{ root: classes.buttonTableAction }} className={classes.downloadButton} color="primary" variant="contained">
												Descargar Todo
											</Button> */}
										</div>
									</TableContainer>
								</>
							) : (
								<Box className={classes.boxLoading}>
									<h1 className={'ct-black ff-LatoRegular fs20'}>{'No hay visitas disponibles'}</h1>
								</Box>
							)
						) : (
							<div style={{ width: '100%', textAlign: 'center', paddingTop: '5%' }}>
								<CircularProgress />
							</div>
						)}
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}
