import { response } from './Client'

export const getCounties = () => {
	const options = {
		url: `/counties`,
	}
	return response(options)
}

export const getCollaboratorLocation = (id: string) => {
	const options = {
		url: `/collaborators/${id}/track`,
	}
	return response(options)
}
