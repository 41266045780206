import { useState } from 'react'
import 'date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createStyles, makeStyles, Theme, InputAdornment, Button } from '@material-ui/core'
import es from 'date-fns/locale/es'
import CloseIcon from '@material-ui/icons/Close'
import TodayIcon from '@material-ui/icons/Today'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		customTextField: {
			'& .MuiOutlinedInput-input ': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		calentadarButton: {
			'& :hover': {
				cursor: 'pointer',
			},
		},
	})
)

const DateShiftSearch = ({ setSelectedDate, selectedDate, disabled = false }: FrequencyTimeProps) => {
	const classes = useStyles()

	const [openPicker, setOpenPicker] = useState(false)

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date)
	}

	const handleRemoveSelection = () => {
		setSelectedDate(null)
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
			<DatePicker
				autoOk
				disabled={disabled}
				className={classes.customTextField}
				format={'dd/MM/yyyy'}
				fullWidth
				inputVariant="outlined"
				onChange={handleDateChange}
				value={selectedDate}
				emptyLabel=" - - / - - / - - - - "
				variant="inline"
				defaultValue={null}
				onClose={() => setOpenPicker(false)}
				open={openPicker}
				InputProps={{
					startAdornment: (
						<InputAdornment onClick={() => setOpenPicker(true)} position="start" className={classes.calentadarButton}>
							<TodayIcon />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<Button onClick={() => handleRemoveSelection()}>
								<CloseIcon />
							</Button>
						</InputAdornment>
					),
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}

export default DateShiftSearch
