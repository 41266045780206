import { FC } from 'react'
import { createBrowserHistory } from 'history'
import { Redirect, Router, Switch, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import './assets/scss/style.scss'

// Pages
import RouteLayout from './views/Layout/RouteLayout'
import HomePage from './views/HomePage'
import NewRoutePage from './views/NewRoutePage'
import CreateRoutePage from './views/CreateRoutePage'
import { ExistingRoutePage } from './views/ExistingRoutePage'
import AuthPage from './views/AuthPage'
import MyShiftsPage from './views/MyShiftsPage'
import ResumeRoutePage from './views/ResumeRoutePage'
import OptimizedRoutePage from './views/OptimizedRoutePage'
import EditShiftPage from './views/EditShiftPage'
import EditRoutePage from './views/EditRoutePage'
import { WrongAddressPage } from './views/WrongAddressPage'
import { TracingMapPage } from './views/TracingMapPage'
import WrongPassengersFilePage from './views/WrongPassengersFilePage'
import ProfilePage from './views/ProfilePage'

//user data
import UserContext from './context/UserContext'
import QrVerification from './views/QrVerification'
import Header from './components/Header/Header'
import PublicHeader from './components/Header/PublicHeader'
import { AdministrationPage } from './views/AdministrationPage'
import { ReportsPage } from './views/ReportsPage'
import { WrongMaxTimePage } from './views/WrongMaxTimePage'
import { RouteMapLayout } from './views/Layout/RouteMapLayout'

const hist = createBrowserHistory()

const IndexPage: FC = () => {
	return (
		<>
			<UserContext>
				<SnackbarProvider>
					<Router history={hist}>
						<Switch>
							<RouteLayout fullCover={true} component={HomePage} exact path="/" />
							<Route exact path="/auth">
								<AuthPage />
							</Route>
							<RouteLayout fullCover={false} component={ProfilePage} exact path="/my-profile" />
							<RouteLayout fullCover={false} component={AdministrationPage} exact path="/administration" />
							<RouteLayout fullCover={false} component={ReportsPage} exact path="/reports" />
							<RouteLayout fullCover={true} component={CreateRoutePage} exact path="/new-route" />
							<RouteLayout fullCover={false} component={NewRoutePage} exact path="/new-route/common-route" />
							<RouteLayout fullCover={false} component={NewRoutePage} exact path="/new-route/dynamic-route" />
							<RouteLayout fullCover={false} component={ExistingRoutePage} exact path="/:id/existing-route" />
							<RouteLayout fullCover={false} component={MyShiftsPage} exact path="/my-routes" />
							<RouteLayout fullCover={false} component={EditShiftPage} exact path="/my-routes/:shiftId/edit" />
							<RouteLayout fullCover={false} component={EditRoutePage} exact path="/my-routes/:shiftId/edit/route/:routeId" />
							<RouteLayout fullCover={false} component={ResumeRoutePage} exact path="/new-route/:id/resume" />
							<RouteLayout fullCover={false} component={WrongAddressPage} exact path="/new-route/:id/wrong-address" />
							<RouteLayout fullCover={false} component={WrongMaxTimePage} exact path="/new-route/:id/wrong-max-time" />
							<RouteLayout fullCover={false} component={WrongPassengersFilePage} exact path="/new-route/:id/wrong-passengers-file" />
							<RouteLayout fullCover={false} component={OptimizedRoutePage} exact path="/new-route/:id/optimized-route" />
							<RouteMapLayout fullCover={false} component={TracingMapPage} exact path="/tracing/:id" />
							<Route exact path="/qr-verification">
								<PublicHeader />
								<QrVerification />
							</Route>

							<RouteLayout fullCover={false} component={HomePage} exact path="*" />
							<Route path="*">
								<Redirect to="/" />
							</Route>
						</Switch>
					</Router>
				</SnackbarProvider>
			</UserContext>
		</>
	)
}

export default IndexPage
