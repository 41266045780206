import { FC, useState, useEffect, Fragment, useRef } from 'react'
import { Box, Button, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, ButtonGroup } from '@material-ui/core'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import { getShift, getRoute, putRoute } from '../providers/Private/Provider'
import DeleteIcon from '@material-ui/icons/Delete'
import { Form, Formik } from 'formik'
import { EditRouteSchema } from '../validations/EditRouteSchema'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MainColortheme from '../components/utils/MainColorTheme'
//time manager
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import es from 'date-fns/locale/es'
import Schedule from '../components/NewRoutes/Schedule/Schedule'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { Link } from 'react-router-dom'
// Dialogs
import EditHourPassengerDialog from '../components/Dialogs/EditHourPassengerDialog'
import ChangeRoutePassengerDialog from '../components/Dialogs/ChangeRoutePassengerDialog'
import RemovePassengerDialog from '../components/Dialogs/RemovePassengerDialog'
import AddPassengerDialog from '../components/Dialogs/AddPassengerDialog'
import { useNotification } from '../hooks/useNotification'
import { PopoverDetail } from '../components/utils/PopoverDetails'

const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#595B62',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		boxButtonGroup: {
			height: '43px',
		},
		buttonGroup: {
			'borderColor': '#7E7E7E',
			'fontSize': 16,
			'fontFamily': 'Lato-Regular',
			'background': '#0D1373',
			'color': '#FFFFFF',
			'&:hover': {
				background: '#0D1373',
				color: '#FFFFFF',
				opacity: '0.9',
			},
		},
		activeButton: {
			'background': '#0D1373',
			'color': '#FFFFFF',
			'&:hover': {
				background: '#0D1373',
				color: '#FFFFFF',
				opacity: '0.9',
			},
		},
		pageTile: {
			color: '#113047',
			fontFamily: 'Lato-Regular',
			fontSize: 20,
			margin: '0',
			paddingTop: '3rem',
		},
		buttonDelete: {
			border: '2px solid rgba(0, 0, 0, 0.54)',
			color: 'rgba(0, 0, 0, 0.54)',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			minWidth: 120,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		editHour: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		changeRoute: {
			'padding': '2%',
			'backgroundColor': '#8DA7C4',
			'&:hover': {
				backgroundColor: '#8DA7C4',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		editPassenger: {
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		boxBodyResume: {
			minHeight: 'calc(100vh - 155px)',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 155px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxHeaderRoute: {
			alignItems: 'center',
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			marginTop: '2rem',
			padding: '0 1rem',
		},
		buttonValidate: {
			'backgroundColor': '#0D1373',
			'borderRadius': 20,
			'color': '#FFFFFF',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonBack: {
			'backgroundColor': '#0D1373',
			'borderRadius': 20,
			'color': '#FFFFFF',
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'fontFamily': 'Lato-Regular',
			'transition': '0.3s',
			'fontSize': 16,
			'&:hover': {
				//backgroundColor: '#CDCDCD',
				backgroundColor: '#0D1373',

				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonAddPassenger: {
			'backgroundColor': '#FFFFFF',
			'border': '2px solid #7E9BF2',
			'borderRadius': 20,
			'color': '#7E9BF2',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'minWidth': 181,
			'padding': '10px 0px',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				color: '#7E9BF2',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 323px)',
			justifyContent: 'center',
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		table: {
			minWidth: 700,
			textAlign: 'center',
			width: '100%',
		},
		customLabel: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			marginBottom: '5px',
		},
		customContainer: {
			padding: '4rem 0',
		},
		saveButton: {
			'backgroundColor': '#0D1373',
			'color': '#FFFFFF',
			'bottom': '0px',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
	})
)

const EditRoutePage: FC = (props: any) => {
	const { shiftId, routeId } = props.match.params
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [selectedDateStart, setSelectedDateStart] = useState<Date | null>(new Date())
	const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(new Date())
	const [loaderShift, setLoaderShift] = useState(true)
	const [loaderRoute, setLoaderRoute] = useState(true)
	const [shift, setShift] = useState<any>()
	const [shiftNotFound, setShiftNotFound] = useState(true)

	// Formik States
	const [route, setRoute] = useState({
		collaborators: [],
		createdAt: '',
		departureTime: '',
		endDate: new Date(),
		entryTime: new Date(),
		exitTime: new Date(),
		id: '',
		maxPeopleCapacity: 0,
		maxTime: 0,
		name: '',
		serviceType: 0,
		startDate: new Date(),
		state: 0,
		updatedAt: '',
	})
	const [routeExist, setRouteExist] = useState(false)
	const [serviceType, setServiceType] = useState(0)
	const [entryTime, setEntryTime] = useState(new Date())
	const [exitTime, setExitTime] = useState(new Date())
	const [startDate, setStartDate] = useState()
	const [collaborators, setCollaborators] = useState([{}])

	// Dialog States
	const [editHourDialog, setEditHourDialog] = useState(false)
	const [changeRouteDialog, setChangeRouteDialog] = useState(false)
	const [removeDialog, setRemoveDialog] = useState(false)
	const [addDialog, setAddDialog] = useState(false)
	const [passenger, setPassenger] = useState({
		fullName: '',
		fullRut: '',
		rut: '',
		dv: '',
		address: {
			street: '',
			number: '',
			countyId: '',
		},
		phoneNumber: '',
		email: '',
		isValid: true,
	})
	const [passengerData, setPassengerData] = useState({})
	const editForm = useRef(null)
	const [handleNotification] = useNotification()
	const [sendNewHour, setSendNewHour] = useState(false)

	const getRouteById = () => {
		getRoute(routeId)
			.then((response: any) => {
				if (response && response.success) {
					setRouteData(response.data)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(() => {
				setLoaderRoute(false)
			})
	}

	const getShiftById = () => {
		getShift(shiftId)
			.then((response: any) => {
				if (response && response.success) {
					setShiftNotFound(false)
					const { data } = response
					setShiftData(data)
				} else {
					setShiftNotFound(true)
				}
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(() => {
				setLoaderShift(false)
			})
	}

	const setShiftData = (responseData: any) => {
		let data: any

		data = {
			name: responseData.name,
			store: responseData.store.name,
			costCenter: responseData.costCenter.name,
		}
		setShift(data)
	}

	const setRouteData = (routeData: any) => {
		setRouteExist(!!routeData ? true : false)
		let data: any
		data = {
			collaborators: routeData.visits,
			entryTime: new Date(`${routeData.startDate} ${routeData.entryTime}`),
			exitTime: new Date(`${routeData.startDate} ${routeData.exitTime}`),
			id: routeData.id,
			maxPeopleCapacity: routeData.maxPeopleCapacity,
			maxTime: routeData.maxTime,
			name: routeData.name,
			serviceType: routeData.serviceType,
			startDate: formatDate(new Date(routeData.startDate)),
			state: routeData.state,
		}
		setCollaborators(routeData.visits)
		setEntryTime(new Date(`${routeData.startDate} ${routeData.entryTime}`))
		setExitTime(new Date(`${routeData.startDate} ${routeData.entryTime}`))
		setRoute(data)
		setServiceType(route.serviceType)
		setStartDate(routeData.startDate)
	}

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy').toString()
	}

	useEffect(() => {
		getRouteById()
		getShiftById()
	}, [])

	if (loaderShift) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}
	if (loaderRoute) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={40} className={classes.customProgress} />
			</Box>
		)
	}

	const handleSubmit = (form: any) => {
		setSendingData(true)
		let values = form.current.values
		const postData = setPostData(values)
		putRoute(routeId, postData).catch(error => {
			setSendingData(false)
			console.log('error', error)
		})
	}

	const setPostData = (data: any) => {
		const postData = {
			name: data.name,
			serviceType: serviceType,
			maxPeopleCapacity: data.maxPeopleCapacity,
			maxTime: data.maxTime,
			startDate: setFormatDate(selectedDateStart),
			endDate: setFormatDate(selectedDateEnd),
			entryTime: setFormatTime(entryTime),
			exitTime: setFormatTime(exitTime),
			applyDays: {
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
			},
		}

		return postData
	}

	const setFormatTime = (time: any) => {
		return format(time, 'HH:mm')
	}

	const setFormatDate = (date: any) => {
		return format(date, 'yyyy-MM-dd').toString()
	}

	const handleChangeRouteDialog = () => event => {
		setChangeRouteDialog(!changeRouteDialog)
		getRouteById()
	}

	const handleOpenChangeRouteDialog = (data?: any) => event => {
		setPassengerData(data)
		setChangeRouteDialog(!changeRouteDialog)
		getRouteById()
	}

	const handleEditHourDialog = () => event => {
		setEditHourDialog(!editHourDialog)
		getRouteById()
	}

	const handleOpenEditHourDialog = (data: any) => event => {
		setPassengerData(data)
		setEditHourDialog(!editHourDialog)
		getRouteById()
	}

	const handleOpenRemoveDialog = (data: any) => event => {
		setPassengerData(data)
		setRemoveDialog(!removeDialog)
		getRouteById()
	}

	const handleRemoveDialog = () => event => {
		getRouteById()
		setRemoveDialog(!removeDialog)
	}

	const handleAddDialog = () => event => {
		getRouteById()
		setAddDialog(!addDialog)
	}

	const handleSaveGeneralHour = () => {
		setSendNewHour(true)
		const postData = {
			entryTime: serviceType === 0 ? setFormatTime(entryTime) : setFormatTime(exitTime),
		}
		putRoute(routeId, postData)
			.then(response => {
				if (response && response.success) {
					handleNotification('Hora modificada con éxito', 'success')
					getRouteById()
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.log(err)
				handleNotification('Ha ocurrido un error con la modificación', 'error')
			})
			.finally(() => setSendNewHour(false))
	}

	if (shiftNotFound) {
		return (
			<Box className={classes.boxProgress}>
				<Grid container justify="center" spacing={8}>
					<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
						<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Turno no encontrado'}</p>
					</Grid>
					<Grid item xs={12} md={4}>
						<Button className={classes.buttonBack} color="primary" component={Link} fullWidth startIcon={<TransitEnterexitIcon />} to={`/my-routes`}>
							{'Volver'}
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	}

	return shift.name !== '' ? (
		<Box className={classes.boxBodyResume}>
			<ThemeProvider theme={MainColortheme}>
				<h1 className={classes.pageTile}>{'Editar Ruta'}</h1>
				<Grid container alignItems="center">
					<Grid item xs={12} md={6}>
						<Box className={classes.boxHeaderRoute}>
							<Grid container>
								<Grid container style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '1% 0%' }}>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Ruta'}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sucursal'}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Centro de Costo'}</p>
									</Grid>
								</Grid>
								<Grid container style={{ paddingBottom: '1%' }}>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.name}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.store}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{shift.costCenter}</p>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
				{routeExist ? (
					<Fragment>
						<Formik
							innerRef={editForm}
							initialValues={{
								name: route.name,
								serviceType: route.serviceType,
								maxPeopleCapacity: route.maxPeopleCapacity,
								maxTime: route.maxTime,
								startDate: route.startDate,
								endDate: route.endDate,
								entryTime: route.entryTime,
								exitTime: route.exitTime,
								applyDays: {
									monday: false,
									tuesday: false,
									wednesday: false,
									thursday: false,
									friday: false,
									saturday: false,
									sunday: false,
								},
							}}
							validationSchema={() => EditRouteSchema}
							onSubmit={(values: EditRouteFields) => {
								handleSubmit(values)
							}}
						>
							{({ values, handleChange, handleSubmit }) => (
								<Form onSubmit={handleSubmit} autoComplete="off">
									<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
										<Grid container alignItems="center">
											<Grid item xs={12} md={6}>
												<Grid container alignItems="center" spacing={2}>
													<Grid item xs={12} md={6}>
														<p className={classes.customLabel}>Horario</p>
														<ButtonGroup color="primary" fullWidth className={classes.boxButtonGroup}>
															<Button classes={{ root: classes.buttonGroup }} color="primary" fullWidth>
																{route.serviceType > 0 ? 'Salida' : 'Ingreso'}
															</Button>
														</ButtonGroup>
													</Grid>
													<Grid item xs={12} md={3}>
														<Schedule
															entryTime={entryTime}
															exitTime={entryTime}
															scheduleSelection={route.serviceType}
															setEntryTime={setEntryTime}
															setExitTime={setEntryTime}
															commonShift={false}
														/>
													</Grid>
													<Grid item xs={12} md={3} style={{ paddingTop: '50px' }}>
														<Button className={classes.saveButton} disabled={sendNewHour} color="primary" fullWidth onClick={() => handleSaveGeneralHour()}>
															{'Confirmar'}
															{sendNewHour && <CircularProgress size={24} className={classes.buttonProgress} />}
														</Button>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</MuiPickersUtilsProvider>
								</Form>
							)}
						</Formik>
						<Fragment>
							<TableContainer component={Paper} className={'mt-2r'}>
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											<StyledTableCellHead>{'Nombre Pasajero'}</StyledTableCellHead>
											<StyledTableCellHead>{'Dirección'}</StyledTableCellHead>
											<StyledTableCellHead>{'Comuna'}</StyledTableCellHead>
											<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
											<StyledTableCellHead>{'Comentarios'}</StyledTableCellHead>
											<StyledTableCellHead colSpan={4} align="center">
												{'Acciones'}
											</StyledTableCellHead>
										</TableRow>
									</TableHead>
									<TableBody>
										{collaborators?.length > 0 ? (
											collaborators.map((visit: any, indexPassengers: number) => {
												return (
													<StyledTableRowBody key={indexPassengers}>
														<StyledTableCellBody>{visit.address.collaborator?.fullName || 'Sin Nombre'}</StyledTableCellBody>
														<StyledTableCellBody>
															{visit.address.street}
														</StyledTableCellBody>
														<StyledTableCellBody>{visit.address.county?.name || 'Sin Comuna'}</StyledTableCellBody>
														<StyledTableCellBody>{visit.address.collaborator?.phoneNumber || 'Sin Número'}</StyledTableCellBody>
														<StyledTableCellBody style={{ textAlign: 'center' }}>
															{visit.address.comment ? (
																visit.address.comment?.length > 1 ? (
																	<PopoverDetail comment={visit.address.comment} index={indexPassengers} />
																) : (
																	'Sin comentarios'
																)
															) : (
																'Sin comentarios'
															)}
														</StyledTableCellBody>
														<StyledTableCellBody>
															<Button
																classes={{ root: classes.buttonTableAction }}
																className={classes.editHour}
																color="primary"
																fullWidth
																onClick={handleOpenEditHourDialog(visit)}
																variant="contained"
															>
																{'Editar Hora'}
															</Button>
														</StyledTableCellBody>
														<StyledTableCellBody>
															<Button
																classes={{ root: classes.buttonTableAction }}
																className={classes.changeRoute}
																color="primary"
																fullWidth
																onClick={handleOpenChangeRouteDialog(visit)}
																variant="contained"
															>
																{'Cambiar ruta'}
															</Button>
														</StyledTableCellBody>
														<StyledTableCellBody>
															<IconButton aria-label="delete" classes={{ root: classes.buttonDelete }} onClick={handleOpenRemoveDialog(visit)}>
																<DeleteIcon />
															</IconButton>
														</StyledTableCellBody>
													</StyledTableRowBody>
												)
											})
										) : (
											<StyledTableRowBody>
												<StyledTableCellBody colSpan={5}>{'Sin pasajeros disponibles'}</StyledTableCellBody>
											</StyledTableRowBody>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<Grid container alignItems="center" justify="space-between" spacing={2} className={classes.customContainer}>
								<Grid item xs={12} md={4}>
									<Button
										className={classes.buttonBack}
										color="primary"
										component={Link}
										disabled={sendingData}
										fullWidth
										startIcon={<TransitEnterexitIcon />}
										to={`/my-routes/${shiftId}/edit`}
									>
										{'Volver'}
									</Button>
								</Grid>
								<Grid item xs={12} md={4}>
									<Button className={classes.buttonAddPassenger} color="primary" disabled={sendingData} fullWidth onClick={handleAddDialog()}>
										{'Agregar Pasajero'}
									</Button>
								</Grid>
							</Grid>
							<EditHourPassengerDialog
								handleDialog={handleEditHourDialog()}
								open={editHourDialog}
								passengerData={passengerData}
								routeId={route.id}
								serviceType={route.serviceType}
								entryTime={entryTime}
								setEntryTime={setEntryTime}
								exitTime={exitTime}
								setExitTime={setExitTime}
								startDate={startDate}
							/>
							<ChangeRoutePassengerDialog
								handleDialog={handleChangeRouteDialog()}
								open={changeRouteDialog}
								passengerData={passengerData}
								setPassengerData={setPassengerData}
								collaborators={collaborators}
								setCollaborators={setCollaborators}
								shiftId={shiftId}
								serviceType={route.serviceType}
								date={startDate}
							/>
							<RemovePassengerDialog
								handleDialog={handleRemoveDialog()}
								open={removeDialog}
								passengerData={passengerData}
								setPassengerData={setPassengerData}
								collaborators={collaborators}
								setCollaborators={setCollaborators}
							/>
							<AddPassengerDialog
								routeId={route.id}
								handleDialog={handleAddDialog()}
								open={addDialog}
								passenger={passenger}
								setPassenger={setPassenger}
								collaborators={collaborators}
								setCollaborators={setCollaborators}
							/>
						</Fragment>
					</Fragment>
				) : (
					<Box className={classes.boxProgress}>
						<Grid container>
							<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
								<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada en el turno'}</p>
							</Grid>
						</Grid>
					</Box>
				)}
			</ThemeProvider>
		</Box>
	) : (
		<Box className={classes.boxProgress}>
			<Grid container>
				<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
					<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{'Ruta no encontrada'}</p>
				</Grid>
			</Grid>
		</Box>
	)
}

export default EditRoutePage
