import React from "react";
import { Route } from "react-router-dom";
import Layout from "./Layout";

const RouteLayout = ({ component: Component, fullCover, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout fullCover={fullCover}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteLayout;
