import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, CircularProgress, createStyles, Grid, InputAdornment, makeStyles, MenuItem, Select, TextField, Theme, ThemeProvider, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Link, Redirect } from 'react-router-dom'

//time manager
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import es from 'date-fns/locale/es'

//icons
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import GroupIcon from '@material-ui/icons/Group'

//utils
import MainColortheme from '../utils/MainColorTheme'
import { Form, Formik, ErrorMessage } from 'formik'

//providers
import { getStores, postGetCCByStore, postRoute, putPassengersDynamicExcel } from '../../providers/Private/Provider'

import Files from './Passengers/Files'
import FrequencyTime from '../FrequencyTime/FrequencyTime'
import { CreateRouteSchema } from '../../validations/CreateRouteSchema'
import { useNotification } from '../../hooks/useNotification'
import { PopoverDetail } from '../utils/PopoverDetails'

const { format } = require('date-fns')

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxButtonGroup: {
			height: '43px',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customLabel: {
			marginBottom: '5px',
		},
		buttonAction: {
			borderRadius: 20,
			fontFamily: 'Lato-Bold',
			fontSize: 16,
			padding: '10px 0px',
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonCancel: {
			'color': '#7E7E7E',
			'border': '1px solid #CDCDCD',
			'backgroundColor': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#CDCDCD',
				color: '#000000',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonSave: {
			'color': '#FFFFFF',
			'backgroundColor': '#CDCDCD',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		buttonCreate: {
			'color': '#FFFFFF',
			'backgroundColor': '#2445A6',
			'&:hover': {
				backgroundColor: '#0D1373',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		containerButtonGroup: {
			padding: '2rem 0',
		},
		checkPeriodicty: {
			marginLeft: '0.5rem',
			padding: '0',
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
)

const DynamicRoute: FC = () => {
	const classes = useStyles()
	var today = new Date()
	const [selectedDateStart, setSelectedDateStart] = useState(new Date(today.getTime() + 24 * 60 * 60 * 1000))
	const [selectedDateEnd, setSelectedDateEnd] = useState(new Date(today.getTime() + 24 * 60 * 60 * 1000))
	const [file, setFile] = useState('')
	let passengersFile = new FormData()
	const [sendingData, setSendingData] = useState(false)
	const [routeId, setRouteId] = useState(0)
	const [submitted, setSubmitted] = useState(false)
	const [enableExcelButtoon, setEnableExcelButton] = useState(false)
	const [handleNotification] = useNotification()
	const [stores, setStores] = useState<any>([])
	const [failedPassengersFile, setFailedPassengersFile] = useState({ status: false, data: null })
	const [costCenters, setCostCenters] = useState<any>([])
	const extraPassengerText = 'Cantidad de pasajeros extra con los que un vehículo se puede sobrecargar en caso de ser necesario'

	useEffect(() => {
		getStores().then(response => {
			if (response && response.success) {
				setStores(
					response.data
						.filter(store => store.state === 1)
						.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
				)
			}
		})
	}, [])

	const periodicityTypeOptions = [
		{
			value: 'constant',
			text: 'Constante',
		},
		{
			value: 'intermittent',
			text: 'Intermitente',
		},
		{
			value: 'no',
			text: 'Sin Periocidad',
		},
	]

	const maxTimeOptions = [
		{
			value: 30,
			text: '00:30 Hrs',
		},
		{
			value: 45,
			text: '00:45 Hrs',
		},
		{
			value: 60,
			text: '01:00 Hrs',
		},
		{
			value: 75,
			text: '01:15 Hrs',
		},
		{
			value: 90,
			text: '01:30 Hrs',
		},
		{
			value: 105,
			text: '01:45 Hrs',
		},
		{
			value: 120,
			text: '02:00 Hrs',
		},
		{
			value: 135,
			text: '02:15 Hrs',
		},
		{
			value: 150,
			text: '02:30 Hrs',
		},
		{
			value: 165,
			text: '02:45 Hrs',
		},
		{
			value: 180,
			text: '03:00 Hrs',
		},
		{
			value: 195,
			text: '03:15 Hrs',
		},
		{
			value: 210,
			text: '03:30 Hrs',
		},
	]

	const setFormatDate = (date: any) => {
		return format(date, 'yyyy-MM-dd').toString()
	}

	const storeAddress = store => {
		return store.address !== null ? `${store.address.street}` : 'Sin Dirección'
	}

	const handleSubmit = values => {
		setSendingData(true)
		if (file !== '') {
			const postData = setPostData(values)
			if (values.costCenter === 0) {
				handleNotification('No hay centro de costo asociado', 'error')
				setSendingData(false)
			} else {
				postRoute(postData)
					.then((response: any) => {
						if (response && response.success) {
							const { data } = response
							setRouteId(data.id)
							passengersFile.append('excel', file)
							putPassengersDynamicExcel({ id: data.id, maxPeople: values.maxPeopleCapacity, maxTime: values.maxTime }, passengersFile)
								.then(responseExcel => {
									if (responseExcel) {
										const { success, message } = responseExcel
										if (success) {
											setSubmitted(true)
											handleNotification(message, 'success')
										} else {
											setFailedPassengersFile({ status: true, data: message })
											handleNotification(message, 'error')
										}
									} else {
										handleNotification('Ha ocurrido un error', 'error')
									}
								})
								.catch(excelError => {
									console.log('excelError', excelError)
									handleNotification('Error con la carga del excel', 'error')
								})
						} else {
							handleNotification('Ha ocurrido un error', 'error')
						}
					})
					.catch(error => {
						setSendingData(false)
						console.log('error', error)
						handleNotification('usp! no se ha creado la ruta', 'error')
					})
			}
		} else {
			handleNotification('Debe subir un excel de pasajeros', 'warning')
			setSendingData(false)
		}
	}

	const setPostData = (data: any) => {
		const postData = {
			name: data.name,
			maxPeopleCapacity: data.maxPeopleCapacity,
			maxTime: data.maxTime,
			startDate: setFormatDate(selectedDateStart),
			endDate: setFormatDate(selectedDateEnd),
			periodicityType: data.periodicityType,
			office: data.office,
			costCenter: data.costCenter,
			passengersType: 0,
			shiftType: data.shiftType,
			extraPassengers: data.extraPassengers,
		}

		return postData
	}

	const getCostCenters = value => {
		const setData = {
			id: value,
		}
		postGetCCByStore(setData).then(response => {
			if (response && response.success) {
				const { data } = response
				setCostCenters(data)
			}
		})
	}

	const checkEnableExcelButtom = (values: any) => {
		if (values.name !== null && values.maxPeopleCapacity !== null && values.maxTime !== null && values.office !== null) {
			setEnableExcelButton(false)
		} else {
			setEnableExcelButton(false)
		}
	}

	const noOptions = [
		{
			id: 0,
			name: 'Sin Centros de costo asociados',
		},
	]

	if (submitted) {
		return <Redirect push to={{ pathname: `/new-route/${routeId}/resume` }} />
	}

	if (failedPassengersFile.status) {
		return <Redirect push to={{ pathname: `/new-route/${routeId}/wrong-passengers-file`, state: failedPassengersFile }} />
	}

	return (
		<>
			<Formik
				initialValues={{
					name: null,
					maxPeopleCapacity: null,
					maxTime: 90,
					startDate: new Date(),
					endDate: new Date(),
					state: 1,
					entryTime: new Date(),
					exitTime: new Date(),
					periodicityType: 'no',
					office: null,
					costCenter: null,
					passengersType: 0,
					extraPassengers: 0,
					shiftType: 1,
				}}
				validationSchema={() => CreateRouteSchema}
				onSubmit={(values: DynamicRouteFields) => {
					handleSubmit(values)
				}}
			>
				{({ values, handleChange, handleSubmit, setFieldValue }) => (
					<Form onSubmit={handleSubmit} autoComplete="off">
						<ThemeProvider theme={MainColortheme}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
								<Grid container alignItems="center" spacing={2}>
									<Grid item md={12}>
										<h1 className={'ct-light-black ff-LatoRegular fs20 tt-upper'}>{'Nueva Ruta'}</h1>
									</Grid>
									<Grid item xs={12} md={6}>
										<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>Nombre Ruta</p>
										<TextField
											className={classes.customTextField}
											fullWidth
											id="name"
											name="name"
											onChange={event => {
												checkEnableExcelButtom(values)
												handleChange(event)
											}}
											value={values.name}
											variant="outlined"
											helperText={<ErrorMessage name="name" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Sucursal</p>
										<Select
											id="office"
											name="office"
											variant="outlined"
											className={classes.customTextField}
											fullWidth
											value={values.office}
											onChange={e => {
												handleChange(e)
												getCostCenters(e.target.value)
												setFieldValue('costCenters', null)
											}}
										>
											{stores.map(
												(store, index) =>
													store.state === 1 && (
														<MenuItem key={index} value={store.id} classes={{ root: classes.customMenuItem }}>
															{`${store.name} (${storeAddress(store)})`}
														</MenuItem>
													)
											)}
										</Select>
										<ErrorMessage name="office" render={msg => <Typography className={classes.error}>{msg}</Typography>} />
									</Grid>
									<Grid item xs={12} md={3}>
										<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Centro de costo</p>
										<Select
											id="costCenter"
											name="costCenter"
											variant="outlined"
											disabled={values.office === null}
											className={classes.customTextField}
											fullWidth
											value={values.costCenter}
											onChange={handleChange}
										>
											{costCenters.length > 0
												? costCenters.map((costCenter, index) => (
														<MenuItem key={index} value={costCenter.id} classes={{ root: classes.customMenuItem }}>
															{`${costCenter.name}`}
														</MenuItem>
												  ))
												: noOptions.map((noOpt, index) => (
														<MenuItem key={index} value={noOpt.id} classes={{ root: classes.customMenuItem }}>
															{`Sin centros de costo asociados`}
														</MenuItem>
												  ))}
										</Select>
										<ErrorMessage name="office" render={msg => <Typography className={classes.error}>{msg}</Typography>} />
									</Grid>
									<Grid item xs={12} md={6}>
										<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>Día de Entrada</p>
										<FrequencyTime selectedDate={selectedDateStart} setSelectedDate={setSelectedDateStart} disabled={false} />
									</Grid>
									<Grid item xs={12} md={6}>
										<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>Día de Salida</p>
										<FrequencyTime selectedDate={selectedDateEnd} setSelectedDate={setSelectedDateEnd} disabled={false} />
									</Grid>
									{/* <Grid item xs={12} md={12}>
										<p
											className={
												classes.customLabel +
												' ' +
												'ct-black ff-LatoRegular fs16'
											}
										>
											Días en que se aplica
										</p>
										<FrequencyWeekDays
											weekDays={weekDays}
											setWeekDays={setWeekDays}
										/>
									</Grid> */}
									<Grid item xs={12} md={4}>
										<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>Capacidad máxima de pasajeros</p>
										<TextField
											className={classes.customTextField}
											fullWidth
											type="number"
											id="maxPeopleCapacity"
											name="maxPeopleCapacity"
											onChange={handleChange}
											value={values.maxPeopleCapacity}
											variant="outlined"
											helperText={<ErrorMessage name="maxPeopleCapacity" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<DriveEtaIcon />
													</InputAdornment>
												),
												inputProps: {
													min: 1,
												},
											}}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{`Pasajeros Extra`}</p>
										<TextField
											className={classes.customTextField}
											type="number"
											fullWidth
											id="extraPassengers"
											name="extraPassengers"
											onChange={handleChange}
											value={values.extraPassengers}
											variant="outlined"
											helperText={<ErrorMessage name="extraPassengers" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<GroupIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="end">
														<PopoverDetail comment={extraPassengerText} index={0} />
													</InputAdornment>
												),
											}}
										/>
										<ErrorMessage name="extraPassengers" render={msg => <Typography className={classes.error}>{msg}</Typography>} />
									</Grid>
									<Grid item xs={12} md={4} style={{ marginTop: '-0.1%' }}>
										<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>Tiempo máximo en ruta</p>
										<Select className={classes.customTextField} fullWidth id="maxTime" name="maxTime" onChange={handleChange} value={values.maxTime} variant="outlined">
											{maxTimeOptions.map((maxTime, index) => (
												<MenuItem key={index} value={maxTime.value} classes={{ root: classes.customMenuItem }}>
													{maxTime.text}
												</MenuItem>
											))}
										</Select>
										<ErrorMessage name="maxTime" render={msg => <Typography className={classes.error}>{msg}</Typography>} />
									</Grid>
									{/* <Grid item xs={12} md={4}>
										<p className={classes.customLabel + ' ' + 'ct-black ff-LatoRegular fs16'}>Periodicidad</p>
										<Select
											className={classes.customTextField}
											fullWidth
											id="periodicityType"
											name="periodicityType"
											onChange={handleChange}
											value={values.periodicityType}
											variant="outlined"
										>
											{periodicityTypeOptions.map((periodicity, index) => (
												<MenuItem key={index} value={periodicity.value} classes={{ root: classes.customMenuItem }}>
													{periodicity.text}
												</MenuItem>
											))}
										</Select>
										<ErrorMessage name="periodicityType" render={msg => <Typography className={classes.error}>{msg}</Typography>} />
									</Grid> */}
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									<Files file={file} setFile={setFile} type={1} startDate={selectedDateStart} endDate={selectedDateEnd} />
								</Grid>
								<Grid container alignItems="center" spacing={2} className={classes.containerButtonGroup}>
									{/* <Grid item xs={12} md={4}> */}
									<Grid item xs={12} md={6}>
										<Button
											classes={{ root: classes.buttonAction }}
											className={classes.buttonCancel}
											color="primary"
											component={Link}
											fullWidth
											to={'/new-route'}
											variant="contained"
										>
											{'Cancelar'}
										</Button>
									</Grid>
									{/* <Grid item xs={12} md={4}>
										<Button classes={{ root: classes.buttonAction }} className={classes.buttonSave} color="primary" fullWidth variant="contained">
											{'Guardar Información'}
										</Button>
									</Grid> */}
									<Grid item xs={12} md={6}>
										{/* <Grid item xs={12} md={4}> */}
										<Button
											classes={{ root: classes.buttonAction }}
											className={classes.buttonCreate}
											color="primary"
											disabled={sendingData}
											fullWidth
											type="submit"
											variant="contained"
										>
											{'Crear Ruta'}
											{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
										</Button>
									</Grid>
								</Grid>
							</MuiPickersUtilsProvider>
						</ThemeProvider>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default DynamicRoute
