import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	createStyles,
	makeStyles,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	ThemeProvider,
	withStyles,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { RemoveStoreDialog } from '../Dialogs/RemoveStoreDialog'
import { getCostCenters, putStores } from '../../providers/Private/Provider'
import MainColorTheme from '../utils/MainColorTheme'
import ExpandMore from '@material-ui/icons/ExpandMore'

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonDelete: {
			border: '2px solid rgba(0, 0, 0, 0.54)',
			color: 'rgba(0, 0, 0, 0.54)',
		},
		editHour: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		emptyBox: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
			width: '100%',
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		custCell: {
			padding: '0 !important',
		},
	})
)

const INIT_DIALOGS = {
	remove: false,
}

export const ListCostCenter = ({ setCostCenterEdit, setComponentValue, handleSetSelected }) => {
	const classes = useStyles()
	const [costCenters, setCostCenters] = useState([])
	const [loader, setLoader] = useState(true)
	const [dialogs, setDialogs] = useState(INIT_DIALOGS)
	const [costCenterSelected, setcostCenterSelected] = useState(0)

	useEffect(() => {
		getStoresData()
	}, [])

	const getStoresData = () => {
		setLoader(true)
		getCostCenters()
			.then(response => {
				if (response && response.success && response.data?.length > 0) {
					setCostCenters(
						response.data.sort((a, b) => {
							if (a.id > b.id) {
								return 1
							}
							if (a.id < b.id) {
								return -1
							}
							return 0
						})
					)
				}
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const handleSubmit = e => {
		e.preventDefault()
	}

	const editStore = costCenter => {
		setCostCenterEdit(costCenter)
		handleSetSelected('addCostCenter')
		setComponentValue(5)
	}

	const handleRemoveDialog = () => event => {
		getStoresData()
		setDialogs({ ...INIT_DIALOGS, remove: false })
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={34} className={classes.customProgress} />
			</Box>
		)
	}

	return costCenters && costCenters.length > 0 ? (
		<ThemeProvider theme={MainColorTheme}>
			<form onSubmit={handleSubmit} autoComplete="off">
				<h2 className={'ct-black ff-LatoRegular fs20'}>{'Lista de Centros de Costo'}</h2>
				<TableContainer component={Paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<StyledTableCellHead>{'Nombre'}</StyledTableCellHead>
								<StyledTableCellHead>{'Sucursal'}</StyledTableCellHead>
								<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
								<StyledTableCellHead>{'Email'}</StyledTableCellHead>
								<StyledTableCellHead> {'Acciones'}</StyledTableCellHead>
							</TableRow>
						</TableHead>
						<TableBody>
							{costCenters.map((costCenter: any, index) => (
								<>
									<StyledTableRowBody key={index}>
										<StyledTableCellBody>{costCenter.name ? costCenter.name : 'Sin Nombre'}</StyledTableCellBody>
										<StyledTableCellBody>{costCenter.store?.name ? costCenter.store.name : 'Sin Sucursal'}</StyledTableCellBody>
										<StyledTableCellBody>{costCenter.numberInCharge ? costCenter.numberInCharge : 'Sin Télefono'}</StyledTableCellBody>
										<StyledTableCellBody>{costCenter.emailInCharge ? costCenter.emailInCharge : 'Sin Email'}</StyledTableCellBody>
										<StyledTableCellBody>
											<Button
												classes={{ root: classes.buttonTableAction }}
												className={classes.editHour}
												color="primary"
												fullWidth
												variant="contained"
												onClick={() => editStore(costCenter)}
											>
												{'Editar'}
											</Button>
										</StyledTableCellBody>
									</StyledTableRowBody>
								</>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</form>
			<RemoveStoreDialog handleDialog={handleRemoveDialog()} open={dialogs.remove} store={costCenterSelected} />
		</ThemeProvider>
	) : (
		<Box className={classes.emptyBox}>
			<h1 className={'ct-black ff-LatoRegular fs20'}>No hay Centros de Costo disponibles</h1>
		</Box>
	)
}
