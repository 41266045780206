import { FC, useState, useEffect } from 'react'
import { Box, createStyles, Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PhoneIcon from '@material-ui/icons/Phone'
import HomeIcon from '@material-ui/icons/Home'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import NoteIcon from '@material-ui/icons/Note'

//providers
import { getCounties } from '../../../providers/Public/Provider'

import validator from 'validator'

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		passengersLabel: {
			'color': 'rgba(0, 122, 255, 0.7)',
			'fontFamily': 'Lato-Bold',
			'fontSize': '20px',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			letterSpacing: '0.00938em',
			lineHeight: 1.5,
			position: 'absolute',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: '381.19px',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
	})
)

const initPassengers = {
	fullName: '',
	passengerCode: '',
	address: {
		street: '',
		number: '',
		countyId: '',
	},
	phoneNumber: '',
	email: '',
	isValid: true,
	comment: '',
}
const initValidation = {
	passengerName: false,
	passengerCounty: false,
	passengerStreet: false,
	passengerNumber: false,
	passengerPhone: false,
	passengerMail: false,
}

const Manually: FC<AddPassengerManuallyProps> = ({ setPassenger, passenger }) => {
	const classes = useStyles()
	const [counties, setCounties] = useState([])
	const [verificationField, setVerificationFields] = useState<PassengerValidate[]>([initValidation])
	const [validations, setValidations] = useState({
		email: false,
	})

	const handleValidationPassenger = (validatorField: string, value: string | null) => {
		let clone = JSON.parse(JSON.stringify(verificationField))
		if (value === null || value === undefined || value.length === 0) {
			clone[validatorField] = true
		} else {
			clone[validatorField] = false
		}
		setVerificationFields(clone)
	}

	const handleChangePassenger = (value: string, field: string) => {
		setPassenger(passenger => ({
			...passenger,
			[field]: value,
		}))
	}

	const handleAddressChangePassenger = (value: any, field: string) => {
		let clone = JSON.parse(JSON.stringify(passenger))

		clone['address'][field] = value

		setPassenger(clone)
	}

	const checkEmail = email => {
		if (validator.isEmail(email)) {
			setValidations({ ...validations, email: false })
		} else {
			setValidations({ ...validations, email: true })
			setPassenger({ ...passenger, email: '' })
		}
	}

	const handleCountyChange = (event: any, field: string) => {
		const { value } = event.target
		let clone = JSON.parse(JSON.stringify(passenger))
		const filterCounties = counties.filter(({ name }) => value === name)

		if (filterCounties.length > 0) {
			clone['address'][field] = filterCounties[0]['id']
			setPassenger(clone)
		}
	}

	useEffect(() => {
		getCounties().then(response => {
			response !== undefined &&
				setCounties(
					response.sort((a, b) => {
						if (a.name > b.name) {
							return 1
						}
						if (a.name < b.name) {
							return -1
						}
						return 0
					})
				)
		})
	}, [])

	return (
		<Box>
			<Grid container spacing={2} className={classes.containerInput}>
				<Grid item xs={12} md={6}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Nombre y Apellido</p>
					<TextField
						className={classes.customTextField}
						autoComplete="off"
						id="passengerName"
						name="passengerName"
						variant="outlined"
						required={true}
						onChange={event => handleChangePassenger(event.target.value, 'fullName')}
						value={passenger.fullName}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AccountCircleIcon />
								</InputAdornment>
							),
						}}
						onBlur={() => {
							handleValidationPassenger('passengerName', passenger.fullName)
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Código Pasajero'}</p>
					<TextField
						className={classes.customTextField}
						autoComplete="off"
						id="passengerCode"
						variant="outlined"
						required={true}
						onChange={event => {
							handleChangePassenger(event.target.value, 'passengerCode')
						}}
						value={passenger.passengerCode}
						fullWidth
						inputProps={{
							maxLength: 20,
							startAdornment: (
								<InputAdornment position="start">
									<AssignmentIndIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2} className={classes.containerInput}>
				<Grid item xs={12} md={4}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Comuna</p>
					<Autocomplete
						classes={{ option: classes.customMenuItem }}
						getOptionLabel={option => (option ? option['name'] : '')}
						id="passengerCounty"
						options={counties}
						renderInput={params => (
							<TextField
								{...params}
								name="passengerCounty"
								classes={{
									root: classes.customAutocompleteField,
								}}
								required={true}
								variant="outlined"
								onSelect={event => handleCountyChange(event, 'countyId')}
								onBlur={() => {
									handleValidationPassenger('passengerCounty', passenger['address']['countyId'])
								}}
								value={passenger.address.countyId}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Calle</p>
					<TextField
						className={classes.customTextField}
						autoComplete="off"
						id="passengerStreet"
						name="passengerStreet"
						variant="outlined"
						required={true}
						onChange={event => handleAddressChangePassenger(event.target.value, 'street')}
						value={passenger.address.street}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<HomeIcon />
								</InputAdornment>
							),
						}}
						onBlur={() => {
							handleValidationPassenger('passengerStreet', passenger.address.street)
						}}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2} className={classes.containerInput}>
				<Grid item xs={12} md={6}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Celular</p>
					<TextField
						className={classes.customTextField}
						id="passengerPhone"
						autoComplete="off"
						name="passengerPhone"
						variant="outlined"
						required={true}
						inputProps={{ maxLength: 9 }}
						onChange={event => handleChangePassenger(event.target.value, 'phoneNumber')}
						value={passenger.phoneNumber}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<PhoneIcon />
									<span style={{ marginLeft: '20px' }}>+56</span>
								</InputAdornment>
							),
						}}
						onBlur={() => {
							handleValidationPassenger('passengerPhone', passenger.phoneNumber)
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>E-mail</p>
					<TextField
						className={classes.customTextField}
						autoComplete="off"
						id="passengerMail"
						name="passengerMail"
						variant="outlined"
						required={true}
						onChange={event => handleChangePassenger(event.target.value, 'email')}
						onBlur={event => checkEmail(event.target.value)}
						value={passenger.email}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AlternateEmailIcon />
								</InputAdornment>
							),
						}}
					/>
					{validations.email && <p className={classes.error + ' ' + 'm-0'}>{'Debes ingresar un correo valido'}</p>}
				</Grid>
				<Grid item xs={12} md={12}>
					<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Comentarios</p>
					<TextField
						className={classes.customTextField}
						autoComplete="off"
						id="passengerComment"
						name="passengerComment"
						variant="outlined"
						onChange={event => handleChangePassenger(event.target.value, 'comment')}
						value={passenger.comment}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<NoteIcon />
								</InputAdornment>
							),
						}}
						onBlur={() => {
							handleValidationPassenger('passengerComment', passenger.comment)
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

export default Manually
