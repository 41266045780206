import { AppBar, Container, createStyles, makeStyles, Toolbar } from '@material-ui/core'
import { FC, Fragment } from 'react'
import Logo from '../../assets/img/logo.svg'

const useStyles = makeStyles(() =>
	createStyles({
		customAppBar: {
			background: '#0D1373',
			boxShadow: 'none',
		},
		customToolbar: {
			minHeight: 80,
			padding: 0,
		},
		boxLinks: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		customLink: {
			'color': '#FFFFFF',
			'minWidth': 113,
			'textDecoration': 'none',
			'&:hover, &:focus': {
				color: '#FFFFFF',
				textDecoration: 'none',
			},
		},
		customItemText: {
			'fontSize': 14,
			'textAlign': 'center',
			'& .MuiTypography-root': {
				fontFamily: 'Lato-Regular',
				textTransform: 'uppercase',
			},
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
	})
)

export const MapHeader: FC = () => {
	const classes = useStyles()

	return (
		<Fragment>
			<AppBar position="fixed" className={classes.customAppBar}>
				<Container>
					<Toolbar className={classes.customToolbar}>
						<img alt={'Logo'} src={Logo} />
					</Toolbar>
				</Container>
			</AppBar>
		</Fragment>
	)
}
