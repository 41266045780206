import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const addCompanySchema = Yup.object().shape({
	name: Yup.string()
		.required('Debes ingresar un nombre'),
	rut: Yup.string()
		.required('Debes ingresar un RUT'),
	phoneNumber: Yup.string()
		.matches(phoneRegExp, 'El teléfono no es valido')
		.min(9, 'El teléfono debe tener 9 números')
		.max(9, 'El teléfono debe tener 9 números como máximo')
		.required('Debes ingresar un teléfono'),
	taxicallerId: Yup.string()
		.required('Debes ingresar un ID'),
})
