import { Box, Button, CircularProgress, createStyles, Grid, InputAdornment, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useMediaQuery } from 'react-responsive'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { useNotification } from '../../hooks/useNotification'
import { getStores, postCostCenter, putCostCenter } from '../../providers/Private/Provider'

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { addCostCenterSchema } from '../../validations'

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 260px)',
			justifyContent: 'center',
		},
		actionButton: {
			background: '#0D1373',
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			height: 49,
			width: 240,
		},
		wrongTitle: {
			color: '#FF5E05',
			textTransform: 'uppercase',
			fontWeight: 600,
			fontFamily: 'Lato-Regular',
			fontStyle: 'normal',
			fontSize: '20px',
		},
		error: {
			'fontFamily': 'Lato-Regular',
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: 'red',
				},
				'&:hover fieldset': {
					borderColor: 'red',
				},
				'&.Mui-focused fieldset': {
					borderColor: 'red',
				},
			},
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		errorValidation: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		errorAutocomplete: {
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: 'red',
				},
				'&:hover fieldset': {
					borderColor: 'red',
				},
				'&.Mui-focused fieldset': {
					borderColor: 'red',
				},
			},
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		spanPhone: {
			fontFamily: 'Lato-Regular',
			marginLeft: 20,
		},
	})
)

export const AddCostCenter = ({ setComponentValue, costCenterEdit, handleSetSelected }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [stores, setStores] = useState<any>([])
	const [companiesLoader, setCompaniesLoader] = useState(true)
	const [handleNotification] = useNotification()
	const [sendingdata, setSendingdata] = useState(false)

	useEffect(() => {
		getStores()
			.then(response => {
				if (response && response.success) {
					setStores(
						response.data.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error al listar las compañías', 'error')
			})
			.finally(() => setCompaniesLoader(false))
	}, [])

	const handleSubmit = (values: any) => {
		setSendingdata(true)
		const setPutData = {
			name: values.name,
			store: values.store,
			emailInCharge: values.emailInCharge,
			numberInCharge: values.numberInCharge,
		}
		postCostCenter(setPutData)
			.then(response => {
				if (response && response.success) {
					handleNotification('Se ha creado el centro de costo con éxito', 'success')
					formik.resetForm()
					setComponentValue(6)
					handleSetSelected('listCostCenter')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}

				setSendingdata(false)
			})

			.catch(() => {
				setSendingdata(false)
				handleNotification('Error en la creación del centro de costo', 'error')
			})
	}

	const handleUpdate = (values: any) => {
		const setPutData = {
			name: values.name,
			store: values.store,
			emailInCharge: values.emailInCharge,
			numberInCharge: values.numberInCharge,
		}
		setSendingdata(true)
		putCostCenter(costCenterEdit.id, setPutData)
			.then(response => {
				if (response && response.success) {
					handleNotification('Se actualizado el centro de costo con éxito', 'success')
					formik.resetForm()
					setComponentValue(6)
					handleSetSelected('listCostCenter')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
				setSendingdata(false)
			})
			.catch(() => {
				handleNotification('Error en la modificación del centro de costo', 'error')
				setSendingdata(false)
			})
	}

	const formik = useFormik({
		initialValues: {
			name: costCenterEdit ? costCenterEdit.name : '',
			store: costCenterEdit ? costCenterEdit.store.id : '',
			emailInCharge: costCenterEdit ? costCenterEdit.emailInCharge : '',
			numberInCharge: costCenterEdit ? costCenterEdit.numberInCharge : '',
		},
		validationSchema: addCostCenterSchema,
		onSubmit: values => {
			costCenterEdit ? handleUpdate(values) : handleSubmit(values)
		},
	})

	return !sendingdata ? (
		<Grid item xs={12} md={12}>
			{stores?.length > 0 ? (
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit} autoComplete="off">
						<Grid container>
							<Grid item xs={12} md={6}>
								<h2 className={'ct-black ff-LatoRegular fs20'}>{'Nuevo Centro de Costo'}</h2>
							</Grid>
							<Grid container spacing={2} className={classes.containerInput}>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Nombre'}</p>
									<TextField
										className={classes.customTextField}
										id="name"
										name="name"
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.name}
										fullWidth
										helperText={<ErrorMessage name="name" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AccountCircleIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Sucursal</p>
									<Field
										className={classes.customTextField}
										as={TextField}
										name={'store'}
										onChange={formik.handleChange}
										value={formik.values.store}
										variant="outlined"
										fullWidth
										select
										helperText={<ErrorMessage name="store" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									>
										{stores.map(({ id, name }) => (
											<MenuItem key={id} value={id} className={'ff-LatoRegular'}>
												{name}
											</MenuItem>
										))}
									</Field>
								</Grid>
							</Grid>
							<Grid container spacing={2} className={classes.containerInput}>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Email encargado Centro de Costo</p>
									<TextField
										className={classes.customTextField}
										id="street"
										name={`emailInCharge`}
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.emailInCharge}
										fullWidth
										helperText={<ErrorMessage name={`emailInCharge`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AlternateEmailIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>Número encargado Centro de Costo</p>
									<TextField
										className={classes.customTextField}
										id="number"
										name={`numberInCharge`}
										variant="outlined"
										required={true}
										onChange={formik.handleChange}
										value={formik.values.numberInCharge}
										inputProps={{ maxLength: 9 }}
										fullWidth
										helperText={<ErrorMessage name={`numberInCharge`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIphoneIcon />
													<span className={classes.spanPhone}>+56</span>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} style={{ textAlign: 'center', paddingTop: '2%' }}>
									<Button className={`${classes.actionButton} ${isMobile && 'w-100'}`} type="submit" variant={'contained'}>
										{costCenterEdit?.id ? 'Confirmar Edición' : 'Agregar'}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</FormikProvider>
			) : (
				<Box className={classes.boxLoading}>
					{companiesLoader ? <CircularProgress size={34} className={classes.customProgress} /> : <h1 className={'ct-black ff-LatoRegular fs20'}>{'No hay cetros de costos disponibles'}</h1>}
				</Box>
			)}
		</Grid>
	) : (
		<Box className={classes.boxLoading}>
			<CircularProgress size={34} className={classes.customProgress} />
		</Box>
	)
}
