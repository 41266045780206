import {
	createStyles,
	makeStyles,
	Typography,
  Box
} from '@material-ui/core'

const useStyles = makeStyles(() =>
	createStyles({
    boxTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    customTitle: {
      color: '#FFFFFF',
      fontFamily: 'Lato-Regular',
      fontSize: 20
    }
	})
)

const Title = ({ label }) => {
	const classes = useStyles()

	return (
    <Box className={classes.boxTitle}>
      <Typography className={classes.customTitle} gutterBottom>
        {label}
      </Typography>
    </Box>
	)
}

export default Title
