import * as Yup from 'yup'

export const addStoresSchema = Yup.object().shape({
	name: Yup.string().min(2, 'El nombre debe ser más largo').required('Debes ingresar un nombre'),
	phoneNumber: Yup.string().trim().max(9, 'El número de telefono es muy largo').min(9, 'El número de telefono es muy corto').required('Número de teléfono es obligatorio'),
	address: Yup.object().shape({
        street: Yup.string().required('Debes ingresar una calle').min(2, 'El nombre de la calle debe ser más largo'),
	}),
	emailInCharge: Yup.string().email('Debes ingresar un email válido').required('Debes ingresar un correo electrónico'),
	numberInCharge: Yup.string().trim().max(9, 'El número de telefono es muy largo').min(9, 'El número de telefono es muy corto').required('Número de teléfono es obligatorio'),
})
