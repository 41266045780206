import { Box, Button, Chip, CircularProgress, createStyles, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNotification } from '../../hooks/useNotification'
import { postUsers, getRoles, getCompanies, postStoresByCompany, postGetCCByStore, editUser } from '../../providers/Private/Provider'

//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PhoneIcon from '@material-ui/icons/Phone'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import LockIcon from '@material-ui/icons/Lock'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { addUserSchema } from '../../validations/addUserSchema'
import { Autocomplete } from '@material-ui/lab'
import { UpdateUserSchema } from '../../validations/UpdateUserSchema'

const { validate, format } = require('rut.js')

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		containerInput: {
			padding: '0.5rem 0',
		},
		customProgress: {
			color: '#0D1373',
		},
		boxLoading: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 260px)',
			justifyContent: 'center',
		},
		actionButton: {
			background: '#0D1373',
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			height: 49,
			width: 240,
		},
		errorValidation: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
		spanPhone: {
			fontFamily: 'Lato-Regular',
			marginLeft: 20,
		},
		visibility: {
			'&:hover': {
				cursor: 'pointer',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
	})
)

export const AddUser = ({ setComponentValue, userEdit, handleSetSelected }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const [stores, setStores] = useState<any>([])
	const [costCenters, setCostCenters] = useState<any>([])
	const [rolesList, setRolesList] = useState<any>([])
	const [companies, setCompanies] = useState<any>([])
	const [handleNotification] = useNotification()
	const [sendingdata, setSendingdata] = useState(false)
	const [sendDataMessage, setSendDataMessage] = useState('')
	const [rutValid, setRutValid] = useState(true)
	const [watchPass, setWatchPass] = useState(false)

	let ccArray: any = []

	const checkRut = (rut: any) => {
		validate(rut) ? setRutValid(true) : setRutValid(false)
	}
	useEffect(() => {
		getCompanies()
			.then(response => {
				if (response && response.success) {
					setCompanies(
						response.data.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.error('error', err)
			})

		getRoles()
			.then(response => {
				if (response && response.success) {
					setRolesList(response.data)
				}
			})
			.catch(err => {
				console.error('error', err)
			})

		if (userEdit) {
			handleStoresByCompany(userEdit.company?.id)
			handleCCbyStores(userEdit.stores)
		}
	}, [])

	const handleStoresByCompany = (id: string) => {
		const setPostData = {
			id: id,
		}
		postStoresByCompany(setPostData)
			.then(response => {
				if (response && response.success) {
					setStores(
						response.data.sort((a, b) => {
							if (a.name > b.name) {
								return 1
							}
							if (a.name < b.name) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(error => {
				console.log('error', error)
				handleNotification('Ha ocurrido un error al listar las compañías', 'error')
			})
	}

	const handleCCbyStores = (storesSelected: []) => {
		if (storesSelected.length > 0) {
			storesSelected.map((store: any, index) => {
				const setPostdata = {
					id: store.id,
				}
				postGetCCByStore(setPostdata).then(response => {
					if (response && response.success) {
						if (costCenters.length === 0) {
							ccArray.push(
								response.data.sort((a, b) => {
									if (a.name > b.name) {
										return 1
									}
									if (a.name < b.name) {
										return -1
									}
									return 0
								})
							)
							setCostCenters(ccArray.flat())
						} else {
							let arr: any = costCenters
							arr.push(
								response.data.sort((a, b) => {
									if (a.name > b.name) {
										return 1
									}
									if (a.name < b.name) {
										return -1
									}
									return 0
								})
							)
							ccArray = arr.flat()
							setCostCenters(ccArray)
						}
					}
				})
			})
		}
	}

	const handleSubmit = values => {
		let check = true
		const { role, company } = formik.values
		if (checkCompanyRequired(role.name)) {
			if (company === '') {
				check = false
			}
		} else {
			check = true
		}
		check
			? postUsers(values)
					.then(response => {
						if (response && response.success) {
							handleNotification('Usuario creado con éxito', 'success')
							formik.resetForm()
							setComponentValue(8)
							handleSetSelected('listUsers')
						}
					})
					.catch(err => {
						handleNotification('Ocurrió un error en la creación del usuario', 'error')
					})
			: handleNotification('El rol del usuario debe tener una compañía', 'error')
	}

	const handleUpdate = values => {
		
		let check = true
		const { role, company } = formik.values
		if (checkCompanyRequired(role.name)) {
			if (company === '') {
				check = false
			}
		} else {
			check = true
		}
		check
			? editUser(userEdit.id, values)
					.then(response => {
						if (response && response.success) {
							handleNotification('Usuario modificado con éxito', 'success')
							formik.resetForm()
							setComponentValue(8)
							handleSetSelected('listUsers')
						}
					})
					.catch(err => {
						handleNotification('Ocurrió un error en la modificación', 'error')
						console.error('error', err)
					})
			: handleNotification('El rol del usuario debe tener una compañía', 'error')
	}

	const checkCompanyRequired = role => {
		switch (role) {
			case 'SUPERADMIN':
				return false
			case 'ADMINISTRADOR':
				return false
			default:
				return true
		}
	}

	const formik = useFormik({
		initialValues: {
			firstName: userEdit ? userEdit.firstName : '',
			lastName: userEdit ? userEdit.lastName : '',
			email: userEdit ? userEdit.email : '',
			rut: userEdit ? `${userEdit.rut}-${userEdit.dv}` : '',
			phoneNumber: userEdit ? userEdit.phoneNumber : '',
			company: userEdit ? userEdit.company : '',
			stores: userEdit ? userEdit.stores : [],
			costCenters: userEdit ? userEdit.costCenters : [],
			role: userEdit ? userEdit.role : '',
			password: '',
			passwordConfirm: '',
		},
		validationSchema: userEdit ? UpdateUserSchema : addUserSchema,
		onSubmit: values => {
			userEdit ? handleUpdate(values) : handleSubmit(values)
		},
	})

	return !sendingdata ? (
		<Grid item xs={12} md={12}>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit} autoComplete="off">
					<Grid container>
						<Grid item xs={12} md={6}>
							<h2 className={'ct-black ff-LatoRegular fs20'}>{'Nuevo Usuario'}</h2>
						</Grid>
						<Grid container spacing={2} className={classes.containerInput}>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Nombres'}</p>
								<TextField
									className={classes.customTextField}
									id="firstName"
									name="firstName"
									variant="outlined"
									required={true}
									onChange={formik.handleChange}
									value={formik.values.firstName}
									fullWidth
									helperText={<ErrorMessage name="firstName" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<AccountCircleIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Apellidos'}</p>
								<TextField
									className={classes.customTextField}
									id="lastName"
									name="lastName"
									variant="outlined"
									required={true}
									onChange={formik.handleChange}
									value={formik.values.lastName}
									fullWidth
									helperText={<ErrorMessage name="lastName" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<AccountCircleIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} className={classes.containerInput}>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Email'}</p>
								<TextField
									className={classes.customTextField}
									id="email"
									name={`email`}
									variant="outlined"
									required={true}
									onChange={formik.handleChange}
									value={formik.values.email}
									fullWidth
									helperText={<ErrorMessage name={`email`} render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<AlternateEmailIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'RUT'}</p>
								<TextField
									className={classes.customTextField}
									fullWidth
									helperText={<ErrorMessage name="rut" render={msg => <p className={classes.errorValidation}>{msg}</p>} />}
									id="rut"
									name={'rut'}
									onBlur={event => checkRut(formik.values.rut)}
									onChange={event => formik.setFieldValue('rut', format(event.target.value))}
									value={format(formik.values.rut)}
									variant="outlined"
									inputProps={{ maxLength: 12 }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<AssignmentIndIcon />
											</InputAdornment>
										),
									}}
								/>
								{!rutValid && <p className={`${classes.errorValidation} m-0`}>{'Debes ingresar un RUT valido'}</p>}
							</Grid>
						</Grid>
						<Grid container spacing={2} className={classes.containerInput}>
							<Grid item xs={12} md={4}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Teléfono'}</p>
								<TextField
									className={classes.customTextField}
									id="phoneNumber"
									name={`phoneNumber`}
									variant="outlined"
									required={true}
									onChange={formik.handleChange}
									value={formik.values.phoneNumber}
									fullWidth
									helperText={<ErrorMessage name="phoneNumber" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
									inputProps={{ maxLength: 9, minLength: 9 }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<PhoneIcon />
												<span className={classes.spanPhone}>+56</span>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Rol'}</p>
								<Autocomplete
									id="roles"
									onChange={(e, value: any) => {
										formik.setFieldValue(`role`, value)
									}}
									options={rolesList.map(option => option)}
									getOptionLabel={(option: any) => (option ? option['name'] : '')}
									value={formik.values.role}
									renderInput={params => (
										<TextField {...params} name="roles" required={true} value={formik.values.role} variant="outlined" className={classes.customAutocompleteField} />
									)}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Compañía'}</p>
								<Autocomplete
									id="companies"
									onChange={(e, value: any) => {
										if (value?.id) {
											handleStoresByCompany(value.id)
										} else {
											formik.setFieldValue(`stores`, [])
											formik.setFieldValue(`costCenters`, [])
										}
										formik.setFieldValue(`company`, value)
									}}
									options={companies.map(option => option)}
									value={formik.values.company}
									getOptionLabel={(option: any) => (option ? option['name'] : '')}
									renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)}
									renderInput={params => <TextField {...params} name="companies" value={formik.values.company} variant="outlined" className={classes.customAutocompleteField} />}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} className={classes.containerInput}>
							{(formik.values.role.name === 'USUARIO SUCURSAL' || formik.values.role.name === 'USUARIO CENTRO COSTO') && (
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Sucursal'}</p>
									<Autocomplete
										multiple
										id="stores"
										disabled={stores.length === 0}
										onChange={(e, value: any) => {
											costCenters.splice(0, costCenters.length)
											formik.setFieldValue(`stores`, value)
											handleCCbyStores(value)
										}}
										options={stores}
										value={formik.values.stores}
										getOptionLabel={(option: any) => (option ? option['name'] : '')}
										renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)}
										renderInput={params => <TextField {...params} name="stores" value={formik.values.stores} variant="outlined" className={classes.customAutocompleteField} />}
									/>
								</Grid>
							)}
							{formik.values.role.name === 'USUARIO CENTRO COSTO' && (
								<Grid item xs={12} md={6}>
									<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Centro de Costo'}</p>
									<Autocomplete
										multiple
										id="costCenters"
										disabled={costCenters.length === 0}
										onChange={(e, value: any) => {
											formik.setFieldValue(`costCenters`, value)
										}}
										groupBy={option => option.store.name}
										value={formik.values.costCenters}
										options={costCenters.map(option => option)}
										getOptionLabel={(option: any) => (option ? option['name'] : '')}
										renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)}
										renderInput={params => (
											<TextField {...params} name="costCenters" value={formik.values.costCenters} variant="outlined" className={classes.customAutocompleteField} />
										)}
									/>
								</Grid>
							)}
						</Grid>
						<Grid container spacing={2} className={classes.containerInput}>
							{!userEdit && (
								<>
									<Grid item xs={12} md={6}>
										<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Contraseña'}</p>
										<TextField
											className={classes.customTextField}
											id="password"
											name="password"
											variant="outlined"
											onChange={formik.handleChange}
											value={formik.values.password}
											fullWidth
											type={watchPass ? 'text' : 'password'}
											required={true}
											helperText={<ErrorMessage name="password" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="end">
														{watchPass ? (
															<VisibilityOffIcon className={classes.visibility} onClick={() => setWatchPass(false)} />
														) : (
															<VisibilityIcon className={classes.visibility} onClick={() => setWatchPass(true)} />
														)}
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Reingresa contraseña'}</p>
										<TextField
											className={classes.customTextField}
											id="passwordConfirm"
											name="passwordConfirm"
											variant="outlined"
											onChange={formik.handleChange}
											value={formik.values.passwordConfirm}
											fullWidth
											type={watchPass ? 'text' : 'password'}
											required={true}
											helperText={<ErrorMessage name="passwordConfirm" render={msg => <Typography className={classes.errorValidation}>{msg}</Typography>} />}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="end">
														{watchPass ? (
															<VisibilityOffIcon className={classes.visibility} onClick={() => setWatchPass(false)} />
														) : (
															<VisibilityIcon className={classes.visibility} onClick={() => setWatchPass(true)} />
														)}
													</InputAdornment>
												),
											}}
										/>
									</Grid>
								</>
							)}
							<Grid item xs={12} md={12} style={{ textAlign: 'center', paddingTop: '2%' }}>
								<Button className={`${classes.actionButton} ${isMobile && 'w-100'}`} type="submit" variant={'contained'}>
									{userEdit?.id ? 'Confirmar Edición' : 'Agregar'}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</FormikProvider>
		</Grid>
	) : (
		<Box className={classes.boxLoading}>
			<CircularProgress size={34} className={classes.customProgress} />
			<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'}>{sendDataMessage}</p>
		</Box>
	)
}

const roles = [
	{
		id: 'be8ac202-d8bb-4bad-802b-e6066a500480',
		name: 'USUARIO SUCURSAL',
	},
	{
		id: 'cc6e9db2-f566-4286-af0e-89b43da6026d',
		name: 'ADMINISTRADOR',
	},
	{
		id: 'ce9c6c6e-a9eb-4161-825e-cc15a7e47542',
		name: 'USUARIO CENTRO COSTO',
	},
	{
		id: 'db47e049-d43f-4b6f-9f9a-8403816aa1cf',
		name: 'USUARIO EMPRESA',
	},
	{
		id: 'e6b920cb-5526-43a7-a900-694784ca1b2b',
		name: 'SUPERADMIN',
	},
]
