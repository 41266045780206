import { CircularProgress, createStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme, withStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useNotification } from '../../../hooks/useNotification'
import { getVisits } from '../../../providers/Private/Provider'

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

export const OptimizedRoute = props => {
	const { visitId, collaborator, nodes } = props
	const [visit, setVisit] = useState<any>()
	const [loader, setLoader] = useState(true)
	const [handleNotification] = useNotification()

	useEffect(() => {
		getVisits(visitId)
			.then(response => {
				if (response && response.success) {
					setVisit(response.data)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.finally(() => {
				setLoader(false)
			})
	}, [visitId])

	const getServiceStatus = (value: any) => {
		switch (value) {
			case 0:
				return 'No Iniciado'
			case 1:
				return 'Iniciado'
			case 2:
				return 'Completado'
			case 3:
				return 'Finalizado'
			default:
				return 'Desconocido'
		}
	}

	if (loader) {
		return (
			<div style={{ width: '100%', textAlign: 'center', padding: '1%' }}>
				<CircularProgress />
			</div>
		)
	}

	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<StyledTableCellHeader>{'Nombre del pasajero'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Dirección'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Teléfono'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Orden de Recogida'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Hora'}</StyledTableCellHeader>
					<StyledTableCellHeader>{'Estado'}</StyledTableCellHeader>
				</TableRow>
			</TableHead>
			<TableBody>
				<StyledTableRowBody>
					<StyledTableCellBody>{visit?.address?.collaborator?.fullName ? visit.address.collaborator.fullName : 'Sin Nombre'}</StyledTableCellBody>
					<StyledTableCellBody>
						{visit?.address?.street ? visit.address.street : 'Sin Calle'}
					</StyledTableCellBody>
					<StyledTableCellBody>{visit?.address?.collaborator?.phoneNumber ? visit.address.collaborator.phoneNumber : 'Sin Teléfono'}</StyledTableCellBody>
					<StyledTableCellBody>{nodes?.order ? nodes.order : 'Sin Orden'}</StyledTableCellBody>
					<StyledTableCellBody>{nodes?.arrival ? nodes.arrival.substring(0, nodes.arrival.length - 3) : 'Sin Tiempo'}</StyledTableCellBody>
					<StyledTableCellBody>{visit?.nodes?.length > 0 ? getServiceStatus(visit?.nodes[0]?.tour?.statusOrder) : 'Sin Estado'}</StyledTableCellBody>
				</StyledTableRowBody>
			</TableBody>
		</Table>
	)
}
