import { useState } from 'react'
import { createStyles, makeStyles, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Theme } from '@material-ui/core/styles'
import { useNotification } from '../../hooks/useNotification'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		buttonActionSave: {
			'backgroundColor': '#0D1373',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 240,
			'opacity': 1,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		custDialogContent: {
			padding: '1.5rem 24px',
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem',
		},
		customFormLabel: {
			'marginTop': '1rem',
			'& .MuiFormControlLabel-label': {
				color: '#000000',
				fontFamily: 'Lato-Regular',
			},
		},
		buttonProgress: {
			color: '#FFFFFF',
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
			opacity: 0.8,
		},
	})
)

export const RemoveUserDialog = ({ handleDialog, open, user }) => {
	const [removed, setRemoved] = useState(false)
	const classes = useStyles()
	const [sendingData, setSendingData] = useState(false)
	const [handleNotification] = useNotification()

	const removeStore = (userId: string) => {
		setSendingData(true)
		console.log(`Usuario Eliminado`)
		handleNotification('Usuario eliminado con éxito', 'success')
        handleDialog()
		setSendingData(false)

	}

	const handleRemovedChange = () => {
		setRemoved(!removed)
	}

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg">
			<DialogTitle>
				<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>{'Eliminar Usuario'}</h1>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent classes={{ root: classes.custDialogContent }}>
				<p className={'ct-black ff-LatoRegular fs16 m-0'}>
					¿Estás seguro que quieres eliminar al usuario <span className="fw-bold">{`${user.firstName}`}</span>?
				</p>
				<FormControlLabel
					classes={{ root: classes.customFormLabel }}
					control={<Checkbox checked={removed} onChange={handleRemovedChange} name="checkedB" color="primary" />}
					label="Sí, eliminar"
				/>
			</DialogContent>
			<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
				<Button classes={{ root: classes.buttonActionSave }} color="primary" disabled={!removed} onClick={() => removeStore(user.id)} variant="contained">
					{'Eliminar'}
					{sendingData && <CircularProgress size={24} className={classes.buttonProgress} />}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
