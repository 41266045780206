import { Fragment, useState, useEffect } from 'react'
import {
	Box,
	Button,
	Container,
	createStyles,
	Grid,
	makeStyles,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	withStyles,
	Theme,
	Select,
	MenuItem,
	Collapse,
	CircularProgress,
} from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'

import { CreatedRoute } from './RoutesStates/CreatedRoute'
import { OptimizedRoute } from './RoutesStates/OptimizedRoute'
import { EditedRoute } from './RoutesStates/EditedRoute'
import { ApprovedRoute } from './RoutesStates/ApprovedRoute'

import ExpandMore from '@material-ui/icons/ExpandMore'

import { getCollbaratorFiltered, getStores, getVisitFromCollaborator, postGetCCByStore, getReportExcelCollaborator } from '../../providers/Private/Provider'

import { useNotification } from '../../hooks/useNotification'
import { validate, format as rutFormat } from 'rut.js'

//time manager
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import es from 'date-fns/locale/es'

const es_ES = require('date-fns/locale/es')
const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
			fontFamily: 'Lato-Regular',
		},
		customLabel: {
			marginBottom: '5px',
		},
		customTextField: {
			'& .MuiInputBase-input': {
				padding: '14px',
			},
		},
		failMessage: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 600px)',
			justifyContent: 'center',
			alignContent: 'center',
			margin: 'auto',
		},
		customTabsIndicator: {
			display: 'none',
		},
		customTab: {
			border: '2px solid',
			borderColor: 'gray',
			color: '#113047',
			flexShrink: 'initial',
			fontFamily: 'Lato-Regular',
			fontSize: '15px',
			height: 64,
			maxWidth: '100%',
			minWidth: '0',
			textTransform: 'uppercase',
			width: '100%',
		},
		activeTab: {
			border: '1px solid',
			borderBottom: 'none',
			borderColor: '#113047',
			color: '#FFFFFF',
			fontFamily: 'Lato-Bold',
			height: 64,
			textTransform: 'uppercase',
			backgroundColor: '#0D1373',
		},
		customProgress: {
			color: '#0D1373',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		editRoute: {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		custCell: {
			padding: '0 !important',
		},
		boxHeaderRoute: {
			backgroundColor: 'rgba(17, 48, 71, 0.2)',
			margin: '2rem 0 2rem 0',
			padding: '0 1rem',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		downloadButton: {
			'color': '#FFFFFF',
			'backgroundColor': '#008589',
			'&:hover': {
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			letterSpacing: '0.00938em',
			lineHeight: 1.5,
			position: 'absolute',
		},
	})
)

export const SearchCollaborator = () => {
	let today = new Date()
	let oneWeekAgo = new Date(today.getTime() - 168 * 60 * 60 * 1000)
	let excelUrl: any
	const classes = useStyles()
	const [filters, setFilters] = useState({
		rut: '',
		store: 0,
		costCenter: 0,
		status: 4,
		startDate: oneWeekAgo,
		endDate: today,
	})
	const [responseData, setResponseData] = useState([])
	const [stores, setStores] = useState<any>([{ name: 'Todas', id: 0, state: 1, address: { street: '', number: '' } }])
	const [costCenters, setCostCenters] = useState<any>([{ name: 'Todas', id: 0, state: 1, address: { street: '', number: '' } }])
	const [handleNotification] = useNotification()
	const [error, setError] = useState(false)
	const [loaderData, setLoaderData] = useState(false)
	const [loaderStores, setLoaderStores] = useState(true)
	const [loaderCollaborators, setLoaderCollaborators] = useState(true)

	const [collaborators, setCollaborators] = useState([])
	const [collaboratorsSelected, setCollaboratorsSelected] = useState<any>({})
	const [openPassengers, setOpenPassengers] = useState()
	const [rut, setRut] = useState('')
	const [inputRut, setInputRut] = useState('')
	const [rutError, setRutError] = useState(false)
	const [openAutomcomplete, setOpenAutomcomplete] = useState(true)
	const [entryData, setEntryData] = useState([])
	const [exitData, setExitData] = useState([])

	const getAllStores = () => {
		getStores()
			.then(response => {
				if (response && response.success) {
					setStores([...stores, ...response.data])
					setError(false)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(error => {
				console.error('STORES_ERROR', error)
				handleNotification('Ha ocurrido un error', 'error')
				setError(true)
			})
			.finally(() => {
				setLoaderStores(false)
			})
	}

	const storeAddress = store => {
		return store.id > 0 ? (store.address !== null ? `(${store.address.street})` : 'Sin Dirección') : ''
	}

	const formatDateToSeach = (date: any) => {
		return format(date, 'yyyy-MM-dd', { locale: es_ES }).toString()
	}

	useEffect(() => {
		getAllStores()
		getCollaborators()
	}, [])

	useEffect(() => {
		if (collaboratorsSelected?.fullName) {
			setLoaderData(true)
			getVisitFromCollaborator(collaboratorsSelected.id)
				.then(response => {
					if (response && response.success) {
						setResponseData(response.data?.addresses[0].visits.sort((a, b) => new Date(a.route?.startDate).getTime() - new Date(b.route?.startDate).getTime()))
					} else {
						handleNotification('ha ocurrido un error', 'error')
					}
				})
				.finally(() => setLoaderData(false))
		}
	}, [collaboratorsSelected])

	const [valueTab, setValueTab] = useState(0)

	const handleTabChange = (event, newValue) => {
		if (entryData.length > 0 && exitData.length > 0) {
			setValueTab(newValue)
		} else if (exitData.length > 0) {
			setValueTab(1)
		} else if (entryData.length > 0) {
			setValueTab(0)
		}
	}

	const handleStoreChange = event => {
		const { value } = event.target
		setFilters({ ...filters, store: value })
		if (value !== 0) {
			postGetCCByStore({ id: value }).then(response => {
				if (response && response.success) {
					setCostCenters([...costCenters, ...response.data])
				}
			})
		}
	}

	const handleCostCenterChange = event => {
		const { value } = event.target
		setFilters({ ...filters, costCenter: value })
	}

	const handleStatusChange = event => {
		const { value } = event.target
		setFilters({ ...filters, status: value })
	}

	const handleDateChange = (date, type) => {
		if (type === 0) {
			if (format(date, 'yyyy-MM-dd').toString() > format(filters.endDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, startDate: date })
			}
		} else if (type === 1) {
			if (format(date, 'yyyy-MM-dd').toString() < format(filters.startDate, 'yyyy-MM-dd').toString()) {
				setFilters({ ...filters, startDate: date, endDate: date })
			} else {
				setFilters({ ...filters, endDate: date })
			}
		}
	}

	const getCollaborators = () => {
		getCollbaratorFiltered().then(response => {
			if (response && response.success) {
				setCollaborators(response.data.sort((a, b) => a.rut - b.rut))
			}
			setLoaderCollaborators(false)
		})
	}

	const handleCollaboratorSelect = value => {
		if (value) {
			setOpenAutomcomplete(false)
			setCollaboratorsSelected(collaborators.filter((collaborator: any) => collaborator.rut === value)[0])
		}
	}

	const handleRutChange = (rut: any) => {
		setInputRut(rut)
		setFilters({ ...filters, rut: rutFormat(rut) })
	}

	useEffect(() => {
		rut.length >= 3 && setOpenAutomcomplete(true)
	}, [rut])

	const handleSearchCollaborator = () => {
		setLoaderData(true)
		let formatFilters: string = '?'
		const { rut, status, costCenter, store, startDate, endDate } = filters
		formatFilters = rut !== '' ? formatFilters + `rut=${rut}` : formatFilters
		formatFilters = store !== 0 ? formatFilters + `store=${store}` : formatFilters
		formatFilters = status !== 4 ? formatFilters + `&status=${status}` : formatFilters
		formatFilters = costCenter !== 0 ? formatFilters + `&costCenter=${costCenter}` : formatFilters
		formatFilters = formatFilters + `&startDate=${formatDateToSeach(startDate)}&endDate=${formatDateToSeach(endDate)}`
		getVisitFromCollaborator(formatFilters)
			.then(response => {
				if (response && response.success) {
					setResponseData(response.data?.addresses[0].visits.sort((a, b) => new Date(a.route.startDate).getTime() - new Date(b.route.startDate).getTime()))
				} else {
					handleNotification(`ha ocurrido un error: ${response.message ? response.message : ''}`, 'error')
				}
			})
			.finally(() => setLoaderData(false))
	}

	useEffect(() => {
		if (responseData && responseData.length > 0) {
			setEntryData(responseData.filter((visit: any) => visit.route.serviceType === 0))
			setExitData(responseData.filter((visit: any) => visit.route.serviceType === 1))
		}
	}, [responseData])

	useEffect(() => {
		if (entryData.length > 0 || exitData.length > 0) {
			setValueTab(entryData.length > 0 ? 0 : 1)
		}
	}, [entryData, exitData])

	const formatDate = (date: any) => {
		return format(date, 'dd-MM-yyyy', { locale: es_ES }).toString()
	}

	const downloadExcel = async () => {
		const { rut, status, costCenter, store, startDate, endDate } = filters
		let formatFilters: string = '?'
		formatFilters = rut !== '' ? formatFilters + `rut=${rut}` : formatFilters
		formatFilters = store !== 0 ? formatFilters + `store=${store}` : formatFilters
		formatFilters = status !== 4 ? formatFilters + `&status=${status}` : formatFilters
		formatFilters = costCenter !== 0 ? formatFilters + `&costCenter=${costCenter}` : formatFilters
		formatFilters = formatFilters + `&startDate=${formatDateToSeach(startDate)}&endDate=${formatDateToSeach(endDate)}`

		getReportExcelCollaborator(formatFilters, 'Colaboradores')
	}

	const handleOpenCollapse = (value: any) => {
		setOpenPassengers(openPassengers === value ? false : value)
	}

	const resumeType = (value: number, visitId: string, nodes: any) => {
		switch (value) {
			case 0:
				return <CreatedRoute visitId={visitId} collaborator={collaboratorsSelected} />
			case 1:
				return <OptimizedRoute visitId={visitId} collaborator={collaboratorsSelected} nodes={nodes[0]} />
			case 2:
				return <EditedRoute visitId={visitId} collaborator={collaboratorsSelected} />
			case 3:
				return <ApprovedRoute visitId={visitId} collaborator={collaboratorsSelected} nodes={nodes[0]} />
			default:
				return <CreatedRoute visitId={visitId} collaborator={collaboratorsSelected} />
		}
	}

	const getStatusName = (status: number) => {
		switch (status) {
			case 0:
				return 'CREADA'
			case 1:
				return 'OPTIMIZADA'
			case 2:
				return 'EDITADA'
			case 3:
				return 'APROBADA'
			default:
				return 'DESCONOCIDO'
		}
	}

	if (loaderCollaborators) {
		return (
			<div style={{ width: '100%', textAlign: 'center', paddingTop: '15%' }}>
				<CircularProgress style={{ color: '#0D1373' }} />
			</div>
		)
	}

	const OPTIONS_LIMIT = 5
	const defaultFilterOptions = createFilterOptions()

	const filterOptions = (options, state) => {
		return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)
	}

	return (
		<Container maxWidth={'xl'}>
			<div className={classes.container}>
				<h2 className={'ct-black ff-LatoRegular fs25'}>{'Buscar Colaborador'}</h2>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Escriba el rut del colaborador'}</p>
						<TextField
							className={classes.customTextField}
							id="fullRut"
							variant="outlined"
							required={true}
							onChange={event => handleRutChange(event.target.value)}
							value={rutFormat(inputRut)}
							fullWidth
							helperText={rutError && <p className={classes.error + ' ' + 'm-0'}>{'Debes ingresar un RUT valido'}</p>}
							inputProps={{
								maxLength: 12,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Sucursal'}</p>
						<Select id="office" name="office" variant="outlined" defaultValue={0} className={classes.customTextField} fullWidth onChange={handleStoreChange}>
							{stores.map(
								(store, index) =>
									store.state === 1 && (
										<MenuItem key={index} value={store.id} classes={{ root: classes.customMenuItem }}>
											{`${store.name} ${storeAddress(store)}`}
										</MenuItem>
									)
							)}
						</Select>
					</Grid>
					<Grid item xs={12} md={4}>
						<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Centro de costo'}</p>
						<Select id="costCenters" name="office" variant="outlined" defaultValue={0} className={classes.customTextField} fullWidth onChange={handleCostCenterChange}>
							{costCenters.map((costCenter, index) => (
								<MenuItem key={index} value={costCenter.id} classes={{ root: classes.customMenuItem }}>
									{costCenter.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid item xs={12} md={3}>
						<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Estado'}</p>
						<Select id="status" name="status" variant="outlined" defaultValue={4} className={classes.customTextField} fullWidth onChange={handleStatusChange}>
							{statusList.map((status, index) => (
								<MenuItem key={index} value={status.id} classes={{ root: classes.customMenuItem }}>
									{status.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
						<Grid item xs={12} md={3}>
							<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Inicio'}</p>
							<DatePicker
								autoOk
								className={classes.customTextField}
								format={'dd/MM/yyyy'}
								fullWidth
								inputVariant="outlined"
								onChange={date => handleDateChange(date, 0)}
								value={filters.startDate}
								emptyLabel="Seleccione fecha de inicio"
								variant="inline"
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Fecha Fin'}</p>
							<DatePicker
								autoOk
								className={classes.customTextField}
								format={'dd/MM/yyyy'}
								fullWidth
								inputVariant="outlined"
								onChange={date => handleDateChange(date, 1)}
								value={filters.endDate}
								emptyLabel="Seleccione fecha de fin"
								variant="inline"
							/>
						</Grid>
						<Grid item xs={12} md={3} style={{ marginTop: 'auto', marginBottom: '8px' }}>
							<Button fullWidth style={{ backgroundColor: '#0D1373', color: '#FFFFFF' }} variant="contained" onClick={() => handleSearchCollaborator()}>
								Buscar
							</Button>
						</Grid>
					</MuiPickersUtilsProvider>
				</Grid>
				{collaboratorsSelected?.fullName !== undefined && (
					<Grid container className={classes.boxHeaderRoute} style={{ padding: '1rem' }}>
						<Grid item xs={12} md={4}>
							<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Nombre</p>
							{collaboratorsSelected.fullName}
						</Grid>
						<Grid item xs={12} md={4}>
							<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>Teléfono</p>
							{collaboratorsSelected.phoneNumber}
						</Grid>
						<Grid item xs={12} md={4}>
							<p className={'ct-light-black ff-LatoRegular fs16 m-0 tt-upper'}>E-mail</p>
							{collaboratorsSelected.email}
						</Grid>
					</Grid>
				)}
				<Box>
					{!loaderData ? (
						responseData?.length > 0 ? (
							<Fragment>
								<Tabs
									variant="fullWidth"
									value={valueTab}
									onChange={handleTabChange}
									classes={{
										indicator: classes.customTabsIndicator,
									}}
									className={'mt-2r'}
								>
									{entryData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 0 ? classes.activeTab : ''} label="Ingreso" />}
									{exitData.length > 0 && <Tab classes={{ root: classes.customTab }} className={valueTab === 1 ? classes.activeTab : ''} label="Regreso" />}
								</Tabs>
								<Grid container>
									<TableContainer component={Paper} style={{ borderRadius: '0px' }}>
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<StyledTableCellHead>{'Fecha'}</StyledTableCellHead>
													<StyledTableCellHead>{'Sucursal'}</StyledTableCellHead>
													<StyledTableCellHead>{'Tipo'}</StyledTableCellHead>
													<StyledTableCellHead>{'Hora'}</StyledTableCellHead>
													<StyledTableCellHead>{'Nombre de ruta'}</StyledTableCellHead>
													<StyledTableCellHead>{'Estado'}</StyledTableCellHead>
													<StyledTableCellHead>
														<Button
															download
															href={excelUrl}
															classes={{ root: classes.buttonTableAction }}
															className={classes.downloadButton}
															color="primary"
															variant="contained"
															onClick={() => downloadExcel()}
														>
															Descargar Todo
														</Button>
													</StyledTableCellHead>
												</TableRow>
											</TableHead>
											<TableBody>
												{responseData.map(
													(visit: any, index: number) =>
														visit.route.serviceType === valueTab && (
															<>
																<StyledTableRowBody key={visit.id}>
																	<StyledTableCellBody>{formatDate(new Date(`${visit.route.startDate}T00:00:00`))}</StyledTableCellBody>
																	<StyledTableCellBody>{visit.route.shift.store.name}</StyledTableCellBody>
																	<StyledTableCellBody>{visit.route.serviceType === 0 ? 'INGRESO' : 'REGRESO'}</StyledTableCellBody>
																	<StyledTableCellBody>{visit.route.entryTime.substring(0, visit.route.entryTime.length - 3)}</StyledTableCellBody>
																	<StyledTableCellBody>{visit.route.shift.shiftName}</StyledTableCellBody>
																	<StyledTableCellBody>{getStatusName(visit.route.state)}</StyledTableCellBody>
																	<StyledTableCellBody>
																		<Button
																			classes={{
																				root: classes.buttonSee,
																			}}
																			color="primary"
																			onClick={() => {
																				handleOpenCollapse(index)
																			}}
																			endIcon={<ExpandMore />}
																		>
																			{'Detalles'}
																		</Button>
																	</StyledTableCellBody>
																</StyledTableRowBody>
																<StyledTableRowBody>
																	<StyledTableCellHead className={classes.custCell} colSpan={7}>
																		<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
																			{resumeType(visit.route.state, visit.id, visit.nodes)}
																		</Collapse>
																	</StyledTableCellHead>
																</StyledTableRowBody>
															</>
														)
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Fragment>
						) : (
							<Box className={classes.failMessage}>
								<Grid container>
									<Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
										<p className={'ct-light-dark-gray ff-LatoRegular fs30 m-0'}>{collaboratorsSelected?.fullName ? 'Sin coincidencias' : 'Seleccione el rut del colaborador'}</p>
									</Grid>
								</Grid>
							</Box>
						)
					) : (
						<div style={{ width: '100%', textAlign: 'center', paddingTop: '5%' }}>
							<CircularProgress />
						</div>
					)}
				</Box>
			</div>
		</Container>
	)
}

const statusList = [
	{
		id: 4,
		name: 'Todo',
	},
	{
		id: 0,
		name: 'Creada',
	},
	{
		id: 1,
		name: 'Optimizada',
	},
	{
		id: 2,
		name: 'Editada',
	},
	{
		id: 3,
		name: 'Aprobada',
	},
]
