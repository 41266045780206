import { FC, useContext, useEffect } from 'react'
import { Box, Button, createStyles, Grid, Container, makeStyles, Theme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Routes from '../assets/img/routesBgV2.png'
import Eye from '../assets/img/icons/MyRoutes.png'
import Route from '../assets/img/icons/CreateRoute.svg'
import { useMediaQuery } from 'react-responsive'
import { UserContext } from '../context/UserContext'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxCover: {
			alignItems: 'center',
			backgroundImage: `url(${Routes})`,
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			display: 'flex',
			height: 'calc(100vh)',
			justifyContent: 'center',
			// marginTop: 80,
			width: '100%',
			// backgroundColor: '#134288',
			// backgroundBlendMode: 'multiply',
		},
		boxContent: {
			margin: '0 auto',
			width: 500,
		},
		boxContentMob: {
			width: '100%',
		},
		itemFullCenter: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			margin: '1.5rem 0',
		},
		customButton: {
			'backgroundColor': 'transparent',
			'border': '2px',
			'borderColor': 'white',
			'borderRadius': '100px',
			'borderStyle': 'solid',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 30,
			'height': 120,
			'justifyContent': 'space-evenly',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover, &:focus': {
				background: '#2A4C9C',
				borderColor: '#F28F16',
				color: '#F28F16',
			},
			'& img': {
				height: 'auto',
				width: '55px',
			},
		},
		customButtonMob: {
			'backgroundColor': 'transparent',
			'border': '2px',
			'borderColor': 'white',
			'borderRadius': '100px',
			'borderStyle': 'solid',
			'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 22,
			'height': 100,
			'justifyContent': 'space-evenly',
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover, &:focus': {
				background: '#0D1373',
			},
			'& img': {
				height: 'auto',
				width: '55px',
			},
		},
		fs22: {
			fontSize: 22,
		},
		fs30: {
			fontSize: 30,
		},
	})
)

const CreateRoutePage: FC = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const classes = useStyles()
	const { selectedOption, setSelectedOption, setRoutesSection } = useContext(UserContext)

	useEffect(() => {
		setRoutesSection(true)
		setSelectedOption({ ...selectedOption, item: 3 })
	}, [])

	return (
		<Box className={classes.boxCover}>
			<Container>
				<Box className={isMobile ? classes.boxContentMob : classes.boxContent}>
					<Grid container alignItems="center">
						<Grid item xs={12} md={12} className={classes.itemFullCenter}>
							<Button className={isMobile ? classes.customButtonMob : classes.customButton} color="primary" component={Link} fullWidth to={'/new-route/common-route'} variant="contained">
								<img alt={'Street Route'} src={Route} />
								{'CREAR RUTA'}
							</Button>
						</Grid>
						<Grid item xs={12} md={12} className={classes.itemFullCenter}>
							<Button className={isMobile ? classes.customButtonMob : classes.customButton} color="primary" component={Link} fullWidth to={'/my-routes'} variant="contained">
								<img alt={'Street Route'} src={Eye} />
								{'MIS RUTAS'}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}

export default CreateRoutePage
