import {
	createStyles,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import { Form, Formik, ErrorMessage } from 'formik'
import Title from './Tools/Title'
import ButtonAction from './Tools/ButtonAction'
import ButtonLink from './Tools/ButtonLink'
import { recoveryPasswordSchema } from '../../validations/authSchema'

const useStyles = makeStyles(() =>
	createStyles({
    customTextField: {
      'margin': '1rem 0',
      '& label': {
        color: '#FFFFFF !important',
        fontFamily: 'Lato-Regular',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#F2F3F8',
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#F2F3F8',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',
      },
    },
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
    input: {
      color: '#FFFFFF !important',
      fontFamily: 'Lato-Regular',
    },
	})
)

const RecoveryPasswordForm = ({ handleRecoveryPassword, setAction, sendingData }) => {
	const classes = useStyles()

	return (
    <>
      <Title label={'Recuperar Contraseña'}/>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={recoveryPasswordSchema}
        onSubmit={(values: IUserRecoveryPassword) => {
          handleRecoveryPassword(values)
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              classes={{ root: classes.customTextField }}
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              onChange={handleChange}
              value={values.email}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              helperText={
                <ErrorMessage
                  name="email"
                  render={msg => (
                    <Typography className={classes.error}>
                      {msg}
                    </Typography>
                  )}
                />
              }
            />
            <ButtonAction handleSubmit={handleSubmit} label={'Recuperar Contraseña'} sendingData={sendingData} />
          </Form>
        )}
      </Formik>
      <ButtonLink type={'login'} setAction={setAction} label={'Volver a Iniciar Sesión'} />
    </>
	)
}

export default RecoveryPasswordForm
