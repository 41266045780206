import { response, responseExcel, responseCreateExcel } from './Client'

// SHIFTS
export const getShift = (id: string) => {
	const options = {
		url: `/shifts/${id}`,
	}
	return response(options)
}

export const getShifts = (filters: string = '') => {
	const options = {
		url: `/shifts${filters}`,
	}
	return response(options)
}

export const putMaxTimeShifts = (id: string, data) => {
	const options = {
		method: 'PUT',
		url: `/shifts/${id}/change-maxtime`,
		data,
	}
	return response(options)
}

export const deleteShift = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/shifts/${id}`,
		data,
	}
	return response(options)
}

export const putShiftNormalize = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/shifts/${id}/normalize-addresses`,
	}
	return response(options)
}

export const putShiftOptimize = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/shifts/${id}/optimize`,
	}
	return response(options)
}

export const getShiftsOptimized = (id: string) => {
	const options = {
		url: `/shifts/${id}/optimized`,
	}
	return response(options)
}

export const putApproveShift = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/shifts/${id}/approve-shift`,
	}
	return response(options)
}

// ROUTES

export const getRoute = (id: string) => {
	const options = {
		url: `/routes/${id}`,
	}
	return response(options)
}

export const getRouteOptimized = (id: string) => {
	const options = {
		url: `/routes/${id}/optimized`,
	}
	return response(options)
}

export const putRoute = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}`,
		data,
	}
	return response(options)
}

export const putRouteNormalize = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/normalize-addresses`,
	}
	return response(options)
}

export const putRouteOptimize = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/optimize`,
	}
	return response(options)
}

export const putPassengersExcel = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/upload-collaborators`,
		data,
	}
	return responseExcel(options)
}

export const postRoute = (data: any) => {
	const options = {
		method: 'POST',
		url: `/routes`,
		data,
	}
	return response(options)
}

export const putPassengersDynamicExcel = (params: any, data: any) => {
	const options = {
		method: 'PUT',
		url: `/routes/${params.id}/flexible-hours?maxPeopleCapacity=${params.maxPeople}&maxTime=${params.maxTime}`,
		data,
	}
	return responseExcel(options)
}

export const putApproveRoute = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/approve-route`,
	}
	return response(options)
}

export const putExtraHour = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/add-extra-hours`,
		data,
	}
	return response(options)
}

export const putAddCollaborator = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/add-collaborator/`,
		data,
	}
	return response(options)
}

export const cancelRoute = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/routes/${id}/cancel-route`,
	}
	return response(options)
}

// VISITS

export const deleteCollaborator = (id: string) => {
	const options = {
		method: 'DELETE',
		url: `/visits/${id}/delete-collaborator`,
	}
	return response(options)
}

export const putChangeRouteCollaborator = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/visits/${id}/change-route`,
		data,
	}
	return response(options)
}

export const putWrongAddresses = (data: any) => {
	const options = {
		method: 'PUT',
		url: `/visits/edit-addresses`,
		data,
	}
	return response(options)
}

export const postGetVisitServices = (data: any) => {
	const options = {
		method: 'POST',
		url: '/visits/get-visit-services',
		data,
	}
	return response(options)
}

export const getVisits = (id: string) => {
	const options = {
		url: `/visits/${id}`,
	}
	return response(options)
}

export const getCollaborator = (id: string) => {
	const options = {
		url: `/visits/${id}/get-info-collab`,
	}
	return response(options)
}

// COLLABORATORS

export const getCollbaratorFiltered = () => {
	const options = {
		url: `/collaborators`,
	}
	return response(options)
}

export const getVisitFromCollaborator = (filters: string = '') => {
	const options = {
		url: `/collaborators/get-visits${filters}`,
	}
	return response(options)
}

// STORES

export const putStoreNormalize = (id: string) => {
	const options = {
		method: 'PUT',
		url: `/stores/${id}/normalize-store`,
	}
	return response(options)
}

export const getStores = () => {
	const options = {
		url: `/stores`,
	}
	return response(options)
}

export const postStores = (data: any) => {
	const options = {
		method: 'POST',
		url: `/stores`,
		data,
	}
	return response(options)
}

export const putStores = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/stores/${id}`,
		data,
	}
	return response(options)
}

export const getStoreServices = (id: string, filters: string) => {
	const options = {
		url: `/stores/${id}/get-store-services${filters}`,
	}
	return response(options)
}

export const postStoresByCompany = (data: any) => {
	const options = {
		method: 'POST',
		url: `/stores/get-stores-by-company`,
		data,
	}
	return response(options)
}

// COMPANIES

export const getCompanies = () => {
	const options = {
		url: `/companies`,
	}
	return response(options)
}

export const postCompany = (data: any) => {
	const options = {
		method: 'POST',
		url: `/companies`,
		data,
	}
	return response(options)
}

export const putCompany = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/companies/${id}`,
		data,
	}
	return response(options)
}

// COST CENTER

export const postCostCenter = (data: any) => {
	const options = {
		method: 'POST',
		url: `/costCenter`,
		data,
	}
	return response(options)
}

export const putCostCenter = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/costCenter/${id}`,
		data,
	}
	return response(options)
}

export const postGetCCByStore = (data: any) => {
	const options = {
		method: 'POST',
		url: `/costCenter/get-cc-by-store`,
		data,
	}
	return response(options)
}

export const getCostCenters = () => {
	const options = {
		url: `/costCenter`,
	}
	return response(options)
}

// USERS

export const getUser = (id: string) => {
	const options = {
		method: 'GET',
		url: `/users/${id}`,
	}
	return response(options)
}

export const editUser = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/users/${id}`,
		data,
	}
	return response(options)
}

export const getUsers = () => {
	const options = {
		url: `/users`,
	}
	return response(options)
}

export const changePassword = (id: string, data: any) => {
	const options = {
		method: 'PUT',
		url: `/users/${id}/change-password`,
		data,
	}
	return response(options)
}

export const postUsers = (data: any) => {
	const options = {
		method: 'POST',
		url: `/users`,
		data,
	}
	return response(options)
}

// EXCEL

export const getDynamicExcel = (startDate: Date, endDate: Date) => {
	const options = {
		url: `/get-spreadsheet-link?startDate=${startDate}&endDate=${endDate}`,
	}
	return responseExcel(options)
}

export const getReportExcelCollaborator = (filters: string, excelName: string) => {
	const options = {
		method: 'GET',
		url: `/collaborators/download-services${filters}`,
		responseType: 'blob',
	}
	return responseCreateExcel(options, excelName)
}

export const getReportExcelRoute = (filters: string, excelName: string) => {
	const options = {
		method: 'GET',
		url: `/shifts/reports/download-excel-reports${filters}`,
		responseType: 'blob',
	}
	return responseCreateExcel(options, excelName)
}

export const getReportExcelVisits = (data: any, excelName: string) => {
	const options = {
		method: 'POST',
		url: `/visits/download-excel-visits`,
		responseType: 'blob',
		data,
	}
	return responseCreateExcel(options, excelName)
}


// Roles

export const getRoles = () => {
	const options = {
		url: `/roles`,
	}
	return response(options)
}
