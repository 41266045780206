import { useState, useEffect } from 'react'
import { Marker, GoogleMap, withScriptjs, withGoogleMap } from 'react-google-maps'
import Car from '../../assets/img/icons/car.png'
import mark from '../../assets/img/icons/marcador.png'
import person from '../../assets/img/icons/person.png'
import CollaboratorImg from '../../assets/img/icons/star.svg'

const Map = props => {
	const { vehicleLat, vehicleLon, passengerLat, passengerLon, passengers, currentId } = props

	const [lat, setLat] = useState(0)
	const [lon, setLon] = useState(0)
	const [passLat, setPassLat] = useState(0)
	const [passLon, setPassLon] = useState(0)
	const [markers, setmarkers] = useState([])

	useEffect(() => {
		setLat(parseFloat(vehicleLat))
		setLon(parseFloat(vehicleLon))
		setPassLat(parseFloat(passengerLat))
		setPassLon(parseFloat(passengerLon))
		fillMarkers()
	}, [vehicleLat, vehicleLon, passengerLat, passengerLon])

	const fillMarkers = () => {
		let newMarkers = passengers.map((passenger: any) => {
			if (passenger.confirmed === 0 && passengerLat !== passenger.address.latitude && passengerLon !== passenger.address.longitude) {
				return {
					lat: parseFloat(passenger.address.latitude),
					lon: parseFloat(passenger.address.longitude),
				}
			}
		})
		setmarkers(newMarkers.filter(o => o))
	}

	const defaultMapOptions = {
		fullscreenControl: false,
		scaleControl: false,
		streetViewControl: false,
		zoomControl: false,
		mapTypeControl: false,
		style: {
			transition: '0.5s',
		},
	}
	//17
	return (
		<GoogleMap defaultZoom={12} defaultCenter={{ lat: parseFloat(passengerLat), lng: parseFloat(passengerLon) }} defaultOptions={defaultMapOptions}>
			<Marker position={{ lat: passLat, lng: passLon }} icon={{ url: person, scaledSize: new window.google.maps.Size(40, 40) }} />
			<Marker position={{ lat: lat, lng: lon }} icon={{ url: Car, scaledSize: new window.google.maps.Size(40, 40) }} />
			{markers.length > 0 &&
				markers.map((marker: any) => {
					return <Marker position={{ lat: marker.lat, lng: marker.lon }} icon={{ url: mark, scaledSize: new window.google.maps.Size(40, 40) }} />
				})}
		</GoogleMap>
	)
}

export default withScriptjs(withGoogleMap(Map))
