import { createMuiTheme } from "@material-ui/core"

const defaultMaterialTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#0D1373',
			dark: '#0D1373',
			contrastText: '#FFFFFF',
		},
	},
})

export default defaultMaterialTheme