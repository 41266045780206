import * as Yup from 'yup'

export const CreateRouteSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Es necesario un nombre más largo')
		.max(200, 'El nombre es demasiado largo')
		.required('Debes ingresar un nombre')
		.nullable(),
	office: Yup.string().required('Debes seleccionar una sucursal').nullable(),
	maxPeopleCapacity: Yup.number()
		.required('Debes agregar un máximo de personas')
		.positive('Debe ser un número positivo mayor a 1')
		.nullable(),
	maxTime: Yup.number()
		.required('Debes agregar un máximo de tiempo')
		.positive('Debe ser un número positivo mayor a 1')
		.nullable(),
	periodicityType: Yup.string().required('Debes agregar un tipo de periodicidad').nullable(),
})

export const passengersManuallySchema = Yup.object().shape({
	passengerName: Yup.string()
		.min(3, 'Es necesario un nombre más largo')
		.required('Debes agregar un nombre')
		.nullable(),
	passengerCounty: Yup.number().required('Debes ingresar una comuna').nullable(),
	passengerStreet: Yup.string()
		.min(3, 'Es necesario un nombre más largo')
		.required('Debes ingresar una calle')
		.nullable(),
	passengerPhone: Yup.string().required('Debes ingresar un número de teléfono').nullable(),
	passengerMail: Yup.string().email('Email inválido').required('Campo requerido').nullable(),
})
