import { useState } from 'react'
import {
	createStyles,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
  IconButton
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { Form, Formik, ErrorMessage } from 'formik'
import Title from './Tools/Title'
import ButtonAction from './Tools/ButtonAction'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { restorePasswordSchema } from '../../validations/authSchema'

const useStyles = makeStyles(() =>
	createStyles({
    customTextField: {
      'margin': '1rem 0',
      '& label': {
        color: '#FFFFFF !important',
        fontFamily: 'Lato-Regular',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#F2F3F8',
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#F2F3F8',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',
      },
    },
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
    input: {
      color: '#FFFFFF !important',
      fontFamily: 'Lato-Regular',
    },
	})
)

const RestorePasswordForm = ({ handleRestorePassword, sendingData }) => {
  const [visibilityPassword, setVisibilityPassword] = useState(false)
	const classes = useStyles()

	return (
    <>
      <Title label={'Confirmar Contraseña'}/>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validationSchema={restorePasswordSchema}
        onSubmit={(values: IUserRestorePassword) => {
          handleRestorePassword(values)
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              className={classes.customTextField}
              color="primary"
              fullWidth
              id="password"
              label="Contraseña"
              type={visibilityPassword ? 'text' : 'password'}
              name="password"
              onChange={handleChange}
              value={values.password}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton className={'ct-white'} onClick={() => setVisibilityPassword(!visibilityPassword)}>
                    <InputAdornment position="end">
                      {visibilityPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  </IconButton>
                )
              }}
              helperText={
                <ErrorMessage
                  name="password"
                  render={msg => (
                    <Typography className={classes.error}>
                      {msg}
                    </Typography>
                  )}
                />
              }
            />
            <TextField
              className={classes.customTextField}
              color="primary"
              fullWidth
              id="confirmPassword"
              label="Confirmar Contraseña"
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              value={values.confirmPassword}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              helperText={
                <ErrorMessage
                  name="password"
                  render={msg => (
                    <Typography className={classes.error}>
                      {msg}
                    </Typography>
                  )}
                />
              }
            />
            <ButtonAction handleSubmit={handleSubmit} label={'Confirmar Contraseña'} sendingData={sendingData} />
          </Form>
        )}
      </Formik>
    </>
	)
}

export default RestorePasswordForm
