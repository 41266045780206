import { FC } from 'react'
import { Button, ButtonGroup, createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxButtonGroup: {
			height: '43px',
		},
		buttonGroup: {
			background: '#FFFFFF',
			borderColor: '#7E7E7E',
			color: '#7E7E7E',
			fontFamily: 'Lato-Regular',
		},
		activeButton: {
			'background': '#0D1373',
			'color': '#FFFFFF',
			'&:hover': {
				background: '#0D1373',
				color: '#FFFFFF',
				opacity: '0.9',
			},
		},
	})
)

const CustomButtonGroup: FC<CustomButtonGroupProps> = ({ buttonsList, buttonGroupSelection, setButtonGroupSelection }) => {
	const classes = useStyles()

	return (
		<ButtonGroup color="primary" fullWidth className={classes.boxButtonGroup}>
			{buttonsList.length > 0 &&
				buttonsList.map((button, index) => {
					return (
						<Button
							key={index}
							classes={{ root: classes.buttonGroup }}
							className={button.fontSize + ' ' + (buttonGroupSelection === index ? classes.activeButton : '')}
							color="primary"
							fullWidth
							onClick={() => setButtonGroupSelection(button.value)}
						>
							{button.label}
						</Button>
					)
				})}
		</ButtonGroup>
	)
}

export default CustomButtonGroup
