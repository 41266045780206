import { createContext, FC, useState } from 'react'

const appCtxDefaultValue = {
	user: { isLogged: false, user: { id: '', mail: '' } },
	setUser: (user: AppUserContextState) => {},
	logOut: () => {},
	routesSection: false,
	setRoutesSection: routesSection => {},
	selectedOption: { selected: false, item: 0 },
	setSelectedOption: (selectedOption: ISelectItem) => {},
}

export const UserContext = createContext(appCtxDefaultValue)

const UserProvider: FC = ({ children }) => {
	const [user, setUser] = useState(appCtxDefaultValue.user)
	const [routesSection, setRoutesSection] = useState(false)
	const [selectedOption, setSelectedOption] = useState(appCtxDefaultValue.selectedOption)
	const logOut = () => {
		setUser({ isLogged: false, user: { id: '', mail: '' } })
	}

	return <UserContext.Provider value={{ user, setUser, logOut, routesSection, setRoutesSection, selectedOption, setSelectedOption }}>{children}</UserContext.Provider>
}

export default UserProvider
