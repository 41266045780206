import { useState, Fragment, useEffect } from 'react'
import {
	createStyles,
	Grid,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import BusinessIcon from '@material-ui/icons/Business'
import ApartmentIcon from '@material-ui/icons/Apartment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { ErrorMessage } from 'formik'
const { validate, format } = require('rut.js')

const useStyles = makeStyles(() =>
	createStyles({
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customLabel: {
			marginBottom: '5px',
		},
		error: {
			color: 'red',
			fontFamily: 'Lato-Regular',
			fontSize: '0.8rem',
			fontWeight: 600,
			position: 'absolute',
		},
    spanPhone: {
      fontFamily: 'Lato-Regular',
      marginLeft: 20
    }
	})
)

const ProfileForm = ({ values, userData, setUserData, handleChange }) => {
	const classes = useStyles()
  const [rutValid, setRutValid] = useState(true)

  const handleRutChange = (value: any, field: string) => {
    let clone = JSON.parse(JSON.stringify(userData))

    for (const key in clone) {
      clone[field] = format(value)
    }

    setUserData(clone)
    values['rut'] = format(value)
  }

  const checkRut = (rut: any) => {
    values['rut'] = format(rut)
    validate(rut) ? setRutValid(true) : setRutValid(false) 
  }

  useEffect(() => {
    values.rut !== '' && checkRut(values.rut)
  }, [])

	return (
    <Fragment>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={4}>
          <p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Nombre'}</p>
          <TextField
            className={classes.customTextField}
            fullWidth
            helperText={<ErrorMessage name="firstName" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
            id="firstName"
            name="firstName"
            onChange={handleChange}
            type="text"
            value={values.firstName}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Apellido'}</p>
          <TextField
            className={classes.customTextField}
            fullWidth
            helperText={<ErrorMessage name="lastName" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
            id="lastName"
            name="lastName"
            onChange={handleChange}
            type="text"
            value={values.lastName}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Rut'}</p>
          <TextField
            className={classes.customTextField}
            fullWidth
            id="rut"
            name="rut"
            onBlur={event => checkRut(values.rut)}
            onChange={event => handleRutChange(event.target.value, 'rut')}
            type="text"
            value={userData.rut}
            variant="outlined"
            inputProps={{
              maxLength: 12,
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentIndIcon />
                </InputAdornment>
              ),
            }}
          />
          {!rutValid && <p className={`${classes.error} m-0`}>{'Debes ingresar un RUT valido'}</p>}
        </Grid>
        <Grid item xs={12} md={4}>
          <p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'Teléfono'}</p>
          <TextField
            className={classes.customTextField}
            fullWidth
            helperText={<ErrorMessage name="phoneNumber" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
            id="phoneNumber"
            inputProps={{ maxLength: 9 }}
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                  <span className={classes.spanPhone}>+56</span>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p className={`${classes.customLabel} ct-black ff-LatoRegular fs16`}>{'E-mail'}</p>
          <TextField
            className={classes.customTextField}
            fullWidth
            helperText={<ErrorMessage name="email" render={msg => <Typography className={classes.error}>{msg}</Typography>} />}
            id="email"
            name="email"
            onChange={handleChange}
            type="text"
            value={values.email}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              ),
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
	)
}

export default ProfileForm
