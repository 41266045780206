import { makeStyles, createStyles, Theme, Box, Grid, Button, CircularProgress } from '@material-ui/core'
import { useState, useEffect, FC } from 'react'
import { withScriptjs, withGoogleMap } from 'react-google-maps'
import Map from '../components/Map/Map'
import { getCollaboratorLocation } from '../providers/Public/Provider'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			background: '#EFF1F3',
		},
		styledParagraph:{
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			paddingTop: '2%'
		}
	})
)
export const TracingMapPage: FC = (props: any) => {
	const classes = useStyles()
	const id = props.match.params.id
	const [vehicleCoords, setVehicleCoords] = useState({ vehicleLat: '', vehicleLon: '' })
	const [userCoords, setUserCoords] = useState({ passengerLat: '', passengerLon: '' })
	const [passengersCoords, setPassengersCoords] = useState<any>()
	const [response, setResponse] = useState<any>()
	const [notFound, setNotFound] = useState(false)
	const [driver, setDriver] = useState({driverName: 'No Informado', driverLastName: ''})
	const [plate, setPlate] = useState('No Informado')

	useEffect(() => {
		if (userCoords.passengerLat !== '' && vehicleCoords.vehicleLat !== '') {
		}
		getLocation()
	}, [response])

	const getLocation = () => {
		getCollaboratorLocation(id).then(response => {
			if (response && response.success) {
				const { data } = response
				setVehicleCoords({
					vehicleLat: `${data.vehicleLat.slice(0, 3)}.${data.vehicleLat.slice(3, data.vehicleLat.length)}`,
					vehicleLon: `${data.vehicleLon.slice(0, 3)}.${data.vehicleLon.slice(3, data.vehicleLon.length)}`,
				})
				setUserCoords({ passengerLat: data.passengerLat, passengerLon: data.passengerLon })
				setPassengersCoords(data.passengers)
				setResponse(data)
				setPlate(response.status.data.order_status.resource.vehicle.properties.plate)
				setDriver({driverName: response.status.data.order_status.resource.driver.first_name, driverLastName: response.status.data.order_status.resource.driver.last_name})
			} else {
				setNotFound(true)
			}
		})
	}

	return !notFound ? (
		response ? (
			<Box className={classes.contentWrapper}>
				<p className={classes.styledParagraph}>{`Conductor: ${driver.driverName} ${driver.driverLastName}, Patente: ${plate}`}</p>
				<Map
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE}&v=3.exp&libraries=geometry,drawing,places`}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `800px` }} />}
					mapElement={<div style={{ height: `100%` }} />}
					vehicleLat={vehicleCoords.vehicleLat}
					vehicleLon={vehicleCoords.vehicleLon}
					passengerLat={userCoords.passengerLat}
					passengerLon={userCoords.passengerLon}
					passengers={passengersCoords}
					currentId={id}
				/>
			</Box>
		) : (
			<div style={{ width: '100%', textAlign: 'center' }}>
				<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'}>
					<CircularProgress style={{ color: '#0D1373' }} />
					<span style={{ paddingLeft: '2%' }}>{'Cargando..'}</span>
				</p>
			</div>
		)
	) : (
		<div style={{ width: '100%', textAlign: 'center' }}>
			<p className={'ct-light-black ff-LatoRegular fs24 m-0 tt-upper ml-1r'}>{'La ruta no está disponible'}</p>
		</div>
	)
}

export default withScriptjs(withGoogleMap(TracingMapPage))
