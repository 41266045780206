import { FC, useState, useEffect } from 'react'
import {
	createStyles,
	Grid,
	makeStyles,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ThemeProvider,
} from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import es from 'date-fns/locale/es'
import en from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers'
import { putExtraHour } from '../../../providers/Private/Provider'
import { useNotification } from '../../../hooks/useNotification'
import MainColorTheme from '../../utils/MainColorTheme'
const { format } = require('date-fns')

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#595B62',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(even)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
		},
	})
)(TableCell)

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			minWidth: 700,
			textAlign: 'center',
			width: '100%',
		},
		customDialog: {
			minWidth: 350,
		},
		check: {
			padding: '0',
			borderStyle: 'solid',
			color: 'lightgray',
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		customTextField: {
			'& .MuiInputBase-input': {
				fontFamily: 'Lato-Regular',
				padding: '14px',
			},
		},
		customAutocompleteField: {
			'fontFamily': 'Lato-Regular',
			'& .MuiAutocomplete-inputRoot': {
				fontFamily: 'Lato-Regular !important',
				paddingBottom: 4,
				paddingTop: 4,
			},
		},
		customMenuItem: {
			fontFamily: 'Lato-Regular',
		},
		buttonActionSave: {
			'backgroundColor': '#5E75B5',
			'borderRadius': 40,
			'color': '#FFFFFF',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'height': 49,
			'minWidth': 240,
			'opacity': 1,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#5E75B5',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		buttonCancel: {
			'backgroundColor': '#FFFFFF',
			'border': '1px solid #CDCDCD',
			'borderRadius': 40,
			'color': '#7E7E7E',
			'fontFamily': 'Lato-Regular',
			'fontSize': 16,
			'textTransform': 'capitalize',
			'transition': '0.3s',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				color: '#7E7E7E',
				cursor: 'pointer',
				opacity: '0.8',
			},
		},
		custDialogContent: {
			padding: '1.5rem 24px',
		},
		custDialogActions: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			padding: '1rem',
		},
		customFormLabel: {
			'marginTop': '1rem',
			'& .MuiFormControlLabel-label': {
				color: '#000000',
				fontFamily: 'Lato-Regular',
			},
		},
	})
)

const ExtraHourDialog: FC<ExtraHourDialogProps> = ({ handleDialog, open, route, setRoute, collaborators, setCollaborators, exitTime, setExitTime, startDate, setStartDate, minDay }) => {
	const classes = useStyles()
	const [handleNotification] = useNotification()
	const handleChangeCheck = (checked: boolean, index: number) => {
		let clone = collaborators
		clone[index]['check'] = checked
		setCollaborators(clone)
	}

	const setRouteData = () => {
		setCollaborators(collaborators)
	}

	const handleDateChange = (date: Date | null) => {
		setStartDate(setFormatDate(date))
	}
	const setFormatTime = (time: any) => {
		return format(time, 'HH:mm')
	}

	const setFormatDate = (date: any) => {
		return format(date, 'yyyy-MM-dd')
	}
	const setFormatDatePage = (date: any) => {
		return format(date, 'dd/MM/yyyy').toString()
	}
	const setFormatDatePagePicker = (date: any) => {
		return format(date, 'MM/dd/yyyy')
	}
	const saveExtraHour = () => event => {
		handleSubmitDialog()
	}

	const handleSubmitDialog = () => {
		const postData = setPostData(route)
		putExtraHour(route.id, postData)
			.then(response => {
				if (response && response.success) {
					handleDialog()
					handleNotification('Hora extra agregada con éxito', 'success')
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.catch(err => {
				console.log(err)
				handleNotification('Ha ocurrido un error con la hora extra', 'error')
			})
	}

	const setPostData = (data: any) => {
		
		const postData = {
			visits: collaborators.reduce((acc, curr) => {
				if (curr.check && curr.check === true) {
					acc.push(curr.id)
				}
				return acc
			}, []),
			startDate: startDate,
			hours: setFormatTime(exitTime),
		}

		return postData
	}

	useEffect(() => {
		setRouteData()
	}, [])

	return (
		<Dialog onClose={handleDialog} open={open} maxWidth="lg" classes={{ paper: classes.customDialog }}>
			<DialogTitle>
				<h1 className={'ct-light-black ff-LatoRegular fs20 m-0 tt-upper'}>{'Nuevo Horario de Salida'}</h1>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleDialog}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent classes={{ root: classes.custDialogContent }}>
				<ThemeProvider theme={MainColorTheme}>
					<p className={'ct-black ff-LatoRegular fs16 mb-2r'}>{'Seleccionar fecha, hora y pasajeros para asignar nuevo horario de salida.'}</p>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Fecha'}</p>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
								<DatePicker
									autoOk
									className={classes.customTextField}
									minDate={new Date(`${minDay} ${setFormatTime(exitTime)}`)}
									format={'dd/MM/yyyy'}
									fullWidth
									inputVariant="outlined"
									onChange={handleDateChange}
									value={new Date(`${startDate} ${setFormatTime(exitTime)}`)}
									variant="inline"
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} md={6}>
							<p className={'ct-dark-gray ff-LatoRegular fs16 mb-5p mt-0'}>{'Nuevo horario de salida'}</p>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
								<TimePicker
									ampm={false}
									className={classes.customTextField}
									clearable
									fullWidth
									inputVariant="outlined"
									name="exitTime"
									onChange={event => setExitTime(event)}
									value={exitTime}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<QueryBuilderIcon />
											</InputAdornment>
										),
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
					<TableContainer component={Paper} className={'mt-2r'}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<StyledTableCellHead align="left" width="20%">
										{/* <Checkbox className={classes.check} checked={check} onChange={handleChangeCheck} color="primary" /> */}
									</StyledTableCellHead>
									<StyledTableCellHead align="left">{'Pasajero'}</StyledTableCellHead>
								</TableRow>
							</TableHead>
							<TableBody>
								{collaborators !== undefined && collaborators.length > 0 ? (
									collaborators.map(({ address }, index: number) => (
										<StyledTableRowBody key={index}>
											<StyledTableCellBody align="left">
												<Checkbox
													className={classes.check}
													checked={address.collaborator.check}
													onChange={event => handleChangeCheck(event.target.checked, index)}
													color="primary"
												/>
											</StyledTableCellBody>
											<StyledTableCellBody align="left">{address.collaborator.fullName}</StyledTableCellBody>
										</StyledTableRowBody>
									))
								) : (
									<StyledTableRowBody>
										<StyledTableCellBody align="left" colSpan={2}>
											<p className={'ct-light-dark-gray ff-LatoRegular fs16 m-0'}>{'Sin pasajeros disponibles'}</p>
										</StyledTableCellBody>
									</StyledTableRowBody>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</ThemeProvider>
			</DialogContent>
			<DialogActions disableSpacing classes={{ root: classes.custDialogActions }}>
				<Button classes={{ root: classes.buttonActionSave }} color="primary" onClick={saveExtraHour()} variant="contained">
					{'Guardar Hora Extra'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ExtraHourDialog
