import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	createStyles,
	makeStyles,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	ThemeProvider,
	withStyles,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { RemoveStoreDialog } from '../Dialogs/RemoveStoreDialog'
import { getStores, putStores } from '../../providers/Private/Provider'
import MainColorTheme from '../utils/MainColorTheme'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useNotification } from '../../hooks/useNotification'
import { response } from '../../providers/Private/Client'

const StyledTableCellHead = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#0D1373',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableCellHeader = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#1d679b',
			color: theme.palette.common.white,
			fontFamily: 'Lato-Regular',
			textAlign: 'center',
			fontSize: 16,
		},
	})
)(TableCell)

const StyledTableRowBody = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow)

const StyledTableCellBody = withStyles((theme: Theme) =>
	createStyles({
		body: {
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			textAlign: 'center',
		},
	})
)(TableCell)

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '3% 3%',
		},
		table: {
			minWidth: 800,
			textAlign: 'center',
			width: '100%',
		},
		boxProgress: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
		},
		customProgress: {
			color: '#0D1373',
		},
		buttonTableAction: {
			borderRadius: 43,
			color: '#FFFFFF',
			fontFamily: 'Lato-Regular',
			fontSize: 14,
			height: 40,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		buttonDelete: {
			border: '2px solid rgba(0, 0, 0, 0.54)',
			color: 'rgba(0, 0, 0, 0.54)',
		},
		editHour: {
			'backgroundColor': '#0D1373',
			'&:hover': {
				backgroundColor: '#0D1373',
				cursor: 'pointer',
				opacity: '0.9',
			},
		},
		emptyBox: {
			alignItems: 'center',
			display: 'flex',
			height: 'calc(100vh - 500px)',
			justifyContent: 'center',
			width: '100%',
		},
		buttonSee: {
			color: '#000000',
			fontFamily: 'Lato-Regular',
			textTransform: 'capitalize',
		},
		custCell: {
			padding: '0 !important',
		},
	})
)

const INIT_DIALOGS = {
	remove: false,
}

export const ListStores = ({ setStoreEdit, setComponentValue, handleSetSelected }) => {
	const classes = useStyles()
	const [stores, setStores] = useState([])
	const [loader, setLoader] = useState(true)
	const [dialogs, setDialogs] = useState(INIT_DIALOGS)
	const [storeSelected, setStoreSelected] = useState(0)
	const [openPassengers, setOpenPassengers] = useState()
	const [handleNotification] = useNotification()

	useEffect(() => {
		getStoresData()
	}, [])

	const getStoresData = () => {
		setLoader(true)
		getStores()
			.then(response => {
				if (response && response.success) {
					setStores(
						response.data.sort((a, b) => {
							if (a.id > b.id) {
								return 1
							}
							if (a.id < b.id) {
								return -1
							}
							return 0
						})
					)
				} else {
					handleNotification('Ha ocurrido un error', 'error')
				}
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const handleSubmit = e => {
		e.preventDefault()
	}

	const getAddress = address => {
		const { street, number } = address
		return `${street} ${number}`
	}

	const editStore = store => {
		setStoreEdit(store)
		handleSetSelected('addStore')
		setComponentValue(3)
	}

	const handleOpenRemoveDialog = (store: any) => {
		setStoreSelected(store)
		setDialogs({ ...INIT_DIALOGS, remove: true })
	}

	const handleRemoveDialog = () => event => {
		getStoresData()
		setDialogs({ ...INIT_DIALOGS, remove: false })
	}

	const handleSwitchVerify = state => {
		switch (state) {
			case 0:
				return false
			case 1:
				return true
			default:
				return false
		}
	}

	const handleSwitchChange = state => {
		switch (state) {
			case 0:
				return 1
			case 1:
				return 0
			default:
				return 0
		}
	}

	const handleChangeState = store => {
		putStores(store.id, { state: handleSwitchChange(store.state) }).then(response => {
			if (response && response.success) {
				getStores().then(responseStores => {
					if (responseStores && responseStores.success) {
						setStores(
							responseStores.data.sort((a, b) => {
								if (a.id > b.id) {
									return 1
								}
								if (a.id < b.id) {
									return -1
								}
								return 0
							})
						)
					} else {
						handleNotification('Ha ocurrido un error', 'error')
					}
				})
			} else {
				handleNotification('Ha ocurrido un error', 'error')
			}
		})
	}

	const handleClick = (value: any) => {
		setOpenPassengers(openPassengers === value ? false : value)
	}

	if (loader) {
		return (
			<Box className={classes.boxProgress}>
				<CircularProgress size={34} className={classes.customProgress} />
			</Box>
		)
	}

	return stores && stores.length > 0 ? (
		<ThemeProvider theme={MainColorTheme}>
			<form onSubmit={handleSubmit} autoComplete="off">
				<h2 className={'ct-black ff-LatoRegular fs20'}>{'Lista de Sucursales'}</h2>
				<TableContainer component={Paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<StyledTableCellHead>{'Nombre'}</StyledTableCellHead>
								<StyledTableCellHead>{'Dirección'}</StyledTableCellHead>
								<StyledTableCellHead>{'Teléfono'}</StyledTableCellHead>
								<StyledTableCellHead>{'Encargado'}</StyledTableCellHead>
								<StyledTableCellHead> {'Activo'}</StyledTableCellHead>
								<StyledTableCellHead> {'Acciones'}</StyledTableCellHead>
							</TableRow>
						</TableHead>
						<TableBody>
							{stores.map((store: any, index) => (
								<>
									<StyledTableRowBody key={index}>
										<StyledTableCellBody>{store.name}</StyledTableCellBody>
										<StyledTableCellBody>{store.address ? store.address.street : 'Sin Dirección'}</StyledTableCellBody>
										<StyledTableCellBody>{store.phoneNumber ? store.phoneNumber : 'Sin Télefono'}</StyledTableCellBody>
										<StyledTableCellBody>
											{store.emailInCharge && store.numberInCharge ? (
												<Button
													classes={{
														root: classes.buttonSee,
													}}
													color="primary"
													onClick={() => {
														handleClick(index)
													}}
													endIcon={<ExpandMore />}
												>
													{'Ver encargado'}
												</Button>
											) : (
												'Sin encargado'
											)}
										</StyledTableCellBody>

										<StyledTableCellBody>
											<Switch checked={handleSwitchVerify(store.state)} onChange={() => handleChangeState(store)} name="state" color="primary" />
										</StyledTableCellBody>
										<StyledTableCellBody>
											<Button
												classes={{ root: classes.buttonTableAction }}
												className={classes.editHour}
												color="primary"
												fullWidth
												variant="contained"
												onClick={() => editStore(store)}
											>
												{'Editar'}
											</Button>
										</StyledTableCellBody>
									</StyledTableRowBody>
									<StyledTableRowBody>
										<StyledTableCellHead className={classes.custCell} colSpan={7}>
											<Collapse in={openPassengers === index} timeout="auto" unmountOnExit>
												<Table size="small">
													<TableHead>
														<TableRow>
															<StyledTableCellHeader>{'Correo del encargado'}</StyledTableCellHeader>
															<StyledTableCellHeader>{'Teléfono del encargado'}</StyledTableCellHeader>
														</TableRow>
													</TableHead>
													<TableBody>
														<StyledTableRowBody>
															<StyledTableCellBody>{store.emailInCharge}</StyledTableCellBody>
															<StyledTableCellBody>{store.numberInCharge}</StyledTableCellBody>
														</StyledTableRowBody>
													</TableBody>
												</Table>
											</Collapse>
										</StyledTableCellHead>
									</StyledTableRowBody>
								</>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</form>
			<RemoveStoreDialog handleDialog={handleRemoveDialog()} open={dialogs.remove} store={storeSelected} />
		</ThemeProvider>
	) : (
		<Box className={classes.emptyBox}>
			<h1 className={'ct-black ff-LatoRegular fs20'}>No hay Sucursales disponibles</h1>
		</Box>
	)
}
