import { FC, ChangeEvent, useState, Fragment, useEffect } from 'react'
import { Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { getDynamicExcel } from '../../../providers/Private/Provider'

//icons
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useNotification } from '../../../hooks/useNotification'

const { format, isValid } = require('date-fns')

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flexItem: {
			display: 'flex',
			alignItems: 'center',
		},
		downloadFileButton: {
			backgroundColor: '#FFFFFF',
			borderColor: '#7E7E7E',
			borderRadius: 5,
			borderStyle: 'solid',
			color: '#7E7E7E',
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			textTransform: 'capitalize',
		},
		uploadFileButton: {
			backgroundColor: '#BECDF8',
			border: '1px solid #7E9BF2',
			borderRadius: 40,
			color: '#7E7E7E',
			fontFamily: 'Lato-Regular',
			fontSize: 16,
			textTransform: 'capitalize',
			transition: '0.3s',
		},
		truncate: {
			'overflow': 'hidden',
			'text-overflow': 'ellipsis',
			'white-space': 'nowrap',
		},
	})
)

const Files: FC<PassengersFileProps> = ({ file, setFile, type, startDate, endDate }) => {
	const [fileUploaded, setFileUploaded] = useState(false)
	const [fileName, setFileName] = useState('')
	const [emptyFile, setEmptyFile] = useState(false)
	const classes = useStyles()
	const [excelPath, setExcelPath] = useState('')
	let path: string = ''
	const [handleNotification] = useNotification()

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target

		if (files && files[0]) {
			const fileUploaded = files[0]
			setFile(fileUploaded)
			const fileName = fileUploaded['name'] ? fileUploaded['name'] : 'Excel sin nombre'
			setFileUploaded(true)
			setFileName(fileName)
			setEmptyFile(false)
		} else {
			setEmptyFile(true)
		}
	}

	useEffect(() => {
		if (isValid(startDate) && isValid(endDate)) {
			
			if (format(startDate, 'yyyy-MM-dd').toString() <= format(endDate, 'yyyy-MM-dd').toString()) {
				handleCheckExcel()
			} else {
				handleNotification('La fecha de inicio no puede ser menor que la fecha de término', 'warning')
			}
		}
	}, [startDate, endDate])

	const handleCheckExcel = () => {
		if (type === 1) {
			getDynamicExcel(format(startDate, 'yyyy-MM-dd').toString(), format(endDate, 'yyyy-MM-dd').toString()).then((response: any) => {
				if (response?.downloadLink) {
					path = response?.downloadLink || ''
					setExcelPath(`${process.env.REACT_APP_API_XLSX}/${path}`)
				} else {
					setExcelPath('')
				}
			})
		}
	}

	return (
		<Fragment>
			<Grid item md={12}>
				<p className={'mb-0-5r ct-dark-gray ff-LatoBold fs18'}>
					Planilla pasajeros <small className={'ml-1r ff-LatoRegular fs14'}>*Debes subir el listado de pasajeros en el archivo Excel disponible para descargar.</small>
				</p>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={12} md={6}>
						<Button
							className={classes.downloadFileButton}
							download
							fullWidth
							href={type === 1 ? excelPath : `${process.env.REACT_APP_API_XLSX}/public/assets/excel/collaborators/formulario-horarios-fijo.xlsx`}
							variant="contained"
						>
							<GetAppIcon /> {'Archivo excel'}
						</Button>
					</Grid>
					<Grid item xs={12} md={6}>
						<p className={'ct-dark-gray ff-LatoRegular fs14'}>Descargar archivo</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={12} md={6}>
						<label htmlFor="passengersFile">
							<input accept=".xlsx,.xls" id="passengersFile" name="passengersFile" onChange={handleFileChange} style={{ display: 'none' }} type="file" />
							<Button className={classes.uploadFileButton} color="primary" component="span" fullWidth>
								{/* <PublishIcon /> */}
								{'Subir Archivo'}
							</Button>
						</label>
					</Grid>
					<Grid item xs={12} md={6}>
						<p className={`ct-dark-gray ff-LatoRegular fs14 ${classes.truncate}`}>{fileUploaded ? fileName : 'Ningún archivo seleccionado'}</p>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default Files
